import { Input, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { KCustomSelectMainInputProps } from 'types/kCustomSelectType';

export const MainInput = forwardRef<HTMLInputElement, KCustomSelectMainInputProps>(
  ({ title = '', isOpen, onOpen, placeholder, isLoading, isDisabled, ...rest }, ref) => {
    const activeColor = '#4285F4';
    let dropdownLeftIconColor = '#383D58';
    if (isDisabled) {
      dropdownLeftIconColor = '#B7BAC5';
    } else if (isOpen) {
      dropdownLeftIconColor = activeColor;
    }

    return (
      <InputGroup>
        <Input
          type="text"
          ref={ref}
          placeholder={placeholder}
          isReadOnly
          value={title}
          onClick={() => onOpen()}
          fontSize={'sm'}
          color={isDisabled ? '#8A8E9F' : '#262B47'}
          borderColor={isOpen ? activeColor : '#CFD0D899'}
          isDisabled={isLoading || isDisabled}
          cursor={isLoading || isDisabled ? 'not-allowed' : 'pointer'}
          {...rest}
        />
        <InputRightElement>
          {isLoading ? (
            <Spinner size="xs" color="#4285F4" />
          ) : (
            <MdArrowDropDown color={dropdownLeftIconColor} fontSize={'20px'} />
          )}
        </InputRightElement>
      </InputGroup>
    );
  }
);

MainInput.displayName = 'MainInput';
