import api from 'apis/api';

// Helper function to create headers with Authorization
const createAuthHeaders = (accessToken: string = '') => {
  const headers = new Headers();
  if (accessToken) {
    headers.append('Authorization', `Bearer ${accessToken}`);
  } else {
    headers.append(
      'Authorization',
      `Basic ${btoa(`${import.meta.env.VITE_RIPPLING_CLIENT_ID}:${import.meta.env.VITE_RIPPLING_CLIENT_SECRET}`)}`
    );
  }
  return headers;
};

// Fetch access token from Rippling
const getAccessToken = async (code: string) => {
  const redirectUri = `${import.meta.env.VITE_OAUTH_FRONTEND_URL}?service_id=rippling`;
  const body = new FormData();
  body.append('grant_type', 'authorization_code');
  body.append('code', code);
  body.append('redirect_uri', redirectUri);
  // NOTE: fetch is used here for adding unintentional headers to the request we set by axios interceptor
  const response = await fetch('https://api.rippling.com/api/o/token/', {
    method: 'POST',
    headers: createAuthHeaders(),
    body,
  });
  return response.json();
};

// Import authorized connection to Apideck Consumer
const importAuthorizedConnectionToApideckConsumer = async (
  orgId: string,
  consumerId: string,
  payload: { [key: string]: string | number }
) => {
  const { data } = await api.post(`/v1/connections/apideck/${consumerId}/import`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      service_id: 'rippling',
      unified_api: 'hris',
    },
  });
  return data;
};

// Mark Kintsugi app as installed
export const markKintsugiAppAsInstalled = async (code: string, orgId: string, consumerId: string) => {
  const tokenRes = await getAccessToken(code);

  if (tokenRes.access_token) {
    const res = await importAuthorizedConnectionToApideckConsumer(orgId, consumerId, tokenRes);
    if (res && res.success) {
      // NOTE: fetch is used here for adding unintentional headers to the request we set by axios interceptor
      return await fetch('https://api.rippling.com/platform/api/mark_app_installed', {
        method: 'POST',
        headers: createAuthHeaders(tokenRes.access_token),
      });
    } else {
      throw new Error('Failed to import authorized connection to Apideck Consumer');
    }
  }
};
