import { CountryOptionsType } from 'types/address';
import { Address, AddressInstance } from 'types/shared-types';

type AddressTransformers = {
  [key: string]: (value: string) => string;
};

export const generateAddressString = (
  address: Address | AddressInstance,
  transformers: AddressTransformers = {}
): string => {
  const parts: (string | undefined)[] = [
    transformers.phone ? transformers.phone(address.phone ?? '') : address.phone ?? undefined,
    transformers.street_1 ? transformers.street_1(address.street_1 ?? '') : address.street_1 ?? undefined,
    transformers.street_2 ? transformers.street_2(address.street_2 ?? '') : address.street_2 ?? undefined,
    transformers.city ? transformers.city(address.city ?? '') : address.city ?? undefined,
    transformers.county ? transformers.county(address.county ?? '') : address.county ?? undefined,
    transformers.state ? transformers.state(address.state ?? '') : address.state ?? undefined,
    transformers.postal_code ? transformers.postal_code(address.postal_code ?? '') : address.postal_code ?? undefined,
    transformers.country ? transformers.country(address.country ?? '') : address.country ?? undefined,
  ];

  // Filter out any empty parts
  const addressString = parts.filter(part => part).join(', ');

  return addressString;
};

export const getSortedCountries = (countries: CountryOptionsType[]) => {
  if (!countries) return countries;

  const firstCountry = 'US';
  // Separate the specific item from the list
  const topCountry = countries.find(country => country.value === firstCountry);

  // Sort the remaining country alphabetically by label
  const remainingCountry = countries
    .filter(({ value }) => value !== firstCountry)
    .sort((a, b) => a.label.localeCompare(b.label));

  // Combine the specific country at the top with the sorted country
  const sortedCountry = topCountry ? [topCountry, ...remainingCountry] : remainingCountry;
  return sortedCountry;
};
