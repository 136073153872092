import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    bgColor: 'secondary.100',
    borderRadius: '2px',
  },
  description: {
    color: 'gray.800',
    fontWeight: '400',
    fontSize: 'sm',
    lineHeight: '20px',
  },
  icon: {
    boxSize: '6',
    fill: 'secondary.500',
  },
});

const formAlertVariant = definePartsStyle({
  container: {
    bgColor: 'white',
    padding: '12px 0px',
  },
  icon: {
    boxSize: '4',
  },
});

const pricingCardVariant = definePartsStyle({
  container: {
    bgColor: 'secondary.50',
  },
  description: {
    color: 'secondary.500',
  },
});

const warningVariant = definePartsStyle({
  container: {
    bgColor: 'orange.100',
  },
  description: {
    color: 'gray.800',
  },
  icon: {
    fill: 'orange.500',
  },
});

const errorVariant = definePartsStyle({
  container: {
    bgColor: 'red.100',
  },
  description: {
    color: 'gray.800',
  },
  icon: {
    fill: 'red.500',
  },
});

export const alertTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    formAlertVariant: formAlertVariant,
    pricingCardVariant: pricingCardVariant,
    warningVariant: warningVariant,
    errorVariant: errorVariant,
  },
});
