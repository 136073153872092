import { Box, Flex, FormControl, HStack, Input, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TRANSACTION_STATE_KEY, updateAddress } from 'apis/transaction-api';
import { AddressSuggestionPopover } from 'component-library/components/address-suggestion-popover';
import Button from 'component-library/components/ButtonTmp/button';
import FormLabel from 'component-library/components/FormLabel';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Select from 'component-library/components/Select/Select';
import { CountryOptions, USStates } from 'constants/app-constants';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { ChangeEvent } from 'react';
import { SuggestedAddress } from 'types/address';
import { AddressInstance, AddressStatus } from 'types/shared-types';
import { toNormalCase } from 'utils';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  city: Yup.string().required('City is required'),
  state: Yup.string().test('state-required', 'State is required', function (value) {
    const { country } = this.parent;
    if (country === 'US') {
      return !!value;
    }
    return true;
  }),
  postal_code: Yup.string().required('Postal Code is required'),
  country: Yup.string().required('Country is required'),
});

interface EditAddressModalType {
  isOpen: boolean;
  onClose: () => void;
  payload: Partial<AddressInstance>;
}

export const EditTransactionAddressForm = ({ isOpen, onClose, payload }: EditAddressModalType) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();

  const mutation = useMutation({
    mutationFn: (payload: Partial<AddressInstance>[]) => {
      return updateAddress(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TRANSACTION_STATE_KEY] });
      onClose();
      handleSuccessNotification('Transaction address edited successfully.');
    },
  });

  const formik = useFormik({
    initialValues: {
      phone: payload?.phone,
      street_1: payload?.street_1,
      street_2: payload?.street_2,
      city: payload?.city,
      county: payload?.county,
      state: payload?.state,
      postal_code: payload?.postal_code,
      country: payload?.country,
      type: payload?.type,
      status: AddressStatus.UNVERIFIED,
      id: payload?.id,
      transaction_id: payload?.transaction_id,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      values.status = AddressStatus.UNVERIFIED;
      mutation.mutate([values]);
    },
  });

  const handleStateChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedStateName = event.target.value;
    const selectedState = USStates.find(state => state.value === selectedStateName);

    if (selectedState) {
      formik.setFieldValue('state', selectedState.value);
    } else {
      formik.setFieldValue('state', '');
    }
  };

  const handleCountryChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value;

    if (selectedCountry !== 'US') {
      formik.setFieldValue('state', '');
      formik.setFieldValue('county', '');
      formik.setFieldValue('country', selectedCountry);
    } else {
      formik.setFieldValue('country', selectedCountry);
    }
  };

  const applySelectedAddress = (address: SuggestedAddress) => {
    formik.setValues({
      phone: payload?.phone,
      street_1: formik.values.street_1 ?? '',
      street_2: formik.values.street_2 ?? '',
      country: address.country,
      type: payload?.type,
      status: AddressStatus.UNVERIFIED,
      id: payload?.id,
      transaction_id: payload?.transaction_id,
      city: address.city,
      county: address.county,
      state: address.state,
      postal_code: address.postal_code,
    });
  };

  const onCloseModal = () => {
    formik.resetForm();
    onClose();
  };

  const isDisabled =
    (payload?.country === 'US' && formik.values.country && formik.values.country !== 'US') ||
    (payload?.country !== 'US' && formik.values.country !== 'US');

  return (
    <ModalPopup
      size={'lg'}
      isOpen={isOpen}
      onClose={onCloseModal}
      header={payload?.type === 'BILL_TO' ? 'Bill To' : 'Ship To'}
      footer={
        <Flex gap={2}>
          <Button variant={'outline'} color={'secondary'} onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            isLoading={mutation.isPending}
            variant={'solid'}
            color={'primary'}
            width={'90px'}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </Button>
        </Flex>
      }
    >
      <form onSubmit={formik.handleSubmit} noValidate>
        <HStack align={'baseline'}>
          <FormControl isRequired>
            <FormLabel htmlFor="country">Country</FormLabel>
            <Select id="country" name="country" value={formik.values.country ?? ''} onChange={handleCountryChange}>
              <>
                {!payload?.country ? (
                  <option value="">Select country</option>
                ) : (
                  <option value={payload?.country}>
                    {CountryOptions.find(({ value }) => value === payload?.country)?.label}
                  </option>
                )}
                {CountryOptions.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </>
            </Select>
            {formik.errors.country && formik.touched.country && <Text color={'#E53E3E'}>{formik.errors.country}</Text>}
          </FormControl>

          <FormControl isRequired>
            <FormLabel htmlFor="state">State</FormLabel>
            <Select
              isDisabled={isDisabled}
              id="state_code"
              name="state_code"
              value={formik.values.state ?? ''}
              onChange={handleStateChange}
            >
              {isDisabled ? (
                <option value="">Select state</option>
              ) : (
                <>
                  {!payload?.state || payload?.state === '' ? (
                    <option value="">Select state</option>
                  ) : (
                    <option value={payload?.state}>
                      {USStates.find(option => option.value === payload?.state)?.label}
                    </option>
                  )}
                  {USStates.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </>
              )}
            </Select>
            {formik.errors.state && formik.touched.state && <Text color={'#E53E3E'}>{formik.errors.state}</Text>}
          </FormControl>
        </HStack>
        <Box height={'16px'} />
        <HStack align={'baseline'}>
          <FormControl isRequired>
            <FormLabel htmlFor="city">City</FormLabel>
            <Input
              id="city"
              type="text"
              name="city"
              value={toNormalCase(formik.values.city ?? '')}
              onChange={formik.handleChange}
              placeholder={payload?.city}
            />
            {formik.errors.city && formik.touched.city && <Text color={'#E53E3E'}>{formik.errors.city}</Text>}
          </FormControl>

          <FormControl isRequired>
            <FormLabel htmlFor="postal_code">Postal Code</FormLabel>
            <Input
              id="postal_code"
              name="postal_code"
              value={formik.values.postal_code ?? ''}
              onChange={formik.handleChange}
              placeholder={payload?.postal_code}
            />
            {formik.errors.postal_code && formik.touched.postal_code && (
              <Text color={'#E53E3E'}>{formik.errors.postal_code}</Text>
            )}
          </FormControl>
        </HStack>
        <Box height={'16px'} />
        <HStack align={'baseline'}>
          <FormControl>
            <FormLabel htmlFor="street_1">Address Line 1</FormLabel>
            <Input
              id="street_1"
              type="text"
              name="street_1"
              value={formik.values.street_1 ?? ''}
              onChange={formik.handleChange}
              placeholder={payload?.street_1}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="street_2">Address Line 2</FormLabel>
            <Input
              id="street_2"
              type="text"
              name="street_2"
              value={formik.values.street_2 ?? ''}
              onChange={formik.handleChange}
              placeholder={payload?.street_2}
            />
          </FormControl>
        </HStack>
        <Box height={'16px'} />
        {isOpen && formik.values && (
          <AddressSuggestionPopover address={formik.values} onUpdateAddress={applySelectedAddress} />
        )}
      </form>
    </ModalPopup>
  );
};
