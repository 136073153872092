import { Box, Flex, List, ListItem, Text } from '@chakra-ui/react';
import { MdCheck } from 'react-icons/md';
import { KCustomSelectOptionsProps } from 'types/kCustomSelectType';

export const KCustomSelectOptions = ({
  internalItems,
  disabledItems = [],
  itemValue,
  isItemSelected,
  handleChangeOption,
  getTitle,
  renderItemContent,
}: KCustomSelectOptionsProps) => {
  return (
    <List>
      {internalItems?.length === 0 && (
        <Text textAlign={'center'} p={2} mt={4}>
          No items found
        </Text>
      )}
      {internalItems?.map(item => {
        const stringValue = String(item[itemValue]);
        const title = getTitle(item);
        const isDisabled = disabledItems.includes(stringValue);
        const isSelected = isItemSelected(item);
        const getItemColor = (isDisabled: boolean, isSelected: boolean) => {
          if (isDisabled) return '#8A8E9F';
          return isSelected ? '#4285F4' : '#262B47';
        };
        const textColor = getItemColor(isDisabled, isSelected);

        return (
          <ListItem
            p={2}
            _hover={{
              bgColor: !isDisabled ? '#EFEFF3' : 'transparent',
              cursor: !isDisabled ? 'pointer' : 'not-allowed',
            }}
            color={textColor}
            key={stringValue}
            onClick={() => {
              if (!isDisabled) {
                handleChangeOption(item);
              }
            }}
          >
            <Flex alignItems={'center'} gap={1}>
              <Box width={4} height={4}>
                {isItemSelected(item) ? <MdCheck fontSize={14} fontWeight={'bold'} /> : ''}
              </Box>
              {renderItemContent ? (
                renderItemContent({ title, item, isSelected, isDisabled, color: textColor }) // display options from parent
              ) : (
                <Text color={textColor} display="block">
                  {getTitle(item)}
                </Text>
              )}
            </Flex>
          </ListItem>
        );
      })}
    </List>
  );
};
