import { PopoverContent, PopoverProps, PopoverTrigger } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import Popover from 'component-library/components/Popover/popover';
import { FilterIcon } from 'components/icons';
import { UseTableFiltersType } from 'hooks/useTableFilters';

type FilterPopoverProps = {
  content: React.FC<{ onClose: () => void; filters: any }>;
  hasFilter?: boolean;
  filters?: any;
  popoverProps?: PopoverProps;
};

export const FilterPopover = ({ content: Content, hasFilter, filters, popoverProps = {} }: FilterPopoverProps) => {
  return (
    <Popover {...popoverProps}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button variant={'transparent-with-icon'}>
              <FilterIcon hasFilter={hasFilter} />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <Content onClose={onClose} filters={filters} />
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

type TableFilterPopoverProps = {
  content: React.FC<{ onClose: () => void; tableFilters: UseTableFiltersType }>;
  hasFilter?: boolean;
  tableFilters: UseTableFiltersType;
};

export const TableFilterPopover = ({ content: Content, hasFilter, tableFilters }: TableFilterPopoverProps) => {
  return (
    <Popover>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button variant={'transparent-with-icon'}>
              <FilterIcon hasFilter={hasFilter} />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <Content onClose={onClose} tableFilters={tableFilters} />
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
