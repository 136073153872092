import {
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createTestOrganization, getAllOrganizations } from 'apis/organizations-apis';
import { useAppLoaderWrapper } from 'app/app-loader-wrapper';
import Button from 'component-library/components/ButtonTmp/button';
import Modal from 'component-library/components/Modal/Modal';
import useToast from 'component-library/hooks/useToast';
import { storeJWT } from 'cookie-handler';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { setOrg } from 'hooks/useOrg';
import { debounce } from 'lodash';
import getValue from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { object, string } from 'yup';

const validationSchema = object().shape({
  name: string()
    .min(1, 'Organization name must be at least 1 character long')
    .matches(/^[a-zA-Z0-9_ ]*$/, 'Organization name can only contain alphanumeric characters, underscores, or spaces')
    .required('Organization name is required'),
});

export const CreateOrgModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const { tokens } = useAuthInfo();
  const { showLoader, hideLoader } = useAppLoaderWrapper();
  const [searchTerm, setSearchTerm] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();

  const { handleFailNotification } = useHandleNotification();

  const createOrg = searchParams.get('createOrg');
  const createOrgType = searchParams.get('type');

  const { isPending, mutate } = useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      return await createTestOrganization(name, createOrgType !== 'live');
    },
    onSuccess: async data => {
      const externalOrgId = getValue(data.data, 'external_id');
      const orgId = getValue(data.data, 'id');
      if (externalOrgId) {
        handleOnClose();
        showLoader();
        const { accessToken, error } = await tokens.getAccessTokenForOrg(externalOrgId);
        if (accessToken) {
          storeJWT(accessToken);
          setOrg({
            orgId: orgId,
            externalId: externalOrgId,
            isTest: data.data.is_test,
            name: data.data.name,
          });
          hideLoader();
          navigate('/dashboard', { replace: true });
          toast({
            title: 'Successful!',
            description: `You've successfully logged in to the newly created ${data.data.is_test ? 'test organization.' : 'organization.'}`,
            status: 'success',
          });
        }
        if (error) {
          hideLoader();
          toast({
            title: 'Error',
            description: `Failed to log in to the newly created ${data.data.is_test ? 'test organization.' : 'organization.'}`,
            status: 'error',
          });
        }
      }
    },
    onError: error => {
      handleFailNotification(error);
      handleOnClose();
    },
  });
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      mutate(values);
    },
  });

  const cleanupSearchParams = () => {
    if (!createOrg) return;
    setSearchParams(params => {
      const newParams = new URLSearchParams(params);
      newParams.delete('createOrg');
      newParams.delete('type');
      return newParams.toString();
    });
  };

  const handleOnClose = () => {
    formik.resetForm();
    cleanupSearchParams();
    onClose();
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
    }, 300),
    []
  );

  useEffect(() => {
    if (!isOpen && createOrg) {
      onOpen();
    }
    return () => {
      cleanupSearchParams();
      debouncedSearch.cancel();
    };
  }, [createOrg, debouncedSearch]);

  const { data: searchResults, isPending: isSearching } = useQuery({
    queryKey: ['organizationSearch', searchTerm],
    queryFn: async () => {
      const { data } = await getAllOrganizations(searchTerm);
      return data;
    },
    enabled: searchTerm.length > 0,
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    formik.handleChange(e);
    debouncedSearch(value);
  };

  const similarOrganizationExists = useMemo(() => {
    if (!searchResults || !searchResults.items) return false;
    return searchResults.items.some((org: any) => org.name.toLowerCase() === formik.values.name.toLowerCase());
  }, [searchResults, formik.values.name]);

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{createOrgType === 'live' ? 'Create Organization' : 'Create Test Organization'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            What&apos;s the name of your organization? This will be visible to other users in your organization and
            people you have invited.
          </Text>
          <form onSubmit={formik.handleSubmit} id="create-test-org-form">
            <FormControl isRequired mt={5}>
              <FormLabel htmlFor="name">Organization Name</FormLabel>
              <Input
                id="name"
                type="text"
                name="name"
                value={formik.values.name}
                onChange={handleNameChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.name && formik.touched.name && (
                <Text mt={1} color="#E53E3E">
                  {formik.errors.name}
                </Text>
              )}
              {similarOrganizationExists && (
                <Text mt={1} color="#E53E3E">
                  An organization with a similar name already exists.
                </Text>
              )}
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button w="70px" color={'secondary'} variant={'outline'} mr={4} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            w="70px"
            variant="solid"
            type="submit"
            form="create-test-org-form"
            isDisabled={
              !formik.values.name || Object.values(formik.errors).length > 0 || similarOrganizationExists || isSearching
            }
            isLoading={isPending}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
