import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import Text from 'component-library/components/Text';
import React, { useEffect } from 'react';

import IntercomWidget from './intercom';

const StyledBox = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
}));

type Props = {
  error: Error;
  componentStack: string;
};

export const ErrorFallback = ({ error }: Props) => {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <StyledBox>
      <Text fontSize="22px" fontWeight={500} mb={4}>
        Oops! Something went wrong
      </Text>

      <Text color="gray.600" mb={6}>
        Don&apos;t worry, you can still navigate to other pages
      </Text>

      <IntercomWidget support={true} />
    </StyledBox>
  );
};

export const { ErrorBoundary } = Sentry;
