import { Badge, Box, Flex, IconButton, Text, useBoolean } from '@chakra-ui/react';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import copy from 'copy-to-clipboard';
import { Organization } from 'hooks/useOrg';
import React from 'react';
import { MdContentCopy } from 'react-icons/md';

type OrgItemProps = {
  isCurrent: boolean;
  org: any;
  onOrgSwitch: (org: Organization) => void;
};
const OrgItem = ({ isCurrent, org, onOrgSwitch }: OrgItemProps) => {
  const [flag, setFlag] = useBoolean();
  if (!org) return null;

  const handleClick = () => {
    onOrgSwitch({
      orgId: org.id,
      name: org.name,
      externalId: org.external_id,
      isTest: org.is_test,
    });
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleClick();
    }
  };

  return (
    <Box
      onClick={handleClick}
      mt={4}
      p={4}
      border={'2px'}
      borderRadius={'4px'}
      borderColor={'gray.50'}
      mb={'16px'}
      width="100%"
      cursor="pointer"
      aria-label={`org-item-${org?.name}`}
      role="button"
      tabIndex={0}
      onKeyDown={handleEnter}
    >
      <Flex justify={'start'} direction="column" alignItems={'start'}>
        <Flex gap={2} align={'center'}>
          <Text fontSize={'0.875rem'} fontWeight={500}>
            {org?.name}
          </Text>
          <Tooltip
            placement={'bottom-start'}
            shouldWrapChildren
            label={
              org?.is_test
                ? 'This organization is used only for testing.'
                : 'This organization can be used to manage your sales tax compliance in real-time.'
            }
          >
            <Badge
              bg={org?.is_test ? 'orange.500' : 'green.600'}
              color={'white'}
              textTransform={'uppercase'}
              fontSize={'12px'}
              h={'20px'}
            >
              {org?.is_test ? 'Test' : 'Live'}
            </Badge>
          </Tooltip>

          {isCurrent && (
            <Badge variant="secondary" size="sm" h={'20px'}>
              Current
            </Badge>
          )}
        </Flex>
        <Flex gap={2} alignItems="center">
          <Text fontWeight={400}>{org.id}</Text>
          {flag ? (
            <Text size={'sm'} fontWeight={400} color="green.500">
              Copied
            </Text>
          ) : (
            <IconButton
              p={0}
              size={'sm'}
              isRound={false}
              aria-label="copy org id"
              onClick={e => {
                e.stopPropagation();
                copy(org.id);
                setFlag.on();
                setTimeout(() => {
                  setFlag.off();
                }, 5000);
              }}
              variant="ghost"
              icon={<MdContentCopy />}
            />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default OrgItem;
