import { Flex, Skeleton, Stack, Text, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useGetCustomerById, useGetTransactionsById } from 'apis/customers';
import { EXEMPTIONS_STATE_KEY, getExemptions } from 'apis/exemptions';
import AppHeader from 'app/app-header';
import TableContainer from 'component-library/components/Table/table-container';
import { useOrg } from 'hooks/useOrg';
import { useParams } from 'react-router-dom';

import { CreateExemption } from './add-exemptions';
import { CustomerInfo } from './customer-details/customer-info';
import { ExemptionTable } from './customer-details/exemptions';
import { TransactionTable } from './customer-details/transactions';

export const CustomerDetails = () => {
  const { customerId = '' } = useParams<{ customerId: string }>();
  const { orgId } = useOrg();
  const { data: customerData, isPending: isCustomerDataPending } = useGetCustomerById(orgId, customerId);
  const { data: transactionData, isPending: isTransactionsDataPending } = useGetTransactionsById(orgId, customerId);

  const { isPending: isExemptionsDataPending, data: customerExemptions } = useQuery({
    queryKey: [EXEMPTIONS_STATE_KEY, orgId],
    queryFn: async () => {
      const res = await getExemptions(orgId, {
        page: 1,
        size: 50,
        customer_id: customerId,
      });
      return res.data;
    },
  });

  const isExemptionEmpty = !customerExemptions || customerExemptions?.total === 0;

  if (isCustomerDataPending || isExemptionsDataPending || isTransactionsDataPending) {
    return (
      <VStack spacing={4}>
        {Array.from({ length: 10 }, (_, index) => (
          <Skeleton key={index} height="36px" width="full" />
        ))}
      </VStack>
    );
  }
  return (
    <>
      <AppHeader />
      <Stack gap={4}>
        {customerData && <CustomerInfo data={customerData} />}
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontWeight={500} fontSize={'16px'}>
            Exemptions
          </Text>
          <CreateExemption />
        </Flex>
        {isExemptionEmpty ? (
          <TableContainer display="flex" h={'154px'} justifyContent={'center'} alignItems={'center'}>
            <Text color={'#4B5169'}>Customer does not have any exemptions added</Text>
          </TableContainer>
        ) : (
          <ExemptionTable
            captions={['JURISDICTION', 'START DATE', 'END DATE', 'FEIN', 'SALES TAX ID', 'STATUS']}
            data={customerExemptions.items}
          />
        )}
        <Text fontWeight={500} fontSize={'16px'}>
          Transactions
        </Text>
        <TransactionTable
          captions={['ID', 'DESCRIPTION', 'DATE', 'State', 'AMOUNT', 'TAX AMOUNT', 'Status']}
          data={transactionData}
        />
      </Stack>
    </>
  );
};
