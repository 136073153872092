import { TableFilterPopover } from 'components/filter';
import { UseTableFiltersType } from 'hooks/useTableFilters';

import FilingFilter from './FilingFilter';

type FilingFilterButtonProps = {
  tableFilters: UseTableFiltersType;
};
export const FilingFilterButton = ({ tableFilters }: FilingFilterButtonProps) => {
  const { country_code, state_code, start_date, end_date } = tableFilters;
  const hasFilter = !!(country_code || state_code || start_date || end_date);
  return <TableFilterPopover content={FilingFilter} hasFilter={hasFilter} tableFilters={tableFilters} />;
};
