import { Flex, Table, TableProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import TableContainer from 'component-library/components/Table/table-container';
import { ReactNode, useState } from 'react';

import ColumnFilter from './ColumnFilter';

type KDataTableProps = {
  onboardingTable?: string;
  headers: string[];
  numericCols?: string[];
  centerColumns?: string[];
  children: ReactNode;
  tableStyle?: TableProps;
  defaultVisibleColumns?: string[];
  showColumnFilter?: boolean;
  onVisibleColumnsChange?: (columns: string[]) => void;
};

export const KDataTable = ({
  onboardingTable = '',
  headers,
  numericCols = [],
  tableStyle,
  children,
  defaultVisibleColumns = headers,
  showColumnFilter = false,
  onVisibleColumnsChange,
  centerColumns,
}: KDataTableProps) => {
  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultVisibleColumns);

  const handleToggleColumn = (columns: string[]) => {
    setVisibleColumns(columns);
    if (onVisibleColumnsChange) {
      onVisibleColumnsChange(columns);
    }
  };

  const visibleHeaders = headers.filter(header => visibleColumns.includes(header));
  return (
    <TableContainer data-onboarding={onboardingTable}>
      <Table variant="unstyled" {...tableStyle}>
        <Thead>
          <Tr>
            {visibleHeaders.map((header: string, idx: number) => {
              return (
                <Th
                  key={`${idx}-${header}`}
                  isNumeric={numericCols.includes(header)}
                  textAlign={centerColumns?.includes(header) ? 'center' : undefined}
                >
                  {header}
                </Th>
              );
            })}
            {showColumnFilter && (
              <Flex justifyContent={'flex-end'}>
                <ColumnFilter headers={headers} onToggleColumn={handleToggleColumn} visibleColumns={visibleColumns} />
              </Flex>
            )}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
};
