/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum ProductStatusEnum {
    APPROVED = 'APPROVED',
    PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
    PENDING = 'PENDING',
}
