import { TransactionInstance } from 'types/shared-types';

import api from './api';

const getTransactions = (orgId: string, status?: string, page?: number, size?: number) => {
  return api.get(`/v1/transactions`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      status: status,
      page: page,
      size: size,
    },
  });
};

const getTransactionForId = (orgId: string, transactionId: string) => {
  return api.get<TransactionInstance>(`/v1/transactions/${transactionId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getRelatedTransactionForId = (orgId: string, transactionId: string) => {
  return api.get(`/v1/transactions/${transactionId}/related_transactions`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updateAddress = (orgId: string, payload: object) => {
  return api.put(`/v1/transactions/addresses`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const exportTransactionsSummaryReport = (orgId: string, queryParams: Record<string, string>) => {
  return api.post(`/v1/transactions/reports/summary`, null, {
    headers: {
      'x-organization-id': orgId,
    },
    params: queryParams,
  });
};

const exportTransactionsDetailsReport = (orgId: string, queryParams: Record<string, string>) => {
  return api.post(`/v1/transactions/reports/details`, null, {
    headers: {
      'x-organization-id': orgId,
    },
    params: queryParams,
  });
};

const exportTransactionsReport = (orgId: string, reportType: string, queryParams: Record<string, string>) => {
  return reportType === 'transactionDetails'
    ? exportTransactionsDetailsReport(orgId, queryParams)
    : exportTransactionsSummaryReport(orgId, queryParams);
};

const getInitialImportStatus = (orgId: string) => {
  return api.get(`/v1/initial_import_status`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getTransactionMetrics = (orgId: string) => {
  return api.get('/v1/metrics/transactions', {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const TRANSACTION_STATE_KEY = 'transactions';

const TRANSACTION_METRICS_KEY = 'transaction_metrics';

const RELATED_TRANSACTION_STATE_KEY = 'related_transactions';

export {
  exportTransactionsDetailsReport,
  exportTransactionsReport,
  exportTransactionsSummaryReport,
  getInitialImportStatus,
  getRelatedTransactionForId,
  getTransactionForId,
  getTransactionMetrics,
  getTransactions,
  RELATED_TRANSACTION_STATE_KEY,
  TRANSACTION_METRICS_KEY,
  TRANSACTION_STATE_KEY,
  updateAddress,
};
