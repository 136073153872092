import { Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FILINGS_STATE_KEY, getFilings } from 'apis/filing-apis';
import { TableSkeleton } from 'component-library/components/Skeleton/table-skeleton';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import { useOrg } from 'hooks/useOrg';
import { UseTableFiltersType } from 'hooks/useTableFilters';

import { FilingHistoryTable } from './filing-history-table';

type FilingHistoryTableProps = {
  tableFilters: UseTableFiltersType;
};
const FilingsHistoryTable = ({ tableFilters }: FilingHistoryTableProps) => {
  const { orgId } = useOrg();

  const {
    page,
    size,
    state_code,
    start_date,
    end_date,
    country_code,
    date_filed__lte,
    status__in,
    order_by,
    setFilters,
  } = tableFilters;

  const { isPending, data } = useQuery({
    queryKey: [
      FILINGS_STATE_KEY,
      orgId,
      page,
      size,
      state_code,
      start_date,
      end_date,
      country_code,
      date_filed__lte,
      status__in,
      order_by,
    ],
    queryFn: async () => {
      const res = await getFilings({
        orgId,
        params: {
          page,
          size,
          state_code,
          start_date,
          end_date,
          country_code,
          date_filed__lte,
          status__in,
          order_by,
        },
      });
      return res?.data || [];
    },
  });

  const filingData = data?.items || [];
  const isDataEmpty = !filingData?.length;
  const totalPages = data?.pages ?? 0;

  if (isPending) {
    return <TableSkeleton items={size} />;
  }

  return (
    <Stack height={'100%'}>
      <FilingHistoryTable tableFilters={tableFilters} data={filingData} />
      {!isDataEmpty && (
        <PaginationButtons size={size} currentPage={page} totalPages={totalPages} setFilters={setFilters} />
      )}
    </Stack>
  );
};

export default FilingsHistoryTable;
