export enum NumberStyleEnum {
  DECIMAL = 'decimal',
  CURRENCY = 'currency',
  PERCENT = 'percent',
  UNIT = 'unit',
}

export type FormatNumberOptions = {
  style?: NumberStyleEnum;
  zeroStr?: string;
  currency?: string;
  currencySign?: 'standard' | 'accounting';
  minimumFractionDigits?: number;
  maximumFractionDigits?: 'max' | number; // JS max is 20
};
export type Maybe<T> = T | null | undefined;
