import { TransactionStatus, TransactionStatusVariant } from 'types/transactions';

export const transactionStatusVariantMap = {
  COMMITTED: {
    variant: 'green',
    title: 'Committed',
  },
  VOIDED: {
    variant: 'red',
    title: 'Voided',
  },
  CANCELLED: {
    variant: 'red',
    title: 'Cancelled',
  },
  PENDING: {
    variant: 'grey',
    title: 'Pending',
  },
  FULLY_REFUNDED: {
    variant: 'red',
    title: 'Full Refund',
  },
  PARTIALLY_REFUNDED: {
    variant: 'red',
    title: 'Partial Refund',
  },
} satisfies Record<TransactionStatus, TransactionStatusVariant>;

export const addressStatusOptions = [
  {
    value: 'VERIFIED',
    title: 'Verified',
  },
  {
    value: 'UNVERIFIED',
    title: 'Unverified',
  },
  {
    value: 'PARTIALLY_VERIFIED',
    title: 'Partially Verified',
  },
  {
    value: 'INVALID',
    title: 'Invalid',
  },
  {
    value: 'UNVERIFIABLE',
    title: 'Unverifiable',
  },
];
export const statusOptions = [
  {
    value: 'PENDING',
    title: 'Pending',
  },
  {
    value: 'COMMITTED',
    title: 'Committed',
  },
  {
    value: 'CANCELLED',
    title: 'Cancelled',
  },
  {
    value: 'FULLY_REFUNDED',
    title: 'Fully Refunded',
  },
  {
    value: 'PARTIALLY_REFUNDED',
    title: 'Partially Refunded',
  },
];

export const transactionTypeOptions = [
  {
    value: 'SALE',
    title: 'Sale',
  },
  {
    value: 'CREDIT_NOTE',
    title: 'Credit Note',
  },
];

export const transactionSourceOptions = [
  {
    value: 'CHARGEBEE',
    title: 'Chargebee',
  },
  {
    value: 'BIGCOMMERCE',
    title: 'Bigcommerce',
  },
];

export enum TransactionProcessingStatusEnum {
  QUEUED = 'QUEUED',
  PROCESSED = 'PROCESSED',
  LOCKED = 'LOCKED',
  EXEMPT_DONE = 'EXEMPT_DONE',
  PENDING = 'PENDING',
}
