import { createStandaloneToast, useToast as chakraToast, UseToastOptions } from '@chakra-ui/react';
import { handleApiErrorMessage, staticErrorMessages } from 'apis/api';
import ToastContainer from 'component-library/components/Toast/toast';
import React, { useRef } from 'react';

const { toast } = createStandaloneToast();

const defaultOptions: UseToastOptions = {
  duration: 2000,
  isClosable: true,
  position: 'top',
};

const useToast = () => {
  const toast = chakraToast();
  const toastIdRef = useRef<string | number | null>(null);

  const showToast = (toastOptions: UseToastOptions) => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }

    const mergedOptions = {
      ...defaultOptions,
      ...toastOptions,
      render: toastOptions.render || (props => <ToastContainer {...props} />),
    };

    toastIdRef.current = toast(mergedOptions);
  };

  return showToast;
};

export default useToast;

/**
 * Handles global error messages by displaying a toast notification.
 *
 * @param error - The error object to process
 * @throws {never} This function doesn't throw errors
 * @example
 * handleGlobalErrorMessage({ status: 404, message: 'Not found' })
 */
export const handleGlobalErrorMessage = (error: any) => {
  const errorMessage = handleApiErrorMessage(error);
  const errorStatus = error?.status as keyof typeof staticErrorMessages;
  const errorMsgTitle = errorMessage?.title ?? `Request failed with status code ${errorStatus}`;
  const errorMsgDescription = errorMessage?.message ?? staticErrorMessages[errorStatus];

  toast({
    title: errorMsgTitle,
    description: errorMsgDescription,
    status: 'error',
    duration: 2000,
    isClosable: true,
    position: 'top',
    render: props => <ToastContainer {...props} />,
  });
};
