import { useQuery } from '@tanstack/react-query';
import { ApiResponse, CustomerInstance, TableFilter, TransactionInstance } from 'types/shared-types';

import api from './api';

export const CUSTOMERS_STATE_KEY = 'customers';

export const useGetCustomerById = (orgId: string, customerId: string) => {
  return useQuery({
    queryKey: [CUSTOMERS_STATE_KEY, orgId, customerId],
    queryFn: async () => {
      const { data } = await api.get<CustomerInstance>(`/v1/customers/${customerId}`, {
        headers: {
          'x-organization-id': orgId,
        },
      });
      return data;
    },
  });
};

export const useGetTransactionsById = (orgId: string, customerId: string) => {
  return useQuery({
    queryKey: [CUSTOMERS_STATE_KEY, orgId, customerId, 'transactions'],
    queryFn: async () => {
      const { data } = await api.get<TransactionInstance>(`/v1/customers/${customerId}/transactions`, {
        headers: {
          'x-organization-id': orgId,
        },
      });
      return data;
    },
  });
};

export const getCustomers = (orgId: string, params?: TableFilter) => {
  return api.get<ApiResponse<CustomerInstance>>(`/v1/customers`, {
    headers: {
      'x-organization-id': orgId,
    },
    params,
  });
};

export const getCustomerForId = (orgId: string, customerId: string) => {
  return api.get(`/v1/customers/${customerId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};
