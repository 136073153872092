import styled from '@emotion/styled';
import { RedirectToLogin, useAuthInfo } from '@propelauth/react';
import { NotificationProvider } from 'components/error/notification-context';
import { useEffect } from 'react';

import { ACLProvider } from './acl/acl';
import { PayWallProvider } from './acl/paywall';
import AppLoader from './app-loader';
import { AppLoaderWrapper, AppLoaderWrapperProvider } from './app-loader-wrapper';
import AppRoutes from './app-routes';

const LoginPromptContainer = styled.div`
  background-color: #e8f0fe;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const InfoIcon = styled.div`
  background-color: #4285f4;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

const PromptText = styled.p`
  margin: 0;
  color: #202124;
`;

const LoginLink = styled.a`
  color: #1a73e8;
  text-decoration: none;
  font-weight: bold;
`;

const APP_URL = import.meta.env.VITE_APP_URL || 'https://app.trykintsugi.com';

export const AppContent = ({ isEmbeddedInIframe }: { isEmbeddedInIframe: boolean }) => {
  const { isLoggedIn, loading } = useAuthInfo();

  const openInNewTab = () => {
    const newTab = window.open(window.location.href, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  useEffect(() => {
    if (isEmbeddedInIframe && !isLoggedIn && !loading) {
      openInNewTab();
    }
  }, [isEmbeddedInIframe, isLoggedIn, loading]);

  if (loading) {
    return <AppLoader />;
  }

  if (!isLoggedIn) {
    return isEmbeddedInIframe ? (
      <LoginPromptContainer>
        <InfoIcon>i</InfoIcon>
        <PromptText>
          Please log into Kintsugi in the new tab that has been opened or{' '}
          <LoginLink href={APP_URL} target="_blank" rel="noopener noreferrer">
            Click here
          </LoginLink>
          . Once you logged in, you can return to Shopify and use Kintsugi app inside your store.
        </PromptText>
      </LoginPromptContainer>
    ) : (
      <RedirectToLogin />
    );
  }

  return (
    <ACLProvider>
      <PayWallProvider>
        <AppLoaderWrapperProvider>
          <AppLoaderWrapper>
            <NotificationProvider>
              <AppRoutes />
            </NotificationProvider>
          </AppLoaderWrapper>
        </AppLoaderWrapperProvider>
      </PayWallProvider>
    </ACLProvider>
  );
};
