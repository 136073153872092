import { defineStyleConfig } from '@chakra-ui/react';

export const buttonStyles = defineStyleConfig({
  baseStyle: {
    borderRadius: '2px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '500',
  },
  variants: {
    solid: {
      height: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px 8px',
      borderRadius: '2px',
      bg: '#4285F4',
      textColor: '#FFFFFF',
      _hover: {
        bg: '#356AC3',
      },
      _active: {
        bg: '#285092',
      },
      _disabled: {
        bg: '#EFEFF3',
        textColor: '#A0A4B2',
      },
    },
    nav: {
      fontSize: '14px',
      fontWeight: '400',
      width: '188px',
      height: '44px',
      justifyContent: 'flex-start',
      borderRadius: '8px',
      bg: 'transparent',
      textColor: '#FFFFFF',
      _hover: {
        bg: '#3A3F5B',
      },
      _active: {
        bg: '#3A3F5B',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: '10px',
          bottom: 0,
          borderRadius: '4px',
          width: '4px',
          height: '24px',
          background: '#FFB000',
        },
      },
    },
    outline: {
      border: '1px',
      borderRadius: '2px',
      borderColor: '#4285F4',
      bgColor: 'white',
      textColor: '#4285F4',
      height: '32px',
      _hover: {
        borderColor: '#356AC3',
        bgColor: '#ECF3FE',
        textColor: '#356AC3',
      },
      _active: {
        borderColor: '#285092',
        textColor: '#285092',
      },
      _disabled: {
        borderColor: '#B7BAC5',
        textColor: '#B7BAC5',
      },
    },
    'secondary-outline': {
      border: '2px',
      borderRadius: '4px',
      borderColor: '#CFD0D8',
      bgColor: 'white',
      textColor: '#383D58',
      height: '32px',
      _hover: {
        borderColor: '#B7BAC5',
        bgColor: '#EFEFF3',
        textColor: '#383D58',
      },
      _active: {
        bgColor: '#CFD0D8',
        borderColor: '#A0A4B2',
        textColor: '#383D58',
      },
      _disabled: {
        borderColor: '#B7BAC5',
        textColor: '#B7BAC5',
        bgColor: '#383D58',
      },
    },
    disabled: {
      border: '2px',
      borderRadius: '4px',
      borderColor: '#B7BAC5',
      textColor: '#B7BAC5',
      bgColor: 'white',
    },
    paging: {
      width: '36px',
      height: '36px',
      mx: '4px',
      border: '1px',
      borderRadius: '2px',
      borderColor: '#CFD0D8',
      bgColor: 'white',
      textColor: '#383D58',
      _hover: {
        borderColor: '#CFD0D8',
        bgColor: '#EFEFF3',
        textColor: '#383D58',
      },
      fontWeight: 'normal',
    },
    'transparent-with-icon': {
      bg: 'transparent',
      fontWeight: 'bold',
      borderRadius: 'inherit',
      cursor: 'pointer',
      _active: {
        bg: 'transparent',
        transform: 'none',
        borderColor: 'transparent',
      },
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        boxShadow: 'none',
      },
    },
    danger: {
      bg: 'red.500',
      textColor: '#FFFFFF',
      w: '5.625rem',
      h: '2rem',
      _hover: {
        bg: 'red.500',
        _loading: {
          bg: 'red.500',
          opacity: 0.8,
        },
        _disabled: {
          bg: 'red.500',
          opacity: 0.8,
        },
      },
      _active: {
        bg: 'red.500',
      },
    },
  },
});
