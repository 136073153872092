import './date-picker.css';

import { Box, useStyleConfig } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import ReactDatePicker, { CalendarContainer, ReactDatePickerProps } from 'react-datepicker';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { adjustTimeZoneOffsetWithUtc, getFormattedDate, isValidDate } from 'utils/dates';

import { KInput } from '../Input';
import CalendarHeader from './calendar-header';

interface DatePickerProps extends Omit<ReactDatePickerProps, 'selected' | 'onChange'> {
  variant?: string;
  selected?: string | number | Date;
  onChange: (date: string) => void;
  returnDateFormat?: string;
  limitToLastDay?: boolean;
}

const defaultProps: Partial<DatePickerProps> = {
  dateFormat: 'MMM dd, yyyy',
  placeholderText: 'Select Date',
  customInput: <KInput />,
  renderCustomHeader: CalendarHeader,
  showIcon: true,
  icon: <MdOutlineCalendarMonth />,
};

const DatePicker: React.FC<PropsWithChildren<DatePickerProps>> = ({
  variant,
  calendarContainer,
  selected = '',
  onChange,
  returnDateFormat = 'yyyy-MM-dd',
  limitToLastDay = false,
  ...rest
}: PropsWithChildren<DatePickerProps>): React.ReactElement => {
  const datePickerStyle = useStyleConfig('DatePicker', { variant });

  const selectedDate =
    !!selected && isValidDate(new Date(selected)) ? adjustTimeZoneOffsetWithUtc(new Date(selected)) : null;

  const handleOnChange = (date: Date) => {
    const formattedDate = getFormattedDate(date, returnDateFormat);
    onChange(formattedDate);
  };

  const isLastDayOfMonth = (date: Date) => {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    return date.getDate() === lastDay;
  };

  const DefaultCalendarContainer = ({ className, children }: any) => {
    return (
      <Box __css={datePickerStyle}>
        <CalendarContainer className={className}>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </Box>
    );
  };

  return (
    <ReactDatePicker
      {...defaultProps}
      {...rest}
      selected={selectedDate}
      onChange={handleOnChange}
      calendarContainer={calendarContainer ? calendarContainer : DefaultCalendarContainer}
      filterDate={limitToLastDay ? isLastDayOfMonth : undefined}
    />
  );
};

export default DatePicker;
