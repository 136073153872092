import { ExperienceService, NexusService } from '_api-client';
import { OrganizationStatsData, OrganizationTasksData } from 'types/charts';
import { TaxDetailsFilterProps } from 'types/shared-types';

import api from './api';

// TO DO: api client can be used directly by components, i.e container components
// atm just trying to touch less code as possible to avoid breaking changes
// and move to new api client progressively

const getSummary = (orgId: string): Promise<any> => {
  return ExperienceService.getDashboardDataRouteV1DashboardGet({ xOrganizationId: orgId });
};

const getNexus = (orgId: string) => {
  return NexusService.getNexusForOrgV1NexusGet({ xOrganizationId: orgId });
};

const exportNexus = (orgId: string): Promise<any> => {
  return NexusService.getNexusReportV1NexusExportGet({ xOrganizationId: orgId });
};

const getDashboardDetails = (orgId: string, params: TaxDetailsFilterProps): Promise<any> => {
  return ExperienceService.getDashboardDetailsV1DashboardDetailsAggregationsAggregationTypeGet({
    xOrganizationId: orgId,
    aggregationType: 'annual',
    ...params,
  });
};

const getDashboardOnboardingStepStatus = (orgId: string) => {
  return api.get(`/v1/onboarding/steps-status`, {
    method: 'GET',
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getDashboardTasks = (orgId: string) => {
  return api.get<OrganizationTasksData>(`/v1/dashboard/tasks`, {
    method: 'GET',
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const getDashboardStats = (orgId: string) => {
  return api.get<OrganizationStatsData>(`/v1/dashboard/stats`, {
    method: 'GET',
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const NEXUS_STATE_KEY = 'nexus';
const ONBOARDING_STATE_KEY = 'onboarding';

export {
  exportNexus,
  getDashboardDetails,
  getDashboardOnboardingStepStatus,
  getDashboardStats,
  getDashboardTasks,
  getNexus,
  getSummary,
  NEXUS_STATE_KEY,
  ONBOARDING_STATE_KEY,
};
