/* eslint-disable react-hooks/rules-of-hooks */
import { useAuthInfo } from '@propelauth/react';
import * as Sentry from '@sentry/react';
import { ErrorBoundary, ErrorFallback } from 'components/error-fallback';
import useTracking from 'hooks/useTracking';
import { CustomerDetails } from 'pages/Customers/components/customer-details';
import BlankAddressTransactions from 'pages/Dashboard/components/BlankAddressTransactions';
import Integrations from 'pages/Import/components/integrations';
import Presence from 'pages/Presence';
import { TransactionDetails } from 'pages/Transactions/components/transaction-details';
import React, { lazy, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { BLANK_ADDRESSES, TOOLS_AND_GUIDE } from '../constants/AppRoutes';
import { AppLayout } from './app-layout';

const Dashboard = lazy(() => import('pages/Dashboard'));
const Settings = lazy(() => import('pages/Configuration'));
const Customers = lazy(() => import('pages/Customers'));
const Transactions = lazy(() => import('pages/Transactions/transaction'));
const Registrations = lazy(() => import('pages/Registrations'));
const Nexus = lazy(() => import('pages/Nexus'));
const Filing = lazy(() => import('pages/Filing'));
const ShopifyLanding = lazy(() => import('shopify'));
const ConnectShopify = lazy(() => import('shopify/ConnectShopify'));
const ToolsAndGuide = lazy(() => import('pages/ToolsAndGuide'));
const Products = lazy(() => import('pages/Products'));
const Import = lazy(() => import('pages/Import'));
const Exemptions = lazy(() => import('pages/Exemptions'));
const BulkCleanupAddresses = lazy(() => import('pages/Addresses'));
const AppRedirector = lazy(() => import('app/app-redirector'));
const Supports = lazy(() => import('pages/Supports'));

const fallBack = (props: any) => <ErrorFallback {...props} />;

const withErrorBoundary = (Component: React.ComponentType<any>) => {
  return (
    <RouteErrorBoundary>
      <Component />
    </RouteErrorBoundary>
  );
};

const RouteErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  return (
    <ErrorBoundary fallback={fallBack} key={location.pathname}>
      {children}
    </ErrorBoundary>
  );
};

const AppRoutes = () => {
  const { identifyUser } = useTracking();
  const { user } = useAuthInfo();

  useEffect(() => {
    if (user) {
      identifyUser({ email: user.email, id: user.userId });
      Sentry.setUser({ email: user.email, id: user.userId });
    }
  }, [user?.userId]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={withErrorBoundary(Dashboard)} />
          {/* Redirect from base configurations path to default tab */}
          <Route path="/configurations" element={<Navigate to="/configurations/organization-details" replace />} />
          <Route path="/configurations/:tab?" element={withErrorBoundary(Settings)} />
          <Route path="/customers" element={withErrorBoundary(Customers)} />
          <Route path="/customers/:customerId" element={withErrorBoundary(CustomerDetails)} />
          <Route path="/transactions" element={withErrorBoundary(Transactions)} />
          <Route path="/transactions/:transactionId" element={withErrorBoundary(TransactionDetails)} />
          <Route path="/nexus" element={withErrorBoundary(Nexus)} />
          <Route path="/filing" element={withErrorBoundary(Filing)} />
          <Route path="/registrations" element={withErrorBoundary(Registrations)} />
          <Route path="/source" element={withErrorBoundary(Import)} />
          <Route path="/integrations" element={withErrorBoundary(Integrations)} />
          <Route path="/shopify" element={withErrorBoundary(ShopifyLanding)} />
          <Route path="/connect/shopify" element={withErrorBoundary(ConnectShopify)} />

          <Route path={TOOLS_AND_GUIDE} element={withErrorBoundary(ToolsAndGuide)} />
          <Route path="/products" element={withErrorBoundary(Products)} />
          <Route path="/exemptions" element={withErrorBoundary(Exemptions)} />
          <Route path="/presence" element={withErrorBoundary(Presence)} />
          <Route path="/invalid-addresses" element={withErrorBoundary(BulkCleanupAddresses)} />
          <Route path={BLANK_ADDRESSES} element={withErrorBoundary(BlankAddressTransactions)} />
        </Route>
        <Route path="/supports" element={withErrorBoundary(Supports)} />
        <Route path="/redirect" element={withErrorBoundary(AppRedirector)} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
