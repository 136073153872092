import {
  Box,
  Flex,
  Link,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { BILLING_STATE_KEY } from 'apis/billing-apis';
import Modal from 'component-library/components/Modal/Modal';
import { BILLING_PLANS } from 'constants/billing-plans';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { useEffect } from 'react';

import { PricingCard } from './pricing-card';

export const UpgradeBillingPlanFromFreeModal = () => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [openPricingModal, setSearchParams] = useQueryParam('openPricingModal', '');
  const handleUpgradeSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [BILLING_STATE_KEY, 'details', orgId],
    });
    queryClient.invalidateQueries({
      queryKey: [BILLING_STATE_KEY, 'invoices', orgId],
    });
  };

  useEffect(() => {
    if (openPricingModal) {
      onOpen();
    }
  }, [openPricingModal]);

  const handleClose = () => {
    setSearchParams({});
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={handleClose} size={'3xl'} variant={'rounded'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize={'18px'} fontWeight={'500'}>
            Upgrade Plan
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={0}>
          <Flex justify={'space-between'}>
            {BILLING_PLANS.map(plan => (
              <PricingCard key={plan.title} {...plan} onClose={onClose} onUpgradeSuccess={handleUpgradeSuccess} />
            ))}
          </Flex>
        </ModalBody>
        <Box py={2} ml={6}>
          <Text fontSize={'xs'}>The Growth plan is billed in arrears on the first day of the next month</Text>
        </Box>
        <ModalFooter justifyContent={'start'}>
          <Text>
            Questions?
            <Link ml={1} color="#4285F4" href="mailto:growth@trykintsugi.com">
              Contact Sales
            </Link>
          </Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
