import { Flex } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import Text from 'component-library/components/Text';
import KSTooltip from 'component-library/components/Tooltiptmp/tooltip';
import { StepCompleteIcon } from 'components/icons';
import { useNavigate } from 'react-router-dom';

type OnboardingStepProps = {
  icon: any;
  title: string;
  description: string;
  isCompleted: boolean;
  nextStep: boolean;
  path: string;
  disabled: boolean;
  onClose: () => void;
};

export const OnboardingStep = ({
  icon,
  title,
  description,
  isCompleted,
  nextStep,
  path,
  disabled,
  onClose,
}: OnboardingStepProps) => {
  const navigate = useNavigate();

  const renderButtonContent = () => {
    if (isCompleted) {
      return (
        <Flex justifyContent={'center'} width={'106px'} pl={{ sm: 4 }}>
          <StepCompleteIcon />
        </Flex>
      );
    }

    const buttonText = title.includes('Import') ? 'Import' : 'Add';
    return (
      <KSTooltip label="Please contact an owner in your organization to add these details." isDisabled={!disabled}>
        <Button
          ml={{ sm: 12 }}
          width={'106px'}
          variant={nextStep && !disabled ? 'solid' : 'outline'}
          onClick={() => {
            navigate(path);
            onClose();
          }}
          isDisabled={disabled}
        >
          {buttonText}
        </Button>
      </KSTooltip>
    );
  };

  return (
    <Flex
      justifyContent={'space-between'}
      align={{ base: 'center', md: 'center', sm: 'flex-start' }}
      direction={{ base: 'row', md: 'row', sm: 'column' }}
      gap={{ base: '0px', md: '0px', sm: '10px' }}
    >
      <Flex gap={'16px'}>
        {icon}
        <Flex direction={'column'}>
          <Text fontWeight={'500'} fontSize={'sm'}>
            {title}
          </Text>
          <Text fontWeight={'400'} fontSize={'12px'}>
            {description}
          </Text>
        </Flex>
      </Flex>
      {renderButtonContent()}
    </Flex>
  );
};
