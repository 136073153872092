import {
  Badge,
  Button,
  CircularProgress,
  Divider,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getDashboardOnboardingStepStatus, ONBOARDING_STATE_KEY } from 'apis/dashboard-apis';
import { useACL } from 'app/acl/acl';
import { useAppLoaderWrapper } from 'app/app-loader-wrapper';
import Text from 'component-library/components/Text';
import { BankDetailIcon, OrganizationDetailIcon, PresenceIcon, TransactionIcon } from 'components/icons';
import { useOrg } from 'hooks/useOrg';
import { Fragment } from 'react';
import { OnBoardingStepStatus } from 'types/onboarding';

import { OnboardingStep } from './onboarding-step';

export const OnboardingStepper = () => {
  const { orgId, isTest } = useOrg();
  const { isAtLeastRole } = useACL();
  const isAdmin = isAtLeastRole('Admin');
  const isOwner = isAtLeastRole('Owner');
  const { isLoading } = useAppLoaderWrapper();

  const { data, isLoading: isStepStatusLoading } = useQuery({
    queryKey: [ONBOARDING_STATE_KEY, 'steps', 'status', orgId],
    queryFn: async () => {
      const response = await getDashboardOnboardingStepStatus(orgId);
      return response.data satisfies OnBoardingStepStatus;
    },
    enabled: !!orgId && !isTest,
  });

  type Step = {
    title: string;
    description: string;
    status: boolean;
    path: string;
    disabled: boolean;
  };

  const steps: Step[] = [
    {
      title: 'Import Transactions',
      description: 'Import transactions to calculate economic nexus and tax liabilities.',
      status: data?.transactions_status,
      path: '/source',
      disabled: false,
    },
    {
      title: 'Add Presence',
      description: 'Please add jurisdictions where you have physical presence.',
      status: data?.physical_nexus_status,
      path: '/presence',
      disabled: false,
    },
    {
      title: 'Add Organization Details',
      description: 'Enter your business information to process registrations.',
      status: data?.organization_details_status,
      path: '/configurations/organization-setup',
      disabled: !isOwner && !isAdmin,
    },
    {
      title: 'Add Bank Details',
      description: 'Enter bank account details for tax remittance.',
      status: data?.bank_details_status,
      path: '/configurations/bank-details',
      disabled: !isOwner && !isAdmin,
    },
  ];

  const icons = [
    <TransactionIcon key="transaction" />,
    <PresenceIcon key="presence" />,
    <OrganizationDetailIcon key="organizationDetail" />,
    <BankDetailIcon key="bankDetail" />,
  ];
  let completedSteps;
  let totalSteps;
  let completionPercentage;
  if (isOwner || isAdmin) {
    completedSteps = steps.filter(step => step.status).length;
    totalSteps = steps.length;
    completionPercentage = (completedSteps / totalSteps) * 100;
  } else {
    completedSteps = steps.filter(step => step.status && !step.disabled).length;
    totalSteps = 2;
    completionPercentage = (completedSteps / totalSteps) * 100;
  }

  const nextStepIndex = steps.findIndex(step => !step.status);

  if (isStepStatusLoading || isLoading || data?.onboarding_steps_status || isTest) {
    return null;
  }

  return (
    <Popover placement="top-end" defaultIsOpen returnFocusOnClose={false}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              height={'50px'}
              borderRadius={'30px'}
              p={'15px 12px 15px 15px'}
              mr={{ base: '0px', md: '0px', sm: 12 }}
              bgColor={'secondary.500'}
              width={'211px'}
              onClick={onClose}
            >
              <Text fontSize={'sm'} fontWeight={'500'} textColor={'white'} pr={'8px'}>
                Complete Onboarding
              </Text>

              <Badge bgColor={'white'} borderRadius={'70px'} p={'3px 8px 3px 9px'} color={'secondary.500'}>
                {completedSteps}/{totalSteps}
              </Badge>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            py={4}
            pl={4}
            pr={2}
            width={{ base: '613px', md: '613px', sm: '343px' }}
            _focus={{
              boxShadow: '0px 8px 32px -4px rgba(23, 24, 24, 0.12), 0px -2px 26px -3px rgba(23, 24, 24, 0.08)',
            }}
          >
            <Flex align="center" justify="space-between">
              <PopoverHeader flex="1" border={'none'} p={0} color={'gray.900'} fontWeight={'500'}>
                Complete Onboarding
              </PopoverHeader>
              <HStack>
                <CircularProgress value={completionPercentage} size={{ base: '27px', md: '27px', sm: '22px' } as any} />
                <Text fontSize={{ base: 'sm', md: 'sm', sm: 'xs' }}>
                  {completedSteps}/{totalSteps} Completed
                </Text>
                <PopoverCloseButton position="static" size={{ base: 'md', md: 'md', sm: 'sm' }} />
              </HStack>
            </Flex>
            <PopoverBody py={0} pl={0} pr={2} mt={'17px'}>
              <Stack gap={'16px'}>
                {steps.map((step, index) => (
                  <Fragment key={index}>
                    <OnboardingStep
                      onClose={onClose}
                      icon={icons[index]}
                      title={step.title}
                      description={step.description}
                      isCompleted={step.status}
                      nextStep={index === nextStepIndex}
                      path={step.path}
                      disabled={step.disabled}
                      key={index}
                    />
                    {index < steps.length - 1 && <Divider width={'96%'} borderColor="gray.50" />}
                  </Fragment>
                ))}
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
