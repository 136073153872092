import { useEffect, useState } from 'react';
import { getHumanReadableString } from 'utils/enum-helpers';

type UseCustomSelectType = {
  items: any;
  itemTitle?: string;
  itemValue?: string;
  value?: string | number;
};

export const useCustomSelect = ({ items, itemTitle, itemValue, value }: UseCustomSelectType) => {
  const [selectedValue, setSelectedValue] = useState<string | number>();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const getTitle = (item: any) => {
    const isObject = typeof item === 'object';
    return isObject ? item[itemTitle as string] : item;
  };

  const getValue = (item: any) => {
    if (typeof item === 'object') {
      const value = item[itemValue as string];
      setSelectedValue(value);
      return value;
    }
    return item;
  };

  const getTitleFromValue = () => {
    const foundItem = items?.find((item: any) => item[itemValue as string] === selectedValue);
    if (foundItem) {
      return getHumanReadableString(foundItem[itemTitle as string]);
    }
    return '';
  };

  const isItemSelected = (item: any) => {
    if (!selectedValue) {
      return false;
    }
    return selectedValue === item[itemValue as string];
  };

  return {
    getTitle,
    getTitleFromValue,
    getValue,
    selectedValue,
    isItemSelected,
  };
};
