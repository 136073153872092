import { Box, Card, HStack, Spacer, Text, Tooltip, VStack } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import { AddressValidation, AddressValidationOutline } from 'components/icons';
import { MdOutlineWrongLocation } from 'react-icons/md';
import { AddressResSchema } from 'types/address';
import { AddressInstance, AddressStatus, CustomerInstance } from 'types/shared-types';
import { getHumanReadableString } from 'utils/enum-helpers';

export const AddressCard = ({
  title,
  address,
  addressStatus,
  onClickEdit,
  size = 'md',
}: {
  title: string;
  address: AddressInstance | CustomerInstance | AddressResSchema | undefined;
  addressStatus?: AddressStatus | undefined;
  onClickEdit: () => void;
  size?: 'sm' | 'md' | 'lg';
}) => {
  //Address Icon used next to header title
  const getAddressStatusIcon = (status?: AddressStatus | undefined) => {
    if (status === AddressStatus.VERIFIED || status === AddressStatus.PARTIALLY_VERIFIED) {
      return <AddressValidation />;
    } else if (status === AddressStatus.UNVERIFIED) {
      return <AddressValidationOutline fill={'#8A8E9F'} />;
    } else {
      return <MdOutlineWrongLocation fontSize={'18px'} color={address ? '#E53E3E' : '#8A8E9F'} />;
    }
  };

  const getAddressSection = (address: AddressInstance | CustomerInstance | AddressResSchema) => {
    const addressLine1 = [];
    const addressLine2 = [];

    // first line
    address.street_1 && addressLine1.push(address.street_1);
    address.street_2 && addressLine1.push(address.street_2);

    // second line
    address.city && addressLine2.push(address.city);
    address.state && addressLine2.push(address.state);
    address.postal_code && addressLine2.push(address.postal_code);
    address.country && addressLine2.push(address.country);
    address.county && addressLine2.push(address.county);

    if (!addressLine1.length && !addressLine2.length) {
      return (
        <Text color="#4B5169" mt={1.5}>
          Address not found.
        </Text>
      );
    }

    return (
      <VStack alignItems={'flex-start'} mt={1.5}>
        <Text>{addressLine1.join(', ') || ' - '}</Text>
        <Text>{addressLine2.join(', ') || ' - '} </Text>
      </VStack>
    );
  };

  return (
    <Card w={{ base: size, md: size, sm: 'sm' }}>
      <>
        <HStack>
          <HStack>
            <Text fontSize={title === 'Address' ? 'sm' : 'md'} fontWeight="medium">
              {title}
            </Text>
            <Tooltip
              shouldWrapChildren
              label={
                address?.status || addressStatus
                  ? getHumanReadableString(addressStatus || address?.status)
                  : 'Not Found'
              }
            >
              {getAddressStatusIcon(addressStatus && addressStatus)}
            </Tooltip>
          </HStack>
          <Spacer />
          <Box>
            <Button
              height={'24px'}
              width={'50px'}
              fontSize="xs"
              variant="outline"
              color="secondary"
              onClick={() => onClickEdit()}
            >
              {address ? 'Edit' : 'Add'}
            </Button>
          </Box>
        </HStack>
        {address ? (
          getAddressSection(address)
        ) : (
          <Text color="#4B5169" mt={1.5}>
            Address not found
          </Text>
        )}
      </>
    </Card>
  );
};
