import { Flex, Icon, Table, Tbody, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import TableContainer from 'component-library/components/Table/table-container';
import React from 'react';
import { MdInfo } from 'react-icons/md';

type TableRow = {
  label: string;
  value: string | number | null | undefined;
  info?: string;
};

type TableProps = {
  title: string;
  data: TableRow[];
  valueFormatter?: (value: string | number) => string;
};

export const OverviewTable: React.FC<TableProps> = ({ title, data, valueFormatter = v => `${v}` }) => (
  <Flex flexDirection={'column'} gap={2}>
    <Text size={'lg'} fontWeight={'500'}>
      {title}
    </Text>
    <TableContainer>
      <Table variant="unstyled">
        <Tbody>
          {data.map(({ label, value, info }) => (
            <Tr key={label}>
              <Td width={'100%'}>
                <Flex justifyContent={'space-between'}>
                  <Text>
                    {label}
                    {info && (
                      <Tooltip label={info} shouldWrapChildren placement="bottom-start">
                        <Icon as={MdInfo} w={4} h={4} mb="-3px" ml={0.5} />
                      </Tooltip>
                    )}
                  </Text>
                  <Text fontWeight={500}>{valueFormatter(value ?? '')}</Text>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  </Flex>
);
