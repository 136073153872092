import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { blankAddressSuggestions } from 'apis/addressses';
import { TRANSACTION_STATE_KEY, updateAddress } from 'apis/transaction-api';
import FormLabel from 'component-library/components/FormLabel';
import Select from 'component-library/components/Select/Select';
import Text from 'component-library/components/Text';
import { AIIcon } from 'components/icons';
import { CountryOptions, USStates } from 'constants/app-constants';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import isMatch from 'lodash/isMatch';
import { ChangeEvent, useState } from 'react';
import { AddressInstance, AddressStatus } from 'types/shared-types';
import { replaceNullWithEmptyString, toNormalCase } from 'utils';
import * as Yup from 'yup';

type AddBlankAddressPopoverProps = {
  payload: AddressInstance;
};

const ValidationSchema = Yup.object().shape({
  street_1: Yup.string().optional(),
  street_2: Yup.string().optional(),
  city: Yup.string().required('City is required'),
  state: Yup.string().test('state-required', 'State is required', function (value) {
    const { country } = this.parent;
    if (country === 'US') {
      return !!value;
    }
    return true;
  }),
  postal_code: Yup.string().required('Postal Code is required'),
  country: Yup.string().required('Country is required'),
});

const AddBlankAddressPopover = ({ payload }: AddBlankAddressPopoverProps) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();

  const [isSuggestedAddressLoading, setIsSuggestedAddressLoading] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState<AddressInstance | null>(null);
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();

  const { mutate: updateBlankAddress, isPending } = useMutation({
    mutationFn: (payload: Partial<AddressInstance>[]) => {
      return updateAddress(orgId, payload);
    },
    onSuccess: () => {
      handleSuccessNotification('Address successfully added.');
      queryClient.invalidateQueries({ queryKey: [TRANSACTION_STATE_KEY] });
    },
  });

  const initialFormValues = {
    phone: payload?.phone,
    street_1: payload?.street_1,
    street_2: payload?.street_2,
    city: payload?.city,
    county: payload?.county,
    state: payload?.state,
    postal_code: payload?.postal_code,
    country: payload?.country,
    type: payload?.type,
    status: AddressStatus.BLANK,
    id: payload?.id,
    transaction_id: payload?.transaction_id,
  };

  const { values, setFieldValue, handleChange, handleSubmit, handleReset, touched, isValid, errors, dirty } = useFormik(
    {
      initialValues: suggestedAddress || replaceNullWithEmptyString(initialFormValues),
      enableReinitialize: true,
      validationSchema: ValidationSchema,
      onSubmit: values => {
        updateBlankAddress([
          {
            ...values,
            status:
              suggestedAddress && isMatch(values, suggestedAddress)
                ? AddressStatus.PARTIALLY_VERIFIED
                : AddressStatus.UNVERIFIED, // If suggested address not changed, status should be partially verified otherwise unverified
          },
        ]);
      },
    }
  );

  const handleBlankAddressSuggestion = async (addressId: string) => {
    try {
      setIsSuggestedAddressLoading(true);
      const { data: response } = await blankAddressSuggestions(orgId, addressId);
      const updatedAddress = {
        ...values,
        city: response.city,
        state: response.state,
        postal_code: response.postal_code,
        country: response.country,
      };
      setSuggestedAddress(replaceNullWithEmptyString(updatedAddress));
    } catch (error) {
      handleFailNotification(error);
    } finally {
      setIsSuggestedAddressLoading(false);
    }
  };

  const handleStateChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedStateName = event.target.value;
    const selectedState = USStates.find(state => state.value === selectedStateName);

    setFieldValue('state', selectedState ? selectedState.value : '');
  };

  const handleCountryChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = event.target.value;
    if (selectedCountry !== 'US') {
      setFieldValue('state', '');
      setFieldValue('county', '');
    }
    setFieldValue('country', selectedCountry);
  };

  const isDisabled =
    (payload?.country === 'US' && values?.country !== 'US') || (payload?.country !== 'US' && values?.country !== 'US');

  return (
    <Popover placement="bottom-end">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button variant={'outline'} borderWidth={'1px'} width={'6.75rem'}>
              Add Address
            </Button>
          </PopoverTrigger>
          <PopoverContent width={'36.75rem'}>
            <PopoverArrow />
            <PopoverBody>
              <HStack justifyContent={'space-between'} alignItems={'center'} pb={4}>
                <Text fontWeight={'medium'} fontSize={'md'}>
                  Ship To
                </Text>
                <Flex alignItems={'center'} gap={4}>
                  <AIIcon
                    height={20}
                    width={20}
                    onClick={() => handleBlankAddressSuggestion(payload?.id || '')}
                    cursor={'pointer'}
                  />
                  <PopoverCloseButton
                    style={{ position: 'relative', top: '0' }}
                    onClick={() => {
                      onClose();
                      handleReset({});
                    }}
                  />
                </Flex>
              </HStack>

              <form onSubmit={handleSubmit}>
                <HStack align={'baseline'} gap={4}>
                  <Skeleton isLoaded={!isSuggestedAddressLoading} display={'flex'} width="full">
                    <FormControl isRequired>
                      <FormLabel htmlFor="country">Country</FormLabel>
                      <Select id="country" name="country" value={values.country || ''} onChange={handleCountryChange}>
                        <>
                          {!payload?.country ? (
                            <option value="">Select country</option>
                          ) : (
                            <option value={payload?.country}>
                              {CountryOptions.find(({ value }) => value === payload?.country)?.label}
                            </option>
                          )}
                          {CountryOptions.map(({ label, value }) => (
                            <>
                              <option key={value} value={value}>
                                {label}
                              </option>
                            </>
                          ))}
                        </>
                      </Select>
                      {errors.country && touched.country && <Text color={'#E53E3E'}>{String(errors.country)}</Text>}
                    </FormControl>
                  </Skeleton>

                  <Skeleton isLoaded={!isSuggestedAddressLoading} display={'flex'} width="full">
                    <FormControl isRequired>
                      <FormLabel htmlFor="state">State</FormLabel>
                      <Select
                        isDisabled={isDisabled}
                        id="state_code"
                        name="state_code"
                        value={values.state || ''}
                        onChange={handleStateChange}
                      >
                        {isDisabled ? (
                          <option value="">Select state</option>
                        ) : (
                          <>
                            {!payload?.state || payload?.state === '' ? (
                              <option value="">Select state</option>
                            ) : (
                              <option value={payload?.state}>
                                {USStates.find(option => option.value === payload?.state)?.label}
                              </option>
                            )}
                            {USStates.map(option => (
                              <>
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              </>
                            ))}
                          </>
                        )}
                      </Select>
                      {errors.state && touched.state && <Text color={'#E53E3E'}>{String(errors?.state)}</Text>}
                    </FormControl>
                  </Skeleton>
                </HStack>
                <Box height={'16px'} />
                <HStack align={'baseline'} gap={4}>
                  <Skeleton isLoaded={!isSuggestedAddressLoading} display={'flex'} width="full">
                    <FormControl isRequired>
                      <FormLabel htmlFor="city">City</FormLabel>
                      <Input
                        id="city"
                        type="text"
                        name="city"
                        value={toNormalCase(values.city ?? '')}
                        onChange={handleChange}
                        placeholder={payload?.city}
                      />
                      {errors.city && touched.city && <Text color={'#E53E3E'}>{String(errors.city)}</Text>}
                    </FormControl>
                  </Skeleton>

                  <Skeleton isLoaded={!isSuggestedAddressLoading} display={'flex'} width="full">
                    <FormControl isRequired>
                      <FormLabel htmlFor="postal_code">Postal Code</FormLabel>
                      <Input
                        id="postal_code"
                        name="postal_code"
                        value={values.postal_code || ''}
                        onChange={handleChange}
                        placeholder={payload?.postal_code}
                      />
                      {errors.postal_code && touched.postal_code && (
                        <Text color={'#E53E3E'}>{String(errors.postal_code)}</Text>
                      )}
                    </FormControl>
                  </Skeleton>
                </HStack>
                <Box height={'16px'} />
                <HStack align={'baseline'} gap={4}>
                  <Skeleton isLoaded={!isSuggestedAddressLoading} display={'flex'} width="full">
                    <FormControl>
                      <FormLabel htmlFor="street_1">Address Line 1</FormLabel>
                      <Input
                        id="street_1"
                        type="text"
                        name="street_1"
                        value={values.street_1 || ''}
                        onChange={handleChange}
                        placeholder={payload?.street_1}
                      />
                    </FormControl>
                  </Skeleton>

                  <Skeleton isLoaded={!isSuggestedAddressLoading} display={'flex'} width="full">
                    <FormControl>
                      <FormLabel htmlFor="street_2">Address Line 2</FormLabel>
                      <Input
                        id="street_2"
                        type="text"
                        name="street_2"
                        value={values.street_2 || ''}
                        onChange={handleChange}
                        placeholder={payload?.street_2}
                      />
                    </FormControl>
                  </Skeleton>
                </HStack>

                <HStack mt={4} justifyContent={'flex-end'}>
                  <Skeleton
                    isLoaded={!isSuggestedAddressLoading}
                    display={'flex'}
                    width="full"
                    justifyContent="flex-end"
                  >
                    <Button
                      type="submit"
                      isDisabled={!isValid || (!suggestedAddress && !dirty) || isPending}
                      isLoading={isPending}
                      variant={'solid'}
                      width={'90px'}
                      onClick={() => {
                        handleSubmit();
                        onClose();
                      }}
                    >
                      Save
                    </Button>
                  </Skeleton>
                </HStack>
              </form>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default AddBlankAddressPopover;
