import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import React, { forwardRef } from 'react';

import Modal from './Modal';

interface Props extends ModalProps {
  header?: string | React.ReactNode;
  footer?: React.ReactNode;
  footerProps?: ModalFooterProps;
}

const KS_ModalPopup = ({ header, footer, children, footerProps, ...rest }: Props) => {
  return (
    <Modal {...rest}>
      <ModalOverlay />
      <ModalContent>
        {header && (
          <ModalHeader fontSize={18} fontWeight={500}>
            {header}
            <ModalCloseButton />
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
        {footer && <ModalFooter {...footerProps}>{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(KS_ModalPopup);
