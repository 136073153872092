import { Grid, GridItem, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getProductById, PRODUCT_STATE_KEY } from 'apis/product-apis';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import { useOrg } from 'hooks/useOrg';
import { getCategory, getProductStatus, getSource } from 'utils/enum-helpers';
import { humanizeProductSubcategory } from 'utils/product-utils';

export const ProductModal = ({ isOpen, onClose, product_id }: any) => {
  const { orgId } = useOrg();

  const { isPending, data: productDetails } = useQuery({
    queryKey: [PRODUCT_STATE_KEY, orgId, product_id],
    queryFn: async () => {
      const { data } = await getProductById(product_id, orgId);
      return data;
    },
  });

  if (!productDetails) return;

  return (
    <ModalPopup
      closeOnOverlayClick={false}
      isCentered
      header={'Product Details'}
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      scrollBehavior="inside"
    >
      {isPending ? (
        'Loading...'
      ) : (
        <Grid templateColumns="repeat(2, 1fr)" gap={'16px'}>
          <GridItem colSpan={1}>
            <Text fontWeight={500}>Name</Text>
            <Text>{productDetails?.name}</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Text fontWeight={500}>Description</Text>
            <Text>{productDetails?.description}</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Text fontWeight={500}>Category</Text>
            <Text>{productDetails?.product_category && getCategory(productDetails.product_category)}</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Text fontWeight={500}>Subcategory</Text>
            <Text>
              {productDetails?.product_subcategory && humanizeProductSubcategory(productDetails.product_subcategory)}
            </Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Text fontWeight={500}>Source</Text>
            <Text>{productDetails?.source && getSource(productDetails.source)}</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Text fontWeight={500}>Tax Exempt</Text>
            <Text>{productDetails.tax_exempt === false ? 'No' : 'Yes'}</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Text fontWeight={500}>Status</Text>
            <Text>{getProductStatus(productDetails.status)}</Text>
          </GridItem>
        </Grid>
      )}
    </ModalPopup>
  );
};
