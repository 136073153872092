import {
  Checkbox,
  CheckboxGroup,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import Text from 'component-library/components/Text';
import { ColumnIcon } from 'components/icons';
import { useState } from 'react';
import { capitalizeFirstLetter } from 'utils';

type ColumnFilterProps = {
  headers: string[];
  onToggleColumn: (columns: string[]) => void;
  visibleColumns: string[];
  dropdownTopOffset?: number;
};

/**
 * ColumnFilter component allows users to toggle the visibility of table columns.
 * It accepts the headers of all table as props and displays checkbox with those headers in dropdown.
 * visibleColumns contain header names that are to be displayed in table
 * The dropdown is positioned relative to the filter button, position can be changed by dropdownTopOffset.
 */
const ColumnFilter = ({ headers, onToggleColumn, visibleColumns }: ColumnFilterProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedColumns, setSelectedColumns] = useState<string[]>(visibleColumns);

  const handleCheckboxChange = (column: string) => {
    setSelectedColumns(prev => (prev.includes(column) ? prev.filter(col => col !== column) : [...prev, column]));
  };

  const handleApply = () => {
    onToggleColumn(selectedColumns);
  };

  const handleCancel = () => {
    setSelectedColumns(visibleColumns);
    onClose();
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom-start"
      offset={[-5, -8]}
      closeOnBlur={true}
      preventOverflow
      returnFocusOnClose={false}
      strategy="fixed"
    >
      <Tooltip label="Customize Columns" placement="left" shouldWrapChildren>
        <PopoverTrigger>
          <IconButton
            onClick={onOpen}
            variant={'transparent-with-icon'}
            icon={<ColumnIcon />}
            aria-label="Filter columns"
          />
        </PopoverTrigger>
      </Tooltip>
      <PopoverContent
        bg={'#fff'}
        width={'298px'}
        padding={'16px'}
        borderRadius={'2px'}
        boxShadow="-3px -2px 6px -3px rgba(23, 24, 24, 0.08), 0px 8px 20px -4px rgba(23, 24, 24, 0.12)"
        zIndex={'1'}
      >
        <PopoverCloseButton />
        <PopoverHeader fontSize={'18px'} fontWeight="medium" borderBottom="none" pl={'0px'}>
          Customize Columns
        </PopoverHeader>
        <PopoverBody pl={'0px'}>
          <Stack gap="16px" mb={'16px'}>
            <CheckboxGroup>
              {headers.map(column => (
                <Checkbox
                  key={column}
                  isChecked={selectedColumns.includes(column)}
                  onChange={() => handleCheckboxChange(column)}
                  gap="8px"
                >
                  <Text>{capitalizeFirstLetter(column)}</Text>
                </Checkbox>
              ))}
            </CheckboxGroup>
          </Stack>
          <HStack direction="row" justifyContent={'space-around'} spacing="18px">
            <Button variant="outline" color="secondary" width={'132px'} height={'32px'} onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="solid" color="primary" width={'132px'} height={'32px'} onClick={handleApply}>
              Apply
            </Button>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ColumnFilter;
