import { Flex, Td, Text, Tr, useDisclosure } from '@chakra-ui/react';
import { FilingInstance } from 'types/shared-types';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

import FilingDetailsDrawer from '../filing-details-drawer';

const FilingHistoryTableRow = ({
  id,
  start_date,
  end_date,
  date_filed,
  total_tax_liability,
  state_name,
}: FilingInstance) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tr
        cursor={'pointer'}
        onClick={onOpen}
        _hover={{
          bg: '#EFEFF3',
        }}
      >
        <Td width={{ sm: '150px' }}>
          <Text fontWeight={400}>{state_name}</Text>
        </Td>
        <Td width={{ sm: '150px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{toDateShort(start_date)}</Text>
          </Flex>
        </Td>
        <Td width={{ sm: '150px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{toDateShort(end_date)}</Text>
          </Flex>
        </Td>
        <Td width={{ sm: '150px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{toDateShort(date_filed)}</Text>
          </Flex>
        </Td>
        <Td width={{ sm: '100px' }} isNumeric>
          <Text>{formatCurrency(total_tax_liability)}</Text>
        </Td>
      </Tr>
      {isOpen && <FilingDetailsDrawer isOpenDrawer={isOpen} onCloseDrawer={onClose} selectedFilingId={id} />}
    </>
  );
};

export default FilingHistoryTableRow;
