import { useOrgDetailsQuery } from 'apis/organizations-apis';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';

import { BusinessAddressPrompt } from '../../update-address-prompt';
import { ItemCard } from '../item-card';
import { ShopifyIcon } from './icons/shopify';

export default function ShopifyTab({ isConnected = false }: { isConnected?: boolean }) {
  const { orgId } = useOrg();

  const [isBusinessAddressPromptOpen, setIsBusinessAddressPromptOpen] = useState(false);

  const { data } = useOrgDetailsQuery(orgId);
  const isAddressMissing = data ? !data.company_city || !data.company_state || !data.company_postal_code : true;

  const openInNewTab = () => {
    window.open('https://apps.shopify.com/kintsugi', '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <ItemCard
        isConnected={isConnected}
        title={'Shopify'}
        description={'eCommerce Platform for All Businesses.'}
        icon={<ShopifyIcon />}
        onConnect={() => (isAddressMissing ? setIsBusinessAddressPromptOpen(true) : openInNewTab())}
      />
      <BusinessAddressPrompt
        isOpen={isBusinessAddressPromptOpen}
        onClose={() => setIsBusinessAddressPromptOpen(false)}
        onSuccess={openInNewTab}
      />
    </>
  );
}
