import { Flex, List, ListIcon, ListItem, Spacer, Text, VStack } from '@chakra-ui/react';
import { getBillingCheckoutHostedPage } from 'apis/billing-apis';
import Button from 'component-library/components/ButtonTmp/button';
import AlertBanner from 'components/alert/alert';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import debounce from 'lodash/debounce';
import { MdCheck } from 'react-icons/md';
import { BillingPlan } from 'types/billings';
import { chargebee } from 'utils/chargebee';

type PricingCardProps = BillingPlan & {
  onClose: () => void;
  onUpgradeSuccess: () => void;
};

export const PricingCard = ({ plan, title, price, features, info, onClose, onUpgradeSuccess }: PricingCardProps) => {
  let hasSuccessfullyCheckedOut = false;
  const { orgId, isTest } = useOrg();
  const { handleFailNotification } = useHandleNotification();
  const [, setSearchParams] = useQueryParam('');

  const handleUpgrade = async () => {
    onClose();

    const chargebeeInstance = await chargebee(isTest);
    chargebeeInstance?.openCheckout({
      layout: 'in_app',
      hostedPage: async () => {
        return await getBillingCheckoutHostedPage(orgId, plan);
      },
      error: error => {
        handleFailNotification(error);
      },
      close: () => {
        if (hasSuccessfullyCheckedOut) {
          onUpgradeSuccess();
          setSearchParams({ showAutoFileReg: 'true' });
        }
        chargebeeInstance?.closeAll();
      },
      step: (currentStep: string) => {
        console.log('currentStep', currentStep);
      },
      success: () => {
        hasSuccessfullyCheckedOut = true;
      },
    });
  };

  const debouncedHandleUpgrade = debounce(handleUpgrade, 500);

  return (
    <Flex borderRadius={'8px'} border={'1px solid #EFEFF3'} p={4} direction={'column'} gap={4} width={'350px'}>
      <Flex justify={'center'} align={'center'} direction={'column'} gap={4}>
        <Text fontSize={'1.25rem'} fontWeight="500">
          {title}
        </Text>
        <Text fontSize={'2.25rem'} fontWeight="500">
          {price}
        </Text>
      </Flex>
      <VStack>
        <List spacing={2} w="full">
          {features.map((feature, index) => (
            <ListItem key={index} w={'max-content'}>
              <ListIcon fontSize={'1.5rem'} as={MdCheck} color="#4285F4" />
              {feature}
            </ListItem>
          ))}
        </List>

        {info && <AlertBanner message={info} variant={'pricingCardVariant'} />}
      </VStack>
      <Spacer />
      <Button borderColor={'#4285F4'} width={'302px'} height={'50px'} onClick={debouncedHandleUpgrade}>
        Upgrade Plan
      </Button>
    </Flex>
  );
};
