import { Box, Popover, PopoverContent, PopoverTrigger, useOutsideClick } from '@chakra-ui/react';
import KSearchInput from 'components/SearchInput';
import { useEffect, useRef, useState } from 'react';
import { KCustomSelectProps } from 'types/kCustomSelectType';

import { useCustomSelect } from '../../hooks/useCustomSelect';
import { KCustomSelectOptions } from './KCustomSelectOptions';
import { MainInput } from './MainInput';

/**
 * Custom select dropdown component
 * @param name Name of the input field
 * @param placeholder Placeholder of the drop-down field
 * @param items (Options) items should be array of objects
 * @param itemTitle Key to display the option name
 * @param itemValue Key to select the value
 * @param value Selected option value
 * @param onChange function that handle the change option
 * @param searchBar  Default true, means searchBar visible in dropdown
 * @param isExternalSearch If false, data will be filtered with items data, if true data will be filtered from external source like-API data
 * @param searchQuery search query value
 * @param onChangeSearchQuery  function that handle the search query
 * @param renderItemContent  render customize option from parents
 * @param isDisabled  disabled the dropdown
 * @param isReadOnly  readonly the dropdown
 * @param isMatchWidth  if true the dropdown width will be same as options popover width
 */

const KCustomSelect = ({
  placeholder = 'Select',
  name,
  items,
  disabledItems = [],
  itemTitle,
  itemValue,
  value,
  onChange,
  // returnItem, // Return the selected item
  searchBar = true,
  isExternalSearch = false,
  searchQuery = '',
  onChangeSearchQuery,
  renderItemContent,
  isLoading = false,
  isDisabled = false,
  isReadOnly = false,
  isMatchWidth = true,
  popoverContentStyle,
  ...rest
}: KCustomSelectProps) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const listId = `customSelect-${name}-list`;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [internalItems, setInternalItems] = useState<KCustomSelectProps['items']>(items);
  const [internalSearchQuery, setInternalSearchQuery] = useState<string>(searchQuery);

  const { getTitle, getTitleFromValue, getValue, isItemSelected } = useCustomSelect({
    items,
    itemTitle,
    itemValue,
    value,
  });

  const popoverRef = useRef<HTMLDivElement>(null);
  const mainInputRef = useRef<HTMLInputElement>(null);

  useOutsideClick({
    ref: popoverRef,
    handler: () => setIsOpen(false),
  });

  useEffect(() => {
    setInternalItems(items);
  }, [items]);

  useEffect(() => {
    if (isOpen && searchBar) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 0);
    }
  }, [isOpen, searchBar]);

  const toggleDropdown = () => {
    if (isReadOnly) return;
    setIsOpen(!isOpen);
  };

  const handleChangeOption = (item: KCustomSelectProps['items'][number]) => {
    if (onChange) {
      const value = getValue(item);
      onChange(value);
      setIsOpen(false);
    }
  };

  const onChangeQuery = (query: string) => {
    if (isExternalSearch && onChangeSearchQuery) {
      return onChangeSearchQuery(query);
    }
    if (!query) {
      return setInternalItems(items);
    }

    setInternalSearchQuery(query);
    setInternalItems(filterItemsByQuery(query));
  };

  const filterItemsByQuery = (query: string): Array<Record<string, any>> => {
    if (!query || !internalItems?.length) return internalItems;

    return internalItems.filter(item => {
      const itemText = typeof item === 'object' ? item[itemTitle] : item;
      return String(itemText)?.toLowerCase().includes(query.toLowerCase());
    });
  };

  return (
    <Box
      position="relative"
      w="100%"
      ref={popoverRef}
      data-testid={`customSelect-${name}`}
      aria-controls={listId}
      aria-haspopup="listbox"
      aria-expanded={isOpen}
    >
      {/* MainInput display as dropdown */}
      <MainInput
        name={name}
        aria-controls={listId}
        onOpen={toggleDropdown}
        title={getTitleFromValue()}
        placeholder={placeholder}
        isOpen={isOpen}
        isLoading={isLoading}
        isDisabled={isDisabled}
        ref={mainInputRef}
        {...rest}
      />
      <Popover
        isOpen={isReadOnly ? undefined : isOpen}
        placement="bottom-start"
        trigger="click"
        offset={[0, 0]}
        matchWidth={isMatchWidth}
        strategy="fixed"
        returnFocusOnClose={false}
      >
        <PopoverTrigger>
          <Box />
        </PopoverTrigger>

        <PopoverContent
          id={listId}
          p={2}
          color="#262B47"
          bg="#FFFFFF"
          border="1px solid"
          borderColor="#E3E8EC"
          borderRadius="3"
          filter="drop-shadow(0px 8px 20px -4px rgba(23, 24, 24, 0.12))"
          width={'inherit'}
          maxHeight={'414px'} // as we will show max. 10 items first
          overflow={'auto'}
          {...popoverContentStyle}
        >
          <>
            {searchBar && (
              <KSearchInput
                ref={searchInputRef}
                query={isExternalSearch ? searchQuery : internalSearchQuery}
                setQuery={onChangeQuery}
              />
            )}
            <Box py={1} />
            <KCustomSelectOptions
              disabledItems={disabledItems}
              internalItems={internalItems}
              itemValue={itemValue}
              isItemSelected={isItemSelected}
              handleChangeOption={handleChangeOption}
              getTitle={getTitle}
              renderItemContent={renderItemContent}
            />
          </>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default KCustomSelect;
