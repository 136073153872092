import { UpgradeBillingPlanFromFreeModal } from 'pages/Configuration/components/billing-plans/upgrade-billing-plan-from-free-modal';
import { AutoFileRegisterModal } from 'pages/Configuration/components/org-settings/auto-file-register-modal';
import { CreateOrgModal } from 'pages/Configuration/components/org-settings/create-org-modal';
import FilingRegistration from 'pages/Filing/components/file-registration';
import FilingDetailsDrawer from 'pages/Filing/components/filing-details-drawer';
import { LiveDataSourceConnectModal } from 'pages/Import/components/live-data-source-connect-modal';
import { RequestRegistrationForm } from 'pages/Registrations/components/request-registration';

export const AppPopups = () => {
  return (
    <>
      <RequestRegistrationForm />
      <FilingRegistration />
      <FilingDetailsDrawer />
      <CreateOrgModal />
      <LiveDataSourceConnectModal />
      <AutoFileRegisterModal />
      <UpgradeBillingPlanFromFreeModal />
    </>
  );
};

export default AppPopups;
