import { Badge, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import TableContainer from 'component-library/components/Table/table-container';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { transactionStatusVariantMap } from 'constants/transactions';
import { useNavigate } from 'react-router-dom';
import { Transaction, TransactionStatus } from 'types/transactions';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

const TABLE_HEAD = ['ID', 'CUSTOMER NAME', 'DESCRIPTION', 'DATE', 'STATE', 'SALES', 'CALCULATED TAX', 'STATUS'];

const NUMERIC_COLUMNS = ['SALES', 'CALCULATED TAX'];

export const RelatedTransactionTable = ({ data }: any) => {
  const isDataEmpty = !data || data.length === 0;

  return (
    <>
      {isDataEmpty ? (
        ''
      ) : (
        <TableContainer>
          <Table variant="unstyled">
            <Thead>
              <Tr>
                {TABLE_HEAD.map((caption, idx) => (
                  <Th key={idx} isNumeric={NUMERIC_COLUMNS.includes(caption)}>
                    <Flex
                      alignItems="center"
                      justifyContent={NUMERIC_COLUMNS.includes(caption) ? 'flex-end' : 'flex-start'}
                      gap={1}
                    >
                      {caption}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data.map(({ id, addresses, ...rest }: Transaction) => (
                <RelatedTransactionTableRow key={id} id={id} addresses={addresses} {...rest} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const RelatedTransactionTableRow = ({
  id,
  external_id,
  date,
  total_amount,
  total_tax_amount_calculated,
  description,
  status,
  customer,
  state,
}: Transaction) => {
  const dateObject = new Date(date);
  const formattedDate = toDateShort(dateObject);
  const statusVariant = transactionStatusVariantMap[status as TransactionStatus];
  const navigate = useNavigate();
  const handleRowClick = () => {
    navigate(`/transactions/${id}`);
  };

  return (
    <Tr
      onClick={handleRowClick}
      _hover={{
        bg: '#EFEFF3',
        cursor: 'pointer',
      }}
    >
      <Td width={{ sm: '100px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{external_id}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '163px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{customer.name}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '323px' }} maxW="20rem">
        <Tooltip label={description}>
          <Flex alignItems="center" flexWrap="nowrap">
            <Text isTruncated maxWidth={'100%'}>
              {description}
            </Text>
          </Flex>
        </Tooltip>
      </Td>
      <Td width={{ sm: '120px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{formattedDate}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '60px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{state}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '163px' }} isNumeric={true}>
        <Text>{formatCurrency(total_amount)}</Text>
      </Td>
      <Td width={{ sm: '166px' }} isNumeric={true}>
        <Text>{formatCurrency(total_tax_amount_calculated)}</Text>
      </Td>
      <Td width={{ sm: '125px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          {statusVariant && <Badge variant={statusVariant.variant}>{statusVariant.title}</Badge>}
        </Flex>
      </Td>
    </Tr>
  );
};
