import { Drawer, DrawerBody, DrawerContent, DrawerOverlay } from '@chakra-ui/react';
import { TransactionDetails } from 'pages/Transactions/components/transaction-details';
import { useSearchParams } from 'react-router-dom';

export const TransactionDetailsDrawer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const transactionId = searchParams.get('transaction_id');

  const onClose = () => {
    setSearchParams({});
  };

  if (!transactionId) {
    return null;
  }

  return (
    <Drawer isOpen={!!transactionId} onClose={onClose} size="xl" placement="right">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody>
          <TransactionDetails transactionId={transactionId} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
