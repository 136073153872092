import 'react-datepicker/dist/react-datepicker.css';
import './app-fonts';

import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react';
import { AuthProvider, RequiredAuthProvider } from '@propelauth/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { handleApiErrorMessage, staticErrorMessages } from 'apis/api';
import ToastContainer from 'component-library/components/Toast/toast';
import { theme } from 'component-library/theme/theme';
import useIsEmbeddedInIframe from 'hooks/useIsEmbeddedInIframe';
import { StrictMode } from 'react';

import { AppContent } from './app-content';

const { toast } = createStandaloneToast();

export const handleGlobalErrorMessage = (error: any) => {
  const errorMessage = handleApiErrorMessage(error);
  const errorStatus = error?.status as keyof typeof staticErrorMessages;
  const errorMsgTitle = errorMessage?.title ?? `Request failed with status code ${errorStatus}`;
  const errorMsgDescription = errorMessage?.message ?? staticErrorMessages[errorStatus];

  toast({
    title: errorMsgTitle,
    description: errorMsgDescription,
    status: 'error',
    duration: 5000,
    isClosable: true,
    render: props => <ToastContainer {...props} />,
  });
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      networkMode: 'online',
    },
    mutations: {
      networkMode: 'online',
      onError: (error: any) => {
        console.log('mutation-error', error);
        handleGlobalErrorMessage(error);
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      console.log('queryCache-error', error);
      handleGlobalErrorMessage(error);
    },
  }),
});

const App = () => {
  const isEmbeddedInIframe = useIsEmbeddedInIframe();

  // If the app is embedded in an iframe, this means the app is opened in Shopify
  // Then, we will open it in a new tab for authentication if needed
  // ToDo: we should open new tab only when the user is not authenticated
  const AuthProviderWrapper = isEmbeddedInIframe ? AuthProvider : RequiredAuthProvider;
  return (
    <StrictMode>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthProviderWrapper authUrl={import.meta.env.VITE_AUTH_URL}>
            <AppContent isEmbeddedInIframe={isEmbeddedInIframe} />
          </AuthProviderWrapper>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ChakraProvider>
    </StrictMode>
  );
};

export default App;
