import { Flex, HStack, IconButton, Stack, Td, Tr, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { TRANSACTION_STATE_KEY } from 'apis/transaction-api';
import { getTransactions } from 'apis/transactions';
import AppHeader from 'app/app-header';
import { TableSkeleton } from 'component-library/components/Skeleton/table-skeleton';
import Text from 'component-library/components/Text';
import { AIIcon } from 'components/icons';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { useOrg } from 'hooks/useOrg';
import { useTableFilters } from 'hooks/useTableFilters';
import TransactionSearch from 'pages/Transactions/components/TransactionSearch';
import { AddressInstance, AddressStatus, TransactionInstance } from 'types/shared-types';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

import AddBlankAddressPopover from './AddBlankAddressPopover';
import AIBlankAddressAddModal from './AIBlankAddressAddModal';

const TABLE_HEAD = ['ID', 'DESCRIPTION', 'DATE', 'AMOUNT', 'CUSTOMER NAME', ''];
const NUMERIC_COL = ['AMOUNT'];

const BlankAddressTransactions = () => {
  const { orgId } = useOrg();
  const { isOpen: isOpenAIModal, onOpen: onOpenAIModal, onClose: onCloseAIModal } = useDisclosure();
  const transactionTableFilters = useTableFilters({
    order_by: ['-date'],
    address_status__in: [AddressStatus.BLANK],
  });
  const { page, size, order_by, address_status__in, search_query, setFilters } = transactionTableFilters;
  const { isPending, data } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, orgId, page, size, order_by, address_status__in, search_query],
    queryFn: async () => {
      const { data } = await getTransactions(orgId, {
        page,
        size,
        order_by,
        address_status__in,
        search_query,
      });
      return data;
    },
  });

  const blankAddressTransactionData = data?.items || [];
  const hasData = blankAddressTransactionData?.length;
  const totalPages = data?.pages || 0;

  return (
    <Stack>
      <HStack gap={1} justifyContent={'space-between'} alignItems={'center'}>
        <AppHeader />
        <HStack>
          <TransactionSearch tableFilters={transactionTableFilters} />
          <IconButton
            aria-label={'Revalidate Address using kintsugi intelligence'}
            mr={1}
            variant={'transparent-with-icon'}
            onClick={onOpenAIModal}
          >
            <AIIcon />
          </IconButton>
        </HStack>
      </HStack>

      {isPending && <TableSkeleton items={size} />}
      {!isPending && !hasData && (
        <TableEmptyState tableName="Blank Address Transactions" isSearchApplied={!!search_query} />
      )}
      {!isPending && hasData && (
        <KDataTable headers={TABLE_HEAD} numericCols={NUMERIC_COL} showColumnFilter>
          {blankAddressTransactionData?.map(transaction => {
            return <BlankAddressTransactionTableRow key={transaction.id} transaction={transaction} />;
          })}
        </KDataTable>
      )}
      {!isPending && hasData && (
        <PaginationButtons size={size} currentPage={page} totalPages={totalPages} setFilters={setFilters} />
      )}
      {isOpenAIModal && <AIBlankAddressAddModal isOpen={isOpenAIModal} onClose={onCloseAIModal} />}
    </Stack>
  );
};

export default BlankAddressTransactions;

const BlankAddressTransactionTableRow = ({ transaction }: { transaction: TransactionInstance }) => {
  const { external_id, id, description, date, total_amount, customer } = transaction;

  return (
    <Tr>
      <Td width={'8.75rem'}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{external_id}</Text>
        </Flex>
      </Td>
      <Td width={'24.5rem'}>
        <Text noOfLines={1} maxWidth={'100%'}>
          {description}
        </Text>
      </Td>
      <Td width={'7.5rem'}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{toDateShort(date)}</Text>
        </Flex>
      </Td>

      <Td width={'9.18rem'} isNumeric={true}>
        <Text>{formatCurrency(total_amount)}</Text>
      </Td>

      <Td width={'17.5rem'}>
        <Text noOfLines={1}>{customer.name}</Text>
      </Td>

      <Td width={'8.75rem'}>
        {/* blank address always has only one address bill to */}
        <AddBlankAddressPopover key={id} payload={transaction.addresses[0] as AddressInstance} />
      </Td>
    </Tr>
  );
};
