import { Flex, FormControl, HStack, IconButton, Stack, Switch } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CUSTOMERS_STATE_KEY } from 'apis/customers';
import { createExemption, uploadResellerCertificate } from 'apis/exemption-apis';
import { TRANSACTION_STATE_KEY } from 'apis/transaction-api';
import Button from 'component-library/components/ButtonTmp/button';
import DatePicker from 'component-library/components/date-picker/date-picker';
import FormLabel from 'component-library/components/FormLabel';
import { KInput } from 'component-library/components/Input';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Select from 'component-library/components/Select/Select';
import Text from 'component-library/components/Text';
import useToast from 'component-library/hooks/useToast';
import { RemoveIcon } from 'components/icons';
import { USStates } from 'constants/app-constants';
import { addYears, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
import { getFormattedDate } from 'utils/dates';
import { date, object, ref, string } from 'yup';

const validationSchema = object().shape({
  start_date: date().required('Start date is required'),
  end_date: date().min(ref('start_date'), "End date can't be before start date").required('End date is required'),
  jurisdiction: string().required('Jurisdiction is required'),
  FEIN: string().required('FEIN is required'),
  sales_tax_id: string().required('Sales Tax Id is required'),
});

type ExemptionsFormProps = {
  isOpen: boolean;
  onClose: () => void;
  customerId: string;
  transactionId?: string;
  transactionDate?: string;
};

export const ExemptionsForm = ({
  isOpen,
  onClose,
  customerId,
  transactionId,
  transactionDate,
}: ExemptionsFormProps) => {
  const { orgId } = useOrg();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [fileName, setFileName] = useState('');
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const mutation = useMutation({
    mutationFn: (payload: any) => {
      return createExemption(orgId, payload);
    },
    onSuccess: data => {
      const file = formik.values.certificate_location;
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        uploadResellerCertificate(data.id, orgId, formData)
          .then(() => {
            queryInvalidate();
            handleSuccessNotification('Exemption added successfully');
          })
          .catch(error => {
            handleFailNotification(error);
          });
      } else {
        queryInvalidate();
        handleSuccessNotification('Exemption added successfully');
      }
      onClose();
    },
  });

  const queryInvalidate = () => {
    queryClient.invalidateQueries({ queryKey: [CUSTOMERS_STATE_KEY, orgId, customerId, 'exemptions'] });
    queryClient.invalidateQueries({ queryKey: [TRANSACTION_STATE_KEY, transactionId] });
  };

  const calculateEndDate = (startDate: string) => {
    const endDate = addYears(parseISO(startDate), 3);
    return getFormattedDate(endDate, 'yyyy-MM-dd');
  };

  const handleStartDateChange = (date: string) => {
    const endDate: string = calculateEndDate(date);
    formik.setFieldValue('start_date', date);
    formik.setFieldValue('end_date', endDate);
  };

  const currentDate = transactionDate ? transactionDate : getFormattedDate(new Date(), 'yyyy-MM-dd');
  const endDate = calculateEndDate(currentDate);

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    handleFileSelection(droppedFile);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileSelection = (selectedFile: File) => {
    if (selectedFile.type !== 'application/pdf') {
      toast({
        status: 'error',
        title: 'Invalid file type',
        description: 'Only PDF file is allowed.',
      });
      return;
    }
    setFileName(selectedFile.name);
    formik.setFieldValue('certificate_location', selectedFile);
  };

  const determineExemptionType = (isReseller: boolean, transactionId?: string) => {
    if (isReseller) {
      return 'wholesale';
    }
    return transactionId ? 'transaction' : 'customer';
  };

  const formik = useFormik({
    initialValues: {
      exemption_type: determineExemptionType(false, transactionId),
      customer_id: customerId,
      start_date: currentDate,
      end_date: endDate,
      FEIN: '',
      sales_tax_id: '',
      jurisdiction: '',
      certificate_location: '',
      reseller: false,
      status: 'ACTIVE',
      transaction_id: transactionId,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      mutation.mutate(values);
    },
  });

  return (
    <ModalPopup
      isCentered
      size={'lg'}
      isOpen={isOpen}
      onClose={onClose}
      header="Add Exemption"
      footer={
        <Flex gap={2}>
          <Button variant={'outline'} color={'secondary'} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={'solid'}
            color={'primary'}
            width={'90px'}
            onClick={() => formik.handleSubmit()}
            isLoading={mutation.isPending}
          >
            Save
          </Button>
        </Flex>
      }
    >
      <form noValidate>
        <Stack gap={'16px'}>
          <FormControl isRequired>
            <FormLabel htmlFor="jurisdiction">State</FormLabel>
            <Select
              id="jurisdiction"
              name="jurisdiction"
              value={formik.values.jurisdiction}
              onChange={formik.handleChange}
            >
              <option>Select state</option>
              {USStates.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            {formik.errors.jurisdiction && formik.touched.jurisdiction && (
              <Text color={'#E53E3E'}>{formik.errors.jurisdiction}</Text>
            )}
          </FormControl>
          <HStack align={'baseline'}>
            <FormControl isRequired>
              <FormLabel htmlFor="start_date">Start Date</FormLabel>
              <DatePicker
                maxDate={transactionDate ? new Date(transactionDate) : undefined}
                selected={formik.values.start_date}
                onChange={handleStartDateChange}
              />
              {formik.errors.start_date && formik.touched.start_date && (
                <Text color={'#E53E3E'}>{formik.errors.start_date}</Text>
              )}
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="end_date">End Date</FormLabel>
              <DatePicker
                minDate={transactionDate ? new Date(transactionDate) : undefined}
                selected={formik.values.end_date}
                onChange={date => formik.setFieldValue('end_date', date)}
              />
              {formik.errors.end_date && formik.touched.end_date && (
                <Text color={'#E53E3E'}>{formik.errors.end_date}</Text>
              )}
            </FormControl>
          </HStack>
          <HStack align={'baseline'}>
            <FormControl isRequired>
              <FormLabel htmlFor="FEIN">FEIN</FormLabel>
              <KInput id="FEIN" type="string" name="FEIN" value={formik.values.FEIN} onChange={formik.handleChange} />
              {formik.errors.FEIN && formik.touched.FEIN && <Text color={'#E53E3E'}>{formik.errors.FEIN}</Text>}
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="sales_tax_id">Sales Tax ID</FormLabel>
              <KInput
                id="sales_tax_id"
                type="string"
                name="sales_tax_id"
                value={formik.values.sales_tax_id}
                onChange={formik.handleChange}
              />
              {formik.errors.sales_tax_id && formik.touched.sales_tax_id && (
                <Text color={'#E53E3E'}>{formik.errors.sales_tax_id}</Text>
              )}
            </FormControl>
          </HStack>
          <FormControl isRequired display="flex" alignItems="center">
            <FormLabel htmlFor="reseller" mb="0" color={'#262B47'}>
              Is this customer a reseller?
            </FormLabel>
            <Switch
              id="reseller"
              name="reseller"
              isChecked={formik.values.reseller}
              onChange={e => {
                formik.handleChange(e);
                const exemptionType = determineExemptionType(e.target.checked, transactionId);
                formik.setFieldValue('exemption_type', exemptionType);
              }}
            />
          </FormControl>
          <Text>Upload Certificate</Text>
          {formik.values.certificate_location ? (
            <HStack mt={2}>
              <Text>{fileName}</Text>
              <IconButton
                variant={'transparent-with-icon'}
                aria-label="Remove file"
                onClick={() => formik.setFieldValue('certificate_location', null)}
                icon={<RemoveIcon />}
              />
            </HStack>
          ) : (
            <Flex
              onDrop={formik.values.reseller ? handleFileDrop : undefined}
              onDragOver={formik.values.reseller ? handleDragOver : undefined}
              height="78px"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              border="1px dashed #4285F4"
              borderRadius="2px"
              p={'16px'}
            >
              <Text>
                Drag and Drop your files here or{' '}
                <Text as="label" variant="outline" color={'#4285F4'} textDecoration={'underline'} cursor="pointer">
                  Choose File
                  <KInput
                    type="file"
                    id="file"
                    accept=".pdf"
                    display="none"
                    onChange={e => {
                      if (e.target.files && e.target.files.length > 0) {
                        handleFileSelection(e.target.files[0]);
                      } else {
                        toast({
                          status: 'error',
                          title: 'Error',
                          description: 'Error selecting file ',
                        });
                      }
                    }}
                    onClick={(e: any) => {
                      e.target.value = '';
                    }}
                  />
                </Text>
              </Text>
              <Text fontSize={'12px'} color={'#5F647B'}>
                Max file size: 10MB. Supported type: PDF
              </Text>
            </Flex>
          )}
        </Stack>
      </form>
    </ModalPopup>
  );
};
