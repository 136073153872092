import { handleApiErrorMessage, isAxiosError, isAxiosErrorMatchedStatus, staticErrorMessages } from 'apis/api';
import { useNavigate } from 'react-router-dom';

import { useNotification } from '../components/error/notification-context';

export const useHandleNotification = () => {
  const { notify } = useNotification();
  const navigate = useNavigate();

  const handleFailNotification = (error: any, route?: string) => {
    if (isAxiosError(error)) {
      const errorMessage = handleApiErrorMessage(error);
      const errorStatus = error?.status as keyof typeof staticErrorMessages;
      const errorMsgTitle = errorMessage?.title ?? `Request failed with status code ${errorStatus}`;
      const errorMsgDescription = errorMessage?.message ?? staticErrorMessages[errorStatus];

      notify({ type: 'error', title: errorMsgTitle, message: errorMsgDescription });

      // If you navigate to the specified route for a specific error status code
      if (route && isAxiosErrorMatchedStatus(error, 400, 404)) {
        navigate(route, { replace: true });
      }
    }
  };

  const handleSuccessNotification = (message: string, duration?: number) => {
    notify({ type: 'success', title: 'Success!', message, duration });
  };

  return { handleFailNotification, handleSuccessNotification };
};
