import { Badge, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import TableContainer from 'component-library/components/Table/table-container';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Transaction } from 'types/transactions';
import { toDateShort } from 'utils/dates';
import { formatCurrency } from 'utils/utils';

const statusBadges = {
  COMMITTED: <Badge variant={'green'}>Committed</Badge>,
  VOIDED: <Badge variant={'red'}>Voided</Badge>,
  CANCELLED: <Badge variant={'red'}>Cancelled</Badge>,
  PENDING: <Badge variant={'grey'}>Pending</Badge>,
} as Record<string, React.ReactNode>;

export const TransactionTable = ({ captions, data }: any) => {
  return (
    <TableContainer height={'500px'}>
      <Table variant="unstyled">
        <Thead>
          <Tr>
            {captions.map((caption: string, idx: number) => {
              return (
                <Th key={idx} isNumeric={['AMOUNT', 'TAX AMOUNT'].includes(caption)}>
                  {caption}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {data?.map(({ id, addresses, ...rest }: Transaction) => (
            <TransactionTableRow key={id} id={id} addresses={addresses} {...rest} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const TransactionTableRow = ({
  id,
  external_id,
  description,
  date,
  state,
  total_amount,
  total_tax_amount_calculated,
  status,
}: any) => {
  const navigate = useNavigate();
  const handleRowClick = () => {
    navigate(`/transactions/${id}`);
  };

  return (
    <Tr
      onClick={handleRowClick}
      _hover={{
        bg: '#EFEFF3',
        cursor: 'pointer',
      }}
    >
      <Td width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{external_id}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{description}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '150px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{toDateShort(date)}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '80px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{state}</Text>
        </Flex>
      </Td>
      <Td width={{ sm: '80px' }} isNumeric={true}>
        <Text>{formatCurrency(total_amount)}</Text>
      </Td>
      <Td width={{ sm: '80px' }} isNumeric={true}>
        <Text>{formatCurrency(total_tax_amount_calculated)}</Text>
      </Td>
      <Td width={{ sm: '80px' }}>
        <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
          <Text>{statusBadges[status] || null}</Text>
        </Flex>
      </Td>
    </Tr>
  );
};
