import api from './api';

export const downloadAttachmentByAttachmentId = (attachmentId: string, orgId: string) => {
  return api.get(`/v1/attachments/${attachmentId}/download`, {
    headers: {
      'x-organization-id': orgId,
    },
    responseType: 'blob',
  });
};
