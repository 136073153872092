import { Box, Flex, HStack, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import TableContainer from 'component-library/components/Table/table-container';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { NumberStyleEnum } from 'types/utils';
import { formatCurrency, formatNumber } from 'utils/utils';

import { ProductModal } from './product-modal';

type TransactionItemsTableProps = {
  captions: string[];
  data: any;
  subtotal: {
    totalAmount: number;
    totalTaxAmount: number;
  };
};

const NUMERIC_COLUMNS = ['CALCULATED TAX', 'SALES'];

export const TransactionItemsTable = ({ captions, data, subtotal }: TransactionItemsTableProps) => {
  return (
    <>
      <TableContainer maxHeight={'500px'}>
        <Table variant="unstyled">
          <Thead>
            <Tr>
              {captions.map((caption: any, idx: number) => {
                return (
                  <Th key={idx} isNumeric={NUMERIC_COLUMNS.includes(caption)}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((row: any) => (
              <TransactionItemsTableRow
                key={row.id}
                id={row.id}
                product={row.product}
                product_id={row.product_id}
                description={row.description}
                quantity={row.quantity}
                tax_amount_calculated={row.tax_amount_calculated}
                amount={row.amount}
                tax_items={row.tax_items}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box w={'262px'} ml="auto">
        <HStack p={'8px 20px 8px 31px'} justifyContent={'space-between'}>
          <Text>Subtotal</Text>
          <Text>{formatCurrency(subtotal.totalAmount)}</Text>
        </HStack>
        <HStack p={'8px 20px 8px 31px'} justifyContent={'space-between'}>
          <Text>Total Tax</Text>
          <Text>{formatCurrency(subtotal.totalTaxAmount)}</Text>
        </HStack>
        <HStack p={'8px 20px 8px 31px'} justifyContent={'space-between'} bgColor={'#ECF3FE'}>
          <Text fontWeight={500}>Total</Text>
          <Text fontWeight={500}>{formatCurrency(subtotal.totalAmount + subtotal.totalTaxAmount)}</Text>
        </HStack>
      </Box>
    </>
  );
};

const TransactionItemsTableRow = ({
  product,
  product_id,
  description,
  quantity,
  tax_amount_calculated,
  amount,
  tax_items,
}: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tr onClick={onOpen} _hover={{ cursor: 'pointer' }}>
        <Td width={{ sm: '250px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{product}</Text>
          </Flex>
        </Td>
        <Td width={{ sm: '380px' }}>
          <Flex alignItems="center" minWidth="100%" flexWrap="nowrap">
            <Text>{description}</Text>
          </Flex>
        </Td>
        <Td width={{ sm: '200px' }}>
          <Text>{quantity}</Text>
        </Td>
        <Td width={{ sm: '170px' }} isNumeric={true}>
          {tax_amount_calculated > 0 && tax_items?.length > 0 ? (
            <Tooltip
              shouldWrapChildren
              bgColor={'#262B47'}
              hasArrow={false}
              borderRadius={'2px'}
              minW={'296px'}
              p={4}
              label={tax_items.map((item: any, index: number) => (
                <>
                  <HStack p={'8px'}>
                    <Box w={'105px'}>
                      {' '}
                      <Text color={'white'} fontWeight="medium">
                        {item.name}
                      </Text>
                    </Box>
                    <Box minW={'60px'} mr={'30px'}>
                      {' '}
                      <Text color={'white'}> {formatNumber(item.rate, { style: NumberStyleEnum.PERCENT })}</Text>
                    </Box>
                    <Box minW={'50px'}>
                      {' '}
                      <Text color={'white'}> {formatCurrency(item.amount)}</Text>
                    </Box>
                  </HStack>
                  {index < tax_items.length - 1 && <hr style={{ borderTop: '1px solid #383D58' }} />}
                </>
              ))}
            >
              <Text>{formatCurrency(tax_amount_calculated)}</Text>
            </Tooltip>
          ) : (
            <Text>{formatCurrency(tax_amount_calculated)}</Text>
          )}
        </Td>
        <Td width={{ sm: '220px' }} isNumeric={true}>
          <Text>{formatCurrency(amount)}</Text>
        </Td>
      </Tr>
      {product_id && isOpen && <ProductModal isOpen={isOpen} onClose={onClose} product_id={product_id} />}
    </>
  );
};
