import { getMaxLengthMessage } from 'utils/utils';
import { InferType, mixed, object, string } from 'yup';

import { AddressStatus, AddressType, CountryCodeEnum } from './shared-types';

export type CountryOptionsType = { label: string; value: string };
export type CountryStateOptionsType = { label: string; value: string };

export const AddressValidationSchema = object().shape({
  phone: string().optional(),
  street_1: string().required('Address Line 1 is required'),
  street_2: string().optional(),
  country: mixed<CountryCodeEnum>().oneOf(Object.values(CountryCodeEnum)).required(),
  state: string().test('state-required', 'State is required', function (value) {
    const { country } = this.parent;
    if (country === 'US') {
      return !!value;
    }
    return true;
  }),
  city: string().required('City is required'),
  postal_code: string().required('Zip is required').max(50, getMaxLengthMessage('Zip', 200)),
  county: string().optional(),
  type: mixed<AddressType>().oneOf(Object.values(AddressType)).required(),
  status: mixed<AddressStatus>().oneOf(Object.values(AddressStatus)).required(),
});

export type AddressResSchema = InferType<typeof AddressValidationSchema>;

export interface SuggestedAddress {
  city?: string;
  county?: string;
  state?: string;
  postal_code?: string;
  country?: CountryCodeEnum;
}

export const UpdateAddressPromptValidationSchema = object().shape({
  business_name: string().required('Business Name is required'),
  company_address_1: string().required('Address Line 1 is required'),
  company_address_2: string().optional(),
  company_city: string().required('City is required'),
  company_state: string()
    .nullable()
    .trim()
    .test('is-US-state-required', 'Business State required', function (value) {
      const { country } = this.parent;
      if (country === 'US' && !value) {
        return false;
      }
      return true;
    }),
  company_postal_code: string().required('Postal code is required'),
  company_country_code: string().required('Country is required'),
  county: string().optional(),
});
