import { ExemptionsService } from '_api-client';

import api from './api';

export const getExemptions = (orgId: string, status: string, page: number, size: number) => {
  return api.get(`/v1/exemptions`, {
    method: 'GET',
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      status: status,
      page: page,
      size: size,
    },
  });
};

export const uploadResellerCertificate = (exemptionId: string, orgId: string, formData: FormData) => {
  return api.post(`/v1/exemptions/${exemptionId}/attachments`, formData, {
    headers: {
      'x-organization-id': orgId,
      'Content-Type': 'multipart/form-data',
    },
    params: {
      organization_id: orgId,
      exemption_id: exemptionId,
    },
  });
};

export const downloadResellerCertificate = (attachmentId: string, orgId: string) => {
  return api.get(`/v1/attachments/${attachmentId}/download`, {
    headers: {
      'x-organization-id': orgId,
    },
    responseType: 'blob',
  });
};

const getExemptionsForCustomer = (orgId: string, customerId: string) => {
  return api.get(`/v1/exemptions`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      customer_id: customerId,
    },
  });
};

const createExemption = (orgId: string, payload: any) => {
  return ExemptionsService.createExemptionV1ExemptionsPost({ xOrganizationId: orgId, requestBody: payload });
};

const updateExemption = (orgId: string, exemptionId: string, payload: any) => {
  return api.put(`/v1/exemption/${exemptionId}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

export const getAttachmentById = (orgId: string, attachmentId: string) => {
  return api.get(`/v1/attachments/${attachmentId}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

export { createExemption, getExemptionsForCustomer, updateExemption };
