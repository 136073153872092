import axios, { AxiosError, AxiosResponse } from 'axios';
import { getToken } from 'cookie-handler';
import { SELECTED_ORG_KEY } from 'hooks/useOrg';
import isArray from 'lodash/isArray';

axios.defaults.baseURL = import.meta.env.VITE_API_URL || 'http://localhost:8000';
axios.defaults.withCredentials = false;

const api = axios;

/**
 * Reasons for reload and cleanup the selected organization.
 * Being more specific here to avoid removing the selected org when 403 (FORBIDDEN)
 * is returned for other reasons
 * - Invalid bearer token
 * - User does not have access to the specified organization
 */
const REASONS_FOR_RELOAD = ['Invalid bearer token', 'User does not have access to the specified organization'];

/**
 * axios request interceptors
 */
axios.interceptors.request.use(function (config) {
  const accessToken = getToken();
  config.headers.Authorization = `Bearer ${accessToken}`;
  if (import.meta.env.VITE_ENVIRONMENT === 'local') {
    config.headers['ngrok-skip-browser-warning'] = '1234';
  }
  return config;
});

// Helper functions
const handleBadRequest = (error: ApiError) => {
  console.error('Bad request', error);
};

const handleUnauthorized = () => {
  // Reload so propelauth can try to fetch new access token or redirect to login
  window.location.reload();
};

const handleForbidden = (error: ApiError) => {
  const detail = (error.response?.data as { detail?: string })?.detail;
  if (typeof detail === 'string' && REASONS_FOR_RELOAD.includes(detail)) {
    localStorage.removeItem(SELECTED_ORG_KEY);
    window.location.reload();
  }
};

const handleMethodNotAllowed = (error: ApiError) => {
  if (error.response) {
    error.response.data = {
      detail: 'Method Not Allowed. Try with a different method.',
    };
  }
};

const handleInternalServerError = (error: ApiError) => {
  console.error('Internal server error', error);
};

/**
 * main axios response interceptors
 */
axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError): Promise<AxiosError> => {
    const apiError = error as ApiError;
    const status = apiError.response?.status;

    const errorHandlers: Record<number, (error: ApiError) => void> = {
      400: handleBadRequest,
      401: handleUnauthorized,
      403: handleForbidden,
      405: handleMethodNotAllowed,
      500: handleInternalServerError,
    };

    if (status && status in errorHandlers) {
      errorHandlers[status](apiError);
    } else {
      console.error(`Unhandled status code: ${status}`, apiError);
    }

    return Promise.reject(error);
  }
);
export type ApiError = AxiosError;

export default api;

export const handleApiErrorMessage = (err: any) => {
  try {
    if (axios.isCancel(err) || err.name === 'AbortError') {
      // xhr's Promise was cancelled, probably by our code with AbortController.abort()
      return;
    }
    return parseMessageFromError(err);
  } catch (error) {
    console.error('apiError', error);
  }
};

export const parseMessageFromError = (error: any): { title: string; message: string } => {
  let title = '';
  let message = '';
  const errorData = error?.response?.data;
  title = error?.message;

  if (errorData?.detail && isArray(errorData?.detail)) {
    message = errorData.detail[0].msg;
  } else if (errorData?.detail?.msg) {
    message = errorData.detail.msg;
  } else if (errorData?.detail) {
    message = errorData.detail;
  }

  // please add more cases here if needed
  return { title, message };
};

export const isAxiosError = (error: any): error is AxiosError => {
  return !!error?.response;
};

/**
 * Check the error is an Axios error and it matches the specified status codes.
 *
 * @param {any} error - The error object.
 * @param {...number[]} statusCodes - The status codes to check against.
 * @returns {boolean} True if the error is an Axios error with any of the specified status codes, false otherwise.
 */
export const isAxiosErrorMatchedStatus = (error: any, ...statusCodes: number[]) => {
  return isAxiosError(error) && statusCodes.includes(error.response?.status || 0);
};

export const staticErrorMessages = {
  400: 'Bad request. Please check your input.',
  401: 'Unauthorized. Please log in.',
  403: 'Forbidden. You do not have permission to perform this action.',
  404: 'Resource not found.',
  500: 'Internal server error. Please try again later.',
};
