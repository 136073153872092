import {
  AddressStatus,
  AddressType,
  BillingPlanEnum,
  ConnectionStatusEnum,
  CountryCodeEnum,
  ExemptionStatus,
  FilingFrequencyEnum,
  FilingStatusEnum,
  operations,
  ProcessingStatusEnum,
  ProductCategoryEnum,
  ProductStatusEnum,
  RegistrationStatusEnum,
  SourceEnum,
} from '../schema/types-schema.d';
import { components } from './index';
import { Maybe } from './utils';

export type DashboardApiResponse = components['schemas']['DashboardData'];
export {
  AddressStatus,
  AddressType,
  ConnectionStatusEnum as ConnectionStatus,
  ExemptionStatus,
  FilingFrequencyEnum,
  FilingStatusEnum as FilingStatus,
  ProcessingStatusEnum,
  ProductCategoryEnum,
  ProductStatusEnum,
  RegistrationStatusEnum as RegistrationStatus,
  SourceEnum as Source,
};

export enum PeriodModelEnum {
  CURRENT_OR_PREVIOUS = 'CURRENT_OR_PREVIOUS',
  PRECEDING_YEAR = 'PRECEDING_YEAR',
  PRECEDING_YEAR_QUARTERLY = 'PRECEDING_YEAR_QUARTERLY',
  PRECEDING_YEAR_QUARTERLY_OFFSET = 'PRECEDING_YEAR_QUARTERLY_OFFSET',
  PRECEDING_YEAR_FROM_OCTOBER = 'PRECEDING_YEAR_FROM_OCTOBER',
}
export type Period = {
  end_date: string;
  start_date: string;
  transactions_count: number;
  transactions_amount: string;
  transactions_exempted: number;
  transactions_included: number;
  transactions_marketplace: number;
  transactions_tax_liability_amount: string;
  nexus_state: NexusStatus;
};
export interface NexusInstance {
  country_code: string;
  state_code: string;
  state_name: string;
  treatment_of_exempt_transactions: string;
  trigger: string;
  sales_or_transactions: string;
  threshold_sales: number;
  threshold_transactions: number | null;
  start_date: string;
  physical: boolean;
  services: boolean;
  digital: boolean;
  transaction_count: number;
  transactions_amount: number;
  previous_transaction_count: number;
  previous_transactions_amount: number;
  tax_liability: number;
  nexus_met: boolean;
  nexus_met_date: string | null;
  period_model: string;
  period_start_date: string;
  period_end_date: string;
  previous_period_start_date: string | null;
  previous_period_end_date: string | null;
  most_recent_transaction_date: string | null;
  periods: Period[] | null;
  registration_id?: string | null;
  registration?: {
    id: string;
    status: RegistrationStatusEnum;
  };
  physical_nexus_met?: boolean;
  economic_nexus_met?: boolean;
  processing_status?: string;
  status?: string;
  id?: string;
  collected_tax_nexus_met?: boolean | null;
  collected_tax_nexus_met_date?: string | null;
  collected_tax_enabled?: boolean | null;
  total_transactions?: number;
  total_transactions_included?: number;
  total_transactions_exempted?: number;
  total_transactions_marketplace?: number;
}

export type ConnectionInstance = components['schemas']['ConnectionRead'];

export interface RegistrationInstance {
  status: RegistrationStatusEnum;
  country_code: string;
  state_code: string;
  state_name: string;
  registration_date: string | null;
  registration_email: string | null;
  filing_frequency: FilingFrequencyEnum;
  filing_days: number;
  id: string;
  is_manual: boolean;
  username: string;
  create_filings_from: string | null;
  billingPlan: BillingPlanEnum;
  has_all_credentials?: boolean;
  amount_fees: string;
  vda: boolean;
}

export type ProductInstance = components['schemas']['ProductRead'];
export type FilingInstance = components['schemas']['FilingRead'];
export type FilingInstanceCreate = components['schemas']['FilingCreate'];
export type FilingDetailsInstance = components['schemas']['FilingDetailsRead'];
export type CustomerInstance = components['schemas']['CustomerRead'];
export type ExemptionInstance = components['schemas']['ExemptionRead'];
export type AddressInstance = components['schemas']['TransactionAddressRead'];
export type PhysicalNexusInstance = components['schemas']['PhysicalNexusRead'];
export type OrgDetailsInstance = components['schemas']['OrganizationDetailsRead'];
export type BankDetailsInstance = components['schemas']['BankDetailsRead'];
export type OrgInstance = components['schemas']['OrganizationRead'];
export type TransactionInstance = components['schemas']['TransactionRead'] & { customer: CustomerInstance };
export { CountryCodeEnum as CountryCodeEnum };
export type AutoFileAutoRegisterInstance = {
  auto_file: boolean;
  auto_register: boolean;
};

export interface FilingStateOption {
  registration_id: string;
  state_name: string;
  state_code: string;
  registration_date: string;
}

export enum SalesOrTransactions {
  EITHER = 'EITHER',
  SALES = 'SALES',
  BOTH = 'BOTH',
}

export enum NexusStatus {
  EXPOSED = 'EXPOSED',
  APPROACHING = 'APPROACHING',
  PENDING_REGISTRATION = 'PENDING_REGISTRATION',
  REGISTERED = 'REGISTERED',
  NOT_EXPOSED = 'NOT_EXPOSED',
}

export type TaxItem = components['schemas']['TaxItemRead'];
export type TransactionItems = components['schemas']['TransactionItemRead'];
export type TaxDetailsFilterProps =
  operations['get_dashboard_details_v1_dashboard_details_aggregations__aggregation_type__get']['parameters']['query'] & {
    startDate?: string;
    endDate?: string;
  };

export enum EntityType {
  C_CORPORATION = 'C_CORPORATION',
  COOPERATIVE_CO_OP = 'COOPERATIVE_CO_OP',
  CORPORATION = 'CORPORATION',
  GENERAL_PARTNERSHIP = 'GENERAL_PARTNERSHIP',
  HYBRID_LLC = 'HYBRID_LLC',
  JOINT_VENTURE = 'JOINT_VENTURE',
  LLC = 'LLC',
  LLC_TAXED_AS_C_CORPORATION = 'LLC_TAXED_AS_C_CORPORATION',
  LLC_TAXED_AS_S_CORPORATION = 'LLC_TAXED_AS_S_CORPORATION',
  LIMITED_LIABILITY_LIMITED_PARTNERSHIP = 'LIMITED_LIABILITY_LIMITED_PARTNERSHIP',
  LIMITED_LIABILITY_PARTNERSHIP = 'LIMITED_LIABILITY_PARTNERSHIP',
  LIMITED_PARTNERSHIP = 'LIMITED_PARTNERSHIP',
  MULTI_MEMBER_LLC = 'MULTI_MEMBER_LLC',
  NONPROFIT = 'NONPROFIT',
  PROFESSIONAL_LLC = 'PROFESSIONAL_LLC',
  SINGLE_MEMBER_LLC = 'SINGLE_MEMBER_LLC',
  S_CORPORATION = 'S_CORPORATION',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
}

export type TableFilter = {
  status?: string;
  status__in?: string;
  address_status__in?: string;
  transaction_source?: string;
  transaction_type?: string;
  start_date?: string;
  end_date?: string;
  date_filed__gte?: string;
  date_filed__lte?: string;
  order_by?: string;
  state_code?: string;
  country_code?: string;
  country?: string;
  state?: string;
  state_name?: string;
  product_category__in?: string;
  source__in?: string;
  has_postal_code?: boolean;
  has_city?: boolean;
  has_county?: boolean;
  has_country?: boolean;
  has_state?: boolean;
  address_not_empty?: boolean;
  customer_id?: string;
  transaction_id?: string;
  search_query?: string;
  filing_id?: string;
  date__gte?: string;
  date__lte?: string;
  query?: string;
  /** @description Page number */
  page?: number;
  /** @description Page size */
  size?: number;
};

export type UseTableFilters = Omit<
  TableFilter,
  | 'status__in'
  | 'address_status__in'
  | 'order_by'
  | 'product_category__in'
  | 'source__in'
  | 'transaction_source'
  | 'transaction_type'
  | 'has_postal_code'
  | 'has_city'
  | 'has_county'
  | 'has_country'
  | 'has_state'
  | 'address_not_empty'
> & {
  // Arrays
  status__in?: string[];
  address_status__in?: string[];
  order_by?: string[];
  product_category__in?: string[];
  source__in?: string[];
  transaction_source?: string[];
  transaction_type?: string[];
  // Booleans
  has_postal_code?: string;
  has_city?: string;
  has_county?: string;
  has_country?: string;
  has_state?: string;
  address_not_empty?: string;
};

export type ApiResponse<T> = {
  /** Items */
  items: T[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages: Maybe<number>;
};

export enum AccountingModel {
  CASH = 'CASH',
  ACCRUAL = 'ACCRUAL',
}

export enum UserRole {
  Admin = 'Admin',
  Owner = 'Owner',
  Member = 'Member',
}

export type UserRoleUpdateRequest = {
  role: string;
  userId: string;
};

export type AutoFileAutoRegisterUpdateRequest = {
  auto_register?: boolean;
  auto_file?: boolean;
};

export type UserInviteRequest = {
  email: string;
  role: string;
  additional_roles?: string[];
};

export enum PhysicalMailStatus {
  authorize = 'authorize',
  sign = 'sign',
  verify = 'verify',
  complete = 'complete',
}

export type UnapprovedFilingMetaData = {
  count: number;
  ids: string[];
  accumulated_amount: number;
  accumulated_total_tax_liability: number;
  accumulated_amount_sales: number;
  accumulated_amount_tax_collected: number;
  accumulated_amount_calculated: number;
  accumulated_amount_adjusted: number;
  accumulated_amount_fees: number;
  accumulated_amount_penalties: number;
  accumulated_amount_discounts: number;
};

export type Address = {
  phone: Maybe<string>;
  street_1: Maybe<string>;
  street_2: Maybe<string>;
  city: Maybe<string>;
  county: Maybe<string>;
  state: Maybe<string>;
  postal_code: Maybe<string>;
  country: Maybe<CountryCodeEnum>;
};

export type AddressSuggestion = {
  id: string;
  address: Address;
  suggestions: Address[];
};

export type AddressSuggestionWithAddressInstance = Omit<AddressSuggestion, 'address'> & { address: AddressInstance };
