import { ApiResponse, ExemptionInstance, TableFilter } from 'types/shared-types';

import api from './api';

const getExemptions = (orgId: string, params?: TableFilter) => {
  return api.get<ApiResponse<ExemptionInstance>>(`/v1/exemptions`, {
    method: 'GET',
    headers: {
      'x-organization-id': orgId,
    },
    params,
  });
};

const EXEMPTIONS_STATE_KEY = 'exemptions';

export { EXEMPTIONS_STATE_KEY, getExemptions };
