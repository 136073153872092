import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Radio,
  RadioGroup,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { ADDRESS_STATE_KEY, getSuggestions } from 'apis/addressses';
import Button from 'component-library/components/ButtonTmp/button';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useState } from 'react';
import { CountryCodeEnum } from 'schema/types-schema';
import { toNormalCase } from 'utils';

export type Address = {
  city: string | undefined;
  county: string | undefined;
  state: string | undefined;
  postal_code: string | undefined;
  country?: CountryCodeEnum;
};

type AddressSuggestionPopoverProps = {
  address: Address;
  onUpdateAddress: (address: Address) => void;
};

export const AddressSuggestionPopover = ({ address, onUpdateAddress }: AddressSuggestionPopoverProps) => {
  const { handleFailNotification } = useHandleNotification();
  const { data, isPending, isRefetching } = useQuery({
    queryKey: [ADDRESS_STATE_KEY, address],
    queryFn: async () => {
      try {
        return await getSuggestions({
          city: address.city,
          state: address.state,
          postalCode: address.postal_code,
          country: address.country,
        });
      } catch (error) {
        handleFailNotification(error);
        return [];
      }
    },
    refetchOnWindowFocus: false,
  });

  const [selectedAddress, setSelectedAddress] = useState<Address>();
  const [value, setValue] = useState('1');

  const handleAddressChange = (index: string) => {
    setValue(index);
    setSelectedAddress(data[parseInt(index) - 1]);
  };

  const handleUpdateAddress = () => {
    if (data.length === 1) {
      onUpdateAddress(data[0]);
    } else if (selectedAddress) {
      onUpdateAddress(selectedAddress);
    } else {
      onUpdateAddress(data[0]);
    }
  };

  return (
    <Popover size={'sm'}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              variant={'transparent-with-icon'}
              isLoading={isPending || isRefetching}
              isDisabled={data && data.length === 0}
              loadingText="See Suggested Address"
            >
              {data && data.length === 0 ? 'No Suggested Address' : 'See Suggested Address'}
            </Button>
          </PopoverTrigger>
          <PopoverContent px={1}>
            <PopoverBody>
              <RadioGroup onChange={handleAddressChange} value={value} defaultValue="1">
                <VStack align="start" spacing={4}>
                  {data?.map((address: Address, index: string) => (
                    <Radio key={index} value={(index + 1).toString()}>{`${toNormalCase(address.city || '')},
                    ${toNormalCase(address.county || '')}, ${address.state} ${address.postal_code}, ${address.country}`}</Radio>
                  ))}
                </VStack>
              </RadioGroup>
            </PopoverBody>
            <PopoverFooter border="0" display="flex" alignItems="center" justifyContent="end" gap={4}>
              <Button width="125px" variant="outline" color="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button
                width="125px"
                variant="solid"
                color="primary"
                onClick={() => {
                  onClose();
                  handleUpdateAddress();
                }}
              >
                Update
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
