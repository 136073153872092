import { Grid, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { CONNECTIONS_STATE_KEY, getConnections } from 'apis/connections';
import { useOrg } from 'hooks/useOrg';
import { cloneElement, Fragment } from 'react';
import { ConnectionStatusEnum, SourceEnum } from 'schema/types-schema.d';
import { ApiResponse, ConnectionInstance } from 'types/shared-types';

import { integrationTabs } from './tabs';

const Integrations = () => {
  const { orgId } = useOrg();
  const { data, isPending } = useQuery<
    ApiResponse<ConnectionInstance>,
    unknown,
    Record<SourceEnum, ConnectionStatusEnum>
  >({
    queryKey: [CONNECTIONS_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getConnections(orgId);
      return data;
    },
    select: data => {
      const connectionMap = (data.items || []).reduce(
        (map, { source, status }) => {
          map[source] = status;
          return map;
        },
        {} as Record<SourceEnum, ConnectionStatusEnum>
      );

      return Object.values(SourceEnum).reduce(
        (acc, curr) => {
          acc[curr] = connectionMap[curr] || ConnectionStatusEnum.INACTIVE;
          return acc;
        },
        {} as Record<SourceEnum, ConnectionStatusEnum>
      );
    },
  });

  const tabHeaders = integrationTabs.map(tab => tab.title);

  if (isPending) {
    return <Skeleton height="full" width="full" />;
  }

  return (
    <Tabs isLazy>
      <TabList>
        {tabHeaders.map((header, i) => {
          return (
            <Tab key={i} fontSize={'sm'}>
              {header}
            </Tab>
          );
        })}
      </TabList>
      <TabPanels mt={4}>
        {integrationTabs.map(({ cards, title }) => (
          <TabPanel maxWidth={'1244px'} p={0} key={title}>
            <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }} gap={6} alignItems={'stretch'}>
              {cards.map(({ source, component }, i) => {
                const isConnected = data?.[source] === ConnectionStatusEnum.ACTIVE;
                return (
                  <Fragment key={`${title}-${i}`}>
                    {cloneElement(component, { ...component.props, isConnected })}
                  </Fragment>
                );
              })}
            </Grid>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
export default Integrations;
