import { useOrgDetailsQuery } from 'apis/organizations-apis';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';

import { BusinessAddressPrompt } from '../../update-address-prompt';
import { ItemCard } from '../item-card';
import { StripeIcon } from './icons/stripe';

const STRIPE_APP_URL = import.meta.env.VITE_STRIPE_MARKETPLACE_URL;

export default function StripeTab({ isConnected = false }: { isConnected?: boolean }) {
  const { orgId } = useOrg();

  const [isBusinessAddressPromptOpen, setIsBusinessAddressPromptOpen] = useState(false);

  const { data } = useOrgDetailsQuery(orgId);
  const isAddressMissing = data ? !data.company_country_code || !data.company_postal_code : true;
  const openInNewTab = () => {
    window.open(STRIPE_APP_URL, '_blank', 'noopener,noreferrer');
  };
  const handleConnect = () => {
    setIsBusinessAddressPromptOpen(false);
    openInNewTab();
  };

  return (
    <>
      <ItemCard
        isConnected={isConnected}
        title={'Stripe'}
        description={'  Financial Infrastructure for the Internet'}
        icon={<StripeIcon />}
        onConnect={() => (isAddressMissing ? setIsBusinessAddressPromptOpen(true) : openInNewTab())}
      />
      <BusinessAddressPrompt
        isOpen={isBusinessAddressPromptOpen}
        onClose={() => setIsBusinessAddressPromptOpen(false)}
        onSuccess={handleConnect}
      />
    </>
  );
}
