import { ApiResponse, TableFilter, TransactionInstance } from 'types/shared-types';

import api from './api';

const getTransactions = (orgId: string, params?: TableFilter) => {
  return api.get<ApiResponse<TransactionInstance>>(`/v1/transactions`, {
    method: 'GET',
    headers: {
      'x-organization-id': orgId,
    },
    params,
  });
};

const TRANSACTION_STATE_KEY = 'transactions';
export { getTransactions, TRANSACTION_STATE_KEY };
