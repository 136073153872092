import { Flex, FormControl, FormLabel, HStack, Stack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addOrUpdateBusinessAddress, ORG_DETAILS_STATE_KEY, useOrgDetailsQuery } from 'apis/organizations-apis';
import { AddressSuggestionPopover } from 'component-library/components/address-suggestion-popover';
import Button from 'component-library/components/ButtonTmp/button';
import { KInput } from 'component-library/components/Input';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Select from 'component-library/components/Select';
import Text from 'component-library/components/Text';
import FormErrorMessage from 'components/error/FormError';
import { CountryOptions, getStatesOrProvinces, supportedCountryStates } from 'constants/app-constants';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useEffect } from 'react';
import { SuggestedAddress, UpdateAddressPromptValidationSchema } from 'types/address';
import { replaceNullWithEmptyString } from 'utils';

type updateBusinessAddressPrompt = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const BusinessAddressPrompt = ({ isOpen, onClose, onSuccess }: updateBusinessAddressPrompt) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();

  const { data: orgDetails } = useOrgDetailsQuery(orgId);

  const { mutate: addBusinessAddress, isPending: isAddingBusinessAddress } = useMutation({
    mutationFn: (payload: any) => {
      return addOrUpdateBusinessAddress(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ORG_DETAILS_STATE_KEY] });
      onCloseModal();
      handleSuccessNotification('Business Address added successfully');
      onSuccess();
    },
  });

  const { values, setValues, setFieldValue, handleChange, handleSubmit, resetForm, errors, touched } = useFormik({
    initialValues: {
      business_name: '',
      company_address_1: '',
      company_address_2: '',
      company_city: '',
      company_county: '',
      company_state: '',
      company_postal_code: '',
      company_country_code: '',
    },
    validationSchema: UpdateAddressPromptValidationSchema,
    onSubmit: values => {
      addBusinessAddress({ ...values });
    },
  });

  const addressToValidate = {
    city: values.company_city,
    county: values.company_county,
    state: values.company_state,
    postal_code: values.company_postal_code,
  };

  const applySelectedAddress = (address: SuggestedAddress) => {
    setValues({
      ...values,
      company_city: address.city as string,
      company_county: address.county as string,
      company_state: address.state as string,
      company_postal_code: address.postal_code as string,
    });
  };

  const isStateSupported = supportedCountryStates.includes(values.company_country_code);

  const onCloseModal = () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (orgDetails) {
      const sanitizedValues = replaceNullWithEmptyString(orgDetails);
      setValues({
        business_name: sanitizedValues.business_name,
        company_address_1: sanitizedValues.company_address_1,
        company_address_2: sanitizedValues.company_address_2,
        company_city: sanitizedValues.company_city,
        company_county: sanitizedValues.company_county,
        company_state: sanitizedValues.company_state,
        company_postal_code: sanitizedValues.company_postal_code,
        company_country_code: sanitizedValues.company_country_code,
      });
    }
  }, [orgDetails]);

  return (
    <ModalPopup
      size={'lg'}
      isOpen={isOpen}
      onClose={onCloseModal}
      header={'Please provide business address before importing'}
      footer={
        <Flex gap={2}>
          <Button variant={'outline'} color={'secondary'} onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            variant={'solid'}
            color={'primary'}
            width={'90px'}
            onClick={() => handleSubmit()}
            isLoading={isAddingBusinessAddress}
          >
            Submit
          </Button>
        </Flex>
      }
    >
      <Stack>
        <Text>
          In order to calculate sales tax rates for origin and destination based jurisdictions we need to know your
          company address
        </Text>
        <FormControl isRequired>
          <FormLabel htmlFor="business_name">Legal Business Name</FormLabel>
          <KInput
            id="business_name"
            type="business_name"
            name="business_name"
            value={values.business_name}
            onChange={handleChange}
          />
          {errors.business_name && touched.business_name && <FormErrorMessage message={errors.business_name} />}
        </FormControl>
        <HStack>
          <FormControl isRequired>
            <FormLabel htmlFor="company_country_code">Country</FormLabel>
            <Select
              name="company_country_code"
              placeholder="Select Country"
              options={CountryOptions}
              onChange={e => setFieldValue('company_country_code', e.target.value)}
              value={values.company_country_code}
            />
            {errors.company_country_code && touched.company_country_code && (
              <FormErrorMessage message={errors.company_country_code} />
            )}
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="company_state">State</FormLabel>
            {isStateSupported ? (
              <Select
                name="company_state"
                placeholder="Select State"
                options={getStatesOrProvinces(values.company_country_code)}
                onChange={handleChange}
                value={values.company_state}
                isDisabled={!supportedCountryStates.includes(values.company_country_code)}
              />
            ) : (
              <KInput
                id="company_state"
                type="company_state"
                name="company_state"
                value={values.company_state}
                onChange={handleChange}
              />
            )}
            {errors.company_state && touched.company_state && <FormErrorMessage message={errors.company_state} />}
          </FormControl>
        </HStack>
        <HStack>
          <FormControl isRequired>
            <FormLabel htmlFor="company_city">City</FormLabel>
            <KInput
              id="company_city"
              type="company_city"
              name="company_city"
              value={values.company_city}
              onChange={handleChange}
            />
            {errors.company_city && touched.company_city && <FormErrorMessage message={errors.company_city} />}
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="company_postal_code">Postal Code</FormLabel>
            <KInput
              id="company_postal_code"
              type="company_postal_code"
              name="company_postal_code"
              value={values.company_postal_code}
              onChange={handleChange}
            />
            {errors.company_postal_code && touched.company_postal_code && (
              <FormErrorMessage message={errors.company_postal_code} />
            )}
          </FormControl>
        </HStack>
        <HStack>
          <FormControl isRequired>
            <FormLabel htmlFor="company_address_1">Address Line 1</FormLabel>
            <KInput
              id="company_address_1"
              type="company_address_1"
              name="company_address_1"
              value={values.company_address_1}
              onChange={handleChange}
            />
            {errors.company_address_1 && touched.company_address_1 && (
              <FormErrorMessage message={errors.company_address_1} />
            )}
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="company_address_1">Address Line 2</FormLabel>
            <KInput
              id="company_address_2"
              type="company_address_2"
              name="company_address_2"
              value={values.company_address_2}
              onChange={handleChange}
            />
          </FormControl>
        </HStack>
        <Flex justify={'flex-start'}>
          {isOpen && values && (
            <AddressSuggestionPopover address={addressToValidate} onUpdateAddress={applySelectedAddress} />
          )}
        </Flex>
      </Stack>
    </ModalPopup>
  );
};
