import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ORG_DETAILS_STATE_KEY, putAutoFileAutoRegister, useAutoFileAutoRegisterQuery } from 'apis/organizations-apis';
import { usePaywall } from 'app/acl/paywall';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Text from 'component-library/components/Text';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useQueryParam from 'hooks/useQueryParam';
import { useEffect } from 'react';
import { AutoFileAutoRegisterUpdateRequest } from 'types/shared-types';

import { AutoFileRegisterToggle } from './auto-file-register-toggle';

export const AutoFileRegisterModal = () => {
  const { orgId } = useOrg();
  const { isPaidUser } = usePaywall();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { handleFailNotification } = useHandleNotification();
  const [showAutoFileReg, setSearchParams] = useQueryParam('showAutoFileReg');
  const queryClient = useQueryClient();
  const { isPending, data: orgDetails, isError } = useAutoFileAutoRegisterQuery(orgId);

  const mutation = useMutation({
    mutationFn: (payload: AutoFileAutoRegisterUpdateRequest) => {
      return putAutoFileAutoRegister(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ORG_DETAILS_STATE_KEY] });
      onClose();
    },
    onError: (error: any) => {
      handleFailNotification(error);
    },
  });

  const formik = useFormik({
    initialValues: {
      auto_file: false,
      auto_register: false,
    },
    onSubmit: values => {
      mutation.mutate(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (orgDetails) {
      formik.setValues({
        auto_file: orgDetails.auto_file,
        auto_register: orgDetails.auto_register,
      });
    }
  }, [orgDetails]);

  const handleSubmit = () => {
    formik.handleSubmit();
    onClose();
  };

  useEffect(() => {
    if (showAutoFileReg && isPaidUser) {
      onOpen();
    }
  }, [showAutoFileReg]);

  const handleClose = () => {
    setSearchParams({});
    onClose();
  };

  if (isPending || isError) return null;

  return (
    <ModalPopup
      isOpen={isOpen}
      onClose={handleClose}
      header={'Turn ON Auto Features'}
      size={'lg'}
      footer={
        <Flex gap={2}>
          <Button variant={'outline'} color={'gray.800'} borderColor={'gray.100'} onClick={handleClose} width={'70px'}>
            Cancel
          </Button>
          <Button
            variant={'solid'}
            width={'90px'}
            onClick={handleSubmit}
            isDisabled={!formik.values.auto_file && !formik.values.auto_register}
          >
            Save
          </Button>
        </Flex>
      }
    >
      <Text>
        Turn ON auto features so that you never miss a due date. You can toggle OFF from &apos;Settings&apos; anytime.
      </Text>
      <form onSubmit={formik.handleSubmit}>
        <AutoFileRegisterToggle
          label="Auto File"
          fieldName="auto_file"
          subtitle="Automatically approve all the filings on the 10th of the month. You can still approve manually before the
            filing is auto-approved."
          formik={formik}
        />
        <AutoFileRegisterToggle
          label="Auto Register"
          subtitle="Automatically approve registrations as soon as the nexus is met."
          fieldName="auto_register"
          formik={formik}
        />
      </form>
    </ModalPopup>
  );
};
