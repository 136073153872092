import { PhysicalNexusCategoryEnum } from 'types/nexus';
import { AccountingModelEnum } from 'types/shared-types';

export interface paths {
  '/health': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /**
     * Health Check
     * @description Health check
     */
    get: operations['health_check_health_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/integration': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /**
     * Integration Test
     * @description Health check
     */
    get: operations['integration_test_integration_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/address_validation/search_fields': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Proxy Address Validation Search */
    post: operations['proxy_address_validation_search_v1_address_validation_search_fields_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/address_validation/suggestions': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Proxy Address Validation Suggestions */
    post: operations['proxy_address_validation_suggestions_v1_address_validation_suggestions_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/address_validation/bulk_suggestions': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Proxy Address Validation Bulk Suggestions */
    post: operations['proxy_address_validation_bulk_suggestions_v1_address_validation_bulk_suggestions_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/address_validation/run_address_batch': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Run Address Batch */
    post: operations['run_address_batch_v1_address_validation_run_address_batch_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/attachments': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Attachments */
    get: operations['get_attachments_v1_attachments_get'];
    put?: never;
    /** Upload Attachment */
    post: operations['upload_attachment_v1_attachments_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/attachments/{attachment_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Attachment By Id */
    get: operations['get_attachment_by_id_v1_attachments__attachment_id__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/attachments/{attachment_id}/download': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Download Attachment */
    get: operations['download_attachment_v1_attachments__attachment_id__download_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/internal/batches/run': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Run All Batches */
    post: operations['run_all_batches_v1_internal_batches_run_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/internal/batches/run/{organization_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Run Organization Batch */
    post: operations['run_organization_batch_v1_internal_batches_run__organization_id__post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/internal/batches/run/{organization_id}/{batch_stage}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Run Batch Stage For Org */
    post: operations['run_batch_stage_for_org_v1_internal_batches_run__organization_id___batch_stage__post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/connections': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Connections */
    get: operations['get_connections_v1_connections_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/connections/{connection_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Connection By Id */
    get: operations['get_connection_by_id_v1_connections__connection_id__get'];
    /** Update Connection */
    put: operations['update_connection_v1_connections__connection_id__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/connections/shopify/{shop_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Create Or Update Shopify Connection */
    put: operations['create_or_update_shopify_connection_v1_connections_shopify__shop_id__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/connections/shopify_oauth/{shop_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Create Or Update Shopify Connection From Oauth */
    put: operations['create_or_update_shopify_connection_from_oauth_v1_connections_shopify_oauth__shop_id__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/connections/chargebee/{site_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Create Or Update Chargebee Connection */
    put: operations['create_or_update_chargebee_connection_v1_connections_chargebee__site_id__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/connections/stripe/{publishable_key}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Create Or Update Stripe Connection */
    put: operations['create_or_update_stripe_connection_v1_connections_stripe__publishable_key__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/connections/{connection_id}/sync': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Sync Connection */
    post: operations['sync_connection_v1_connections__connection_id__sync_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/connections/{connection_id}/status': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /**
     * Update Connection Status
     * @description This endpoint will perform the connection's status field update only
     */
    put: operations['update_connection_status_v1_connections__connection_id__status_put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/connections/apideck/{service_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /**
     * Create Or Update Apideck Connection
     * @description This endpoint will create a session from Apideck's Vault API using the
     *     organization's id and service_id (e.g. quickbooks)
     *     If there is no existing organization's id from Apideck's side, Apideck will create
     *     a new consumer based on our organization's id
     */
    put: operations['create_apideck_connection_v1_connections_apideck__service_id__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/connections/{connection_id}/reset': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Reset Connection */
    post: operations['reset_connection_v1_connections__connection_id__reset_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/customers': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Customers */
    get: operations['get_customers_v1_customers_get'];
    put?: never;
    /** Create Customer */
    post: operations['create_customer_v1_customers_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/customers/{customer_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Customer By Id */
    get: operations['get_customer_by_id_v1_customers__customer_id__get'];
    /** Update Customer */
    put: operations['update_customer_v1_customers__customer_id__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/customers/external/{external_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Customer By External Id */
    get: operations['get_customer_by_external_id_v1_customers_external__external_id__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/internal/email/send': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Send Internal Email */
    post: operations['send_internal_email_v1_internal_email_send_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/email/send_registration_password': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Send Password */
    post: operations['send_password_v1_email_send_registration_password_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/exemptions': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Exemptions */
    get: operations['get_exemptions_v1_exemptions_get'];
    put?: never;
    /** Create Exemption */
    post: operations['create_exemption_v1_exemptions_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/exemptions/{exemption_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Exemption By Id */
    get: operations['get_exemption_by_id_v1_exemptions__exemption_id__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/exemptions/{exemption_id}/attachments': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Attachments For Exemption */
    get: operations['get_attachments_for_exemption_v1_exemptions__exemption_id__attachments_get'];
    put?: never;
    /** Upload Exemption Certificate */
    post: operations['upload_exemption_certificate_v1_exemptions__exemption_id__attachments_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/dashboard/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Dashboard Data Route */
    get: operations['get_dashboard_data_route_v1_dashboard__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/onboarding': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Update Onboarding Status Route */
    put: operations['update_onboarding_status_route_v1_onboarding_put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/dashboard/details/aggregations={aggregation_type}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /**
     * Get Dashboard Details
     * @description Implementing new endpoint
     */
    get: operations['get_dashboard_details_v1_dashboard_details_aggregations__aggregation_type__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/onboarding/steps-status': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Onboarding Steps Status */
    get: operations['get_onboarding_steps_status_v1_onboarding_steps_status_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/filings': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Filings */
    get: operations['get_filings_v1_filings_get'];
    put?: never;
    /** Create Filing */
    post: operations['create_filing_v1_filings_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/filings/{filing_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Filing By Id */
    get: operations['get_filing_by_id_v1_filings__filing_id__get'];
    /** Update Filing */
    put: operations['update_filing_v1_filings__filing_id__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/internal/filings/{filing_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Update Filing Internal */
    put: operations['update_filing_internal_v1_internal_filings__filing_id__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/filings/registration/{registration_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Filings By Registration Id */
    get: operations['get_filings_by_registration_id_v1_filings_registration__registration_id__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/filings/reports/export': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Export Filings
     * @description Export the filings report for the organization, and send an email to the user
     *     with the report
     */
    post: operations['export_filings_v1_filings_reports_export_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/filings/{filing_id}/reports/export': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Get Filing Details Report By Id
     * @description Export the filings report for the organization, and send an email to the user
     *     with the report
     */
    post: operations['get_filing_details_report_by_id_v1_filings__filing_id__reports_export_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/filings/{filing_id}/attachments': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Upload Filing Attachment */
    post: operations['upload_filing_attachment_v1_filings__filing_id__attachments_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/import/validate': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Validate Import Data
     * @description Validate import data and return _our_ transformed version
     */
    post: operations['validate_import_data_v1_import_validate_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/import/upload-urls': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Import Upload Urls
     * @description Return URLs for uploading import data
     */
    post: operations['import_upload_urls_v1_import_upload_urls_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/import/internal-upload': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Internal-Import */
    post: operations['internal_import_v1_import_internal_upload_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/import/internal-manual-upload': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Manual-Internal-Import
     * @description Submit through the front end to Jira.
     */
    post: operations['manual_internal_import_v1_import_internal_manual_upload_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/webhooks/jira': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Jira Webhook */
    post: operations['jira_webhook_v1_webhooks_jira_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/nexus': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Nexus For Org */
    get: operations['get_nexus_for_org_v1_nexus__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/nexus/physical_nexus': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Physical Nexus */
    get: operations['get_physical_nexus_v1_nexus_physical_nexus_get'];
    put?: never;
    /** Create Physical Nexus */
    post: operations['create_physical_nexus_v1_nexus_physical_nexus_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/nexus/physical_nexus/{physical_nexus_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Update Physical Nexus */
    put: operations['update_physical_nexus_v1_nexus_physical_nexus__physical_nexus_id__put'];
    post?: never;
    /** Delete Physical Nexus */
    delete: operations['delete_physical_nexus_v1_nexus_physical_nexus__physical_nexus_id__delete'];
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/nexus/{country_code}/{state_code}/refresh': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Refresh Nexus By Country State */
    post: operations['refresh_nexus_by_country_state_v1_nexus__country_code___state_code__refresh_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/nexus/refresh': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Refresh Nexus */
    post: operations['refresh_nexus_v1_nexus_refresh_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/internal/nexus/refresh_all_organizations': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Refresh All Nexuses Belonging To All Organizations */
    post: operations['refresh_all_nexuses_belonging_to_all_organizations_v1_internal_nexus_refresh_all_organizations_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/nexus/export': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /**
     * Get Nexus Report
     * @description Export the nexus report for the organization, and send an email to the user
     *     with the report
     */
    get: operations['get_nexus_report_v1_nexus_export_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/internal/organizations/{organization_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Organization By Id */
    get: operations['get_organization_by_id_v1_internal_organizations__organization_id__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/internal/organizations/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Organizations */
    get: operations['get_organizations_v1_internal_organizations__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/organizations/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Organizations By User */
    get: operations['get_organizations_by_user_v1_organizations__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/organizations/{organization_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Update Organization */
    put: operations['update_organization_v1_organizations__organization_id__put'];
    post?: never;
    /** Delete Organization */
    delete: operations['delete_organization_v1_organizations__organization_id__delete'];
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/webhooks/auth/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Auth Webhook */
    post: operations['auth_webhook_v1_webhooks_auth__post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/users/me': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Current User */
    get: operations['get_current_user_v1_users_me_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/users/signup_query_params': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get User Signup Query Params */
    get: operations['get_user_signup_query_params_v1_users_signup_query_params_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/bank_details/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Bank Details */
    get: operations['get_bank_details_v1_bank_details__get'];
    /** Create Update Bank Details */
    put: operations['create_update_bank_details_v1_bank_details__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/organization_details/business_details/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Create Update Business Details */
    put: operations['create_update_business_details_v1_organization_details_business_details__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/organization_details/business_address/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Create Update Business Address */
    put: operations['create_update_business_address_v1_organization_details_business_address__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/organization_details/business_information/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Create Update Business Information */
    put: operations['create_update_business_information_v1_organization_details_business_information__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/organization_details/business_contact/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Create Update Business Contact */
    put: operations['create_update_business_contact_v1_organization_details_business_contact__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/organization_details/business_owners/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Create Update Business Owners */
    put: operations['create_update_business_owners_v1_organization_details_business_owners__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/organization_details': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Organization Details */
    get: operations['get_organization_details_v1_organization_details__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/products/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Products */
    get: operations['get_products_v1_products__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/products/{product_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Product By Id */
    get: operations['get_product_by_id_v1_products__product_id__get'];
    /** Update Product */
    put: operations['update_product_v1_products__product_id__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/products/categories': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Product Categories */
    get: operations['get_product_categories_v1_products_categories__get'];
    /** Create Update Product Categories */
    put: operations['create_update_product_categories_v1_products_categories__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/products/bulk_approve/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Bulk Approve */
    put: operations['bulk_approve_v1_products_bulk_approve__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/products/bulk_classify/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    /** Bulk Classify */
    put?: never;
    post: operations['bulk_classify_v1_products_bulk_classify__post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/products/config/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Product Config */
    get: operations['get_product_config_v1_products_config__get'];
    /** Create Update Product Config */
    put: operations['create_update_product_config_v1_products_config__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/products/classification_status/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Product Classification Status */
    get: operations['get_product_classification_status_v1_products_classification_status__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/registrations': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Registrations */
    get: operations['get_registrations_v1_registrations_get'];
    put?: never;
    /** Create Registration */
    post: operations['create_registration_v1_registrations_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/registrations/{registration_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Registration By Id */
    get: operations['get_registration_by_id_v1_registrations__registration_id__get'];
    /** Update Registration */
    put: operations['update_registration_v1_registrations__registration_id__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/registrations/{registration_id}/attachments': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Upload Registration Attachment */
    post: operations['upload_registration_attachment_v1_registrations__registration_id__attachments_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/rules/': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Rules */
    get: operations['get_rules_v1_rules__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/rules/{rule_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Rule By Id */
    get: operations['get_rule_by_id_v1_rules__rule_id__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/customers/{customer_id}/transactions': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Transactions By Customer Id */
    get: operations['get_transactions_by_customer_id_v1_customers__customer_id__transactions_get'];
    put?: never;
    /** Create Transaction By Customer Id */
    post: operations['create_transaction_by_customer_id_v1_customers__customer_id__transactions_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/transactions/addresses': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Addresses */
    get: operations['get_addresses_v1_transactions_addresses_get'];
    /** Update Addresses */
    put: operations['update_addresses_v1_transactions_addresses_put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/transactions/{transaction_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Transaction By Id */
    get: operations['get_transaction_by_id_v1_transactions__transaction_id__get'];
    /** Update Transaction */
    put: operations['update_transaction_v1_transactions__transaction_id__put'];
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/transactions/{transaction_id}/related_transactions': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Related Transactions */
    get: operations['get_related_transactions_v1_transactions__transaction_id__related_transactions_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/transactions/external/{external_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Transaction By External Id */
    get: operations['get_transaction_by_external_id_v1_transactions_external__external_id__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/transactions': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Transactions */
    get: operations['get_transactions_v1_transactions_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/transactions/import': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Import Transactions */
    post: operations['import_transactions_v1_transactions_import_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/transactions/filings/{filing_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Transactions By Filing Id */
    get: operations['get_transactions_by_filing_id_v1_transactions_filings__filing_id__get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/transactions/export/csv': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Export Transactions To Csv */
    get: operations['export_transactions_to_csv_v1_transactions_export_csv_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/transactions/reports/details': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Export Transactions Details Report */
    post: operations['export_transactions_details_report_v1_transactions_reports_details_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/transactions/reports/summary': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Export Transactions Summary Report */
    post: operations['export_transactions_summary_report_v1_transactions_reports_summary_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/tax/estimate': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Estimate Tax */
    post: operations['estimate_tax_v1_tax_estimate_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/taxgpt': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Proxy Taxbot */
    get: operations['proxy_taxbot_v1_taxgpt_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/billing': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Organization Billing Info */
    get: operations['get_organization_billing_info_v1_billing_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/billing_plan': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Organization Billing Plan */
    get: operations['get_organization_billing_plan_v1_billing_plan_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/billing/invoices': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    /** Get Invoices From Chargebee */
    get: operations['get_invoices_from_chargebee_v1_billing_invoices_get'];
    put?: never;
    post?: never;
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/billing/checkout_new_url': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Create Checkout New Url */
    post: operations['create_checkout_new_url_v1_billing_checkout_new_url_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/billing/generate_portal_session': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Generate Portal Session */
    post: operations['generate_portal_session_v1_billing_generate_portal_session_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/billing/chargebee_checkout': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Listen Chargebee Checkout Webhook
     * @description Listen to checkout event from chargebee
     */
    post: operations['listen_chargebee_checkout_webhook_v1_billing_chargebee_checkout_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/billing/update_billing_plan': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /** Update Billing Plan */
    post: operations['update_billing_plan_v1_billing_update_billing_plan_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/v1/webhooks/chargebee/{organization_id}': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Handle Chargebee Webhook
     * @description Handles Chargebee webhooks
     */
    post: operations['handle_chargebee_webhook_v1_webhooks_chargebee__organization_id__post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/chargebee/address/validate': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Validate Address
     * @description Validates address using the address validation service and return response specific
     *     to Chargebee
     */
    post: operations['validate_address_chargebee_address_validate_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/chargebee/address/check-taxability': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Check Address Taxability
     * @description Check address taxability using the address validation service and return response
     *     specific to Chargebee
     */
    post: operations['check_address_taxability_chargebee_address_check_taxability_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/chargebee/tax-estimate': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Estimate Tax
     * @description This endpoint is used to estimate taxes for a set of line items being sold
     *     by the Merchant to a Customer.
     */
    post: operations['estimate_tax_chargebee_tax_estimate_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/chargebee/invoices': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Create Invoice
     * @description Creates Chargebee invoice
     */
    post: operations['create_invoice_chargebee_invoices_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/chargebee/invoices/{invoice_id}/commit': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Commit Invoice
     * @description Commits Chargebee invoice
     */
    post: operations['commit_invoice_chargebee_invoices__invoice_id__commit_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/chargebee/invoices/{invoice_id}/void': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Void Invoice
     * @description Voids Chargebee invoice
     */
    post: operations['void_invoice_chargebee_invoices__invoice_id__void_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/chargebee/credit-notes': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Create Credit Note
     * @description Creates Chargebee credit note
     */
    post: operations['create_credit_note_chargebee_credit_notes_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/chargebee/credit-notes/{credit_note_id}/commit': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Commit Credit Note
     * @description Commits Chargebee credit note
     */
    post: operations['commit_credit_note_chargebee_credit_notes__credit_note_id__commit_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/chargebee/credit-notes/{credit_note_id}/void': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Void Credit Note
     * @description Voids Chargebee credit note
     */
    post: operations['void_credit_note_chargebee_credit_notes__credit_note_id__void_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
  '/chargebee/credentials/validate': {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    get?: never;
    put?: never;
    /**
     * Validate Credentials
     * @description Validates credentials and return response specific to Chargebee
     */
    post: operations['validate_credentials_chargebee_credentials_validate_post'];
    delete?: never;
    options?: never;
    head?: never;
    patch?: never;
    trace?: never;
  };
}
export type webhooks = Record<string, never>;
export interface components {
  schemas: {
    /** Address */
    'Address-Input': {
      /** Id */
      id?: string;
      /**
       * Created At
       * Format: date-time
       */
      created_at?: string;
      /** Updated At */
      updated_at?: string | null;
      /** Phone */
      phone?: string | null;
      /** Street 1 */
      street_1?: string | null;
      /** Street 2 */
      street_2?: string | null;
      /** City */
      city?: string | null;
      /** County */
      county?: string | null;
      /** State */
      state?: string | null;
      /** Postal Code */
      postal_code?: string | null;
      country?: components['schemas']['CountryCodeEnum'] | null;
      type: components['schemas']['AddressType'];
      /** @default UNVERIFIED */
      status: components['schemas']['AddressStatus'];
      /** Organization Id */
      organization_id?: string | null;
      /** Transaction Id */
      transaction_id?: string | null;
    };
    /** Address */
    'Address-Output': {
      /** First Name */
      first_name?: string | null;
      /** Last Name */
      last_name?: string | null;
      /** Line1 */
      line1?: string | null;
      /** Line2 */
      line2?: string | null;
      /** City */
      city?: string | null;
      /** State Code */
      state_code?: string | null;
      /** State */
      state?: string | null;
      /** Country */
      country?: string | null;
      /** Zip */
      zip?: string | null;
      /** Validation Status */
      validation_status: string;
      /** Object */
      object: string;
    };
    /**
     * AddressStatus
     * @enum {string}
     */
    AddressStatus: AddressStatus;
    /**
     * AddressType
     * @enum {string}
     */
    AddressType: AddressType;
    /** Attachment */
    Attachment: {
      /** Id */
      id?: string;
      /**
       * Created At
       * Format: date-time
       */
      created_at?: string;
      /** Updated At */
      updated_at?: string | null;
      /** Related Entity Id */
      related_entity_id: string;
      related_entity_type: components['schemas']['RelatedEntityType'];
      /** File Name */
      file_name: string;
      /** Mime Type */
      mime_type: string;
      /** File Size Bytes */
      file_size_bytes: number;
      /** File Location */
      file_location: string;
      file_location_type: components['schemas']['AttachmentLocationType'];
    };
    /**
     * AttachmentLocationType
     * @constant
     * @enum {string}
     */
    AttachmentLocationType: 'S3';
    /** AttachmentRead */
    AttachmentRead: {
      /** Related Entity Id */
      related_entity_id: string;
      related_entity_type: components['schemas']['RelatedEntityType'];
      /** Id */
      id: string;
    };
    /**
     * AccountingModelEnum
     * @enum {string}
     */
    AccountingModelEnum: AccountingModelEnum;
    /**
     * BankAccountTypeEnum
     * @enum {string}
     */
    BankAccountTypeEnum: BankAccountTypeEnum;
    /** BankDetailsCreateUpdate */
    BankDetailsCreateUpdate: {
      /** Bank Name */
      bank_name: string | null;
      /** Account Number */
      account_number: string | null;
      account_type: components['schemas']['BankAccountTypeEnum'] | null;
      /** Account Holder Name */
      account_holder_name: string | null;
      /** Routing Number */
      routing_number: string | null;
    };
    /** BankDetailsRead */
    BankDetailsRead: {
      /** Bank Name */
      bank_name: string | null;
      /** Account Number */
      account_number: string | null;
      account_type: components['schemas']['BankAccountTypeEnum'] | null;
      /** Account Holder Name */
      account_holder_name: string | null;
      /** Routing Number */
      routing_number: string | null;
      /** Id */
      id: string;
    };
    /** BatchMessage */
    BatchMessage: {
      /** Message */
      message: string;
    };
    /**
     * BatchStages
     * @description The current stages of the main batch run - in order
     * @enum {string}
     */
    BatchStages: BatchStages;
    /**
     * BillingPlanEnum
     * @enum {string}
     */
    BillingPlanEnum: BillingPlanEnum;
    /** Body_internal_import_v1_import_internal_upload_post */
    Body_internal_import_v1_import_internal_upload_post: {
      /**
       * File
       * Format: binary
       */
      file: string;
    };
    /** Body_manual_internal_import_v1_import_internal_manual_upload_post */
    Body_manual_internal_import_v1_import_internal_manual_upload_post: {
      /**
       * File
       * Format: binary
       */
      file: string;
    };
    /** Body_upload_attachment_v1_attachments_post */
    Body_upload_attachment_v1_attachments_post: {
      /** Related Entity Id */
      related_entity_id: string;
      /** Related Entity Type */
      related_entity_type: string;
      /**
       * File
       * Format: binary
       */
      file: string;
    };
    /** Body_upload_exemption_certificate_v1_exemptions__exemption_id__attachments_post */
    Body_upload_exemption_certificate_v1_exemptions__exemption_id__attachments_post: {
      /**
       * File
       * Format: binary
       */
      file: string;
    };
    /** Body_upload_filing_attachment_v1_filings__filing_id__attachments_post */
    Body_upload_filing_attachment_v1_filings__filing_id__attachments_post: {
      /**
       * File
       * Format: binary
       */
      file: string;
    };
    /** Body_upload_registration_attachment_v1_registrations__registration_id__attachments_post */
    Body_upload_registration_attachment_v1_registrations__registration_id__attachments_post: {
      /**
       * File
       * Format: binary
       */
      file: string;
    };
    /** BulkClassifyUpdate */
    BulkClassifyUpdate: {
      /** Product Ids */
      product_ids: string[];
    };
    /** Card */
    Card: {
      /** Status */
      status?: string | null;
      /** Gateway */
      gateway?: string | null;
      /** Gateway Account Id */
      gateway_account_id?: string | null;
      /** Iin */
      iin: string | number;
      /** Last4 */
      last4: string | number;
      /** Card Type */
      card_type?: string | null;
      /** Funding Type */
      funding_type: string;
      /** Expiry Month */
      expiry_month: number;
      /** Expiry Year */
      expiry_year: number;
      /** Created At */
      created_at?: number | null;
      /** Updated At */
      updated_at?: number | null;
      /** Ip Address */
      ip_address?: string | null;
      /** Resource Version */
      resource_version?: number | null;
      /** Object */
      object: string;
      /** Masked Number */
      masked_number: string;
      /** Customer Id */
      customer_id?: string | null;
      /** Payment Source Id */
      payment_source_id?: string | null;
      /** Brand */
      brand?: string | null;
    };
    /** ChargebeeAddressTaxabilityResponse */
    ChargebeeAddressTaxabilityResponse: {
      /** Istaxable */
      isTaxable: boolean;
    };
    /** ChargebeeAddressValidation */
    ChargebeeAddressValidation: {
      /** Line1 */
      line1?: string | null;
      /** Line2 */
      line2?: string | null;
      /** Line3 */
      line3?: string | null;
      /** City */
      city?: string | null;
      /** State */
      state?: string | null;
      /**
       * Country
       * @description `country` should be in ISO 3166-1 alpha-2 format, e.g. US, CA and should not be empty. Not validating here as the validation structure can be different for different providers
       * @default US
       */
      country: string | null;
      /**
       * Postalcode
       * @description `postal_code` can be empty for some locales. Not validating here as the validation structure can be different for different providers
       * @default
       */
      postalCode: string | null;
    };
    /** ChargebeeAddressValidationRequest */
    ChargebeeAddressValidationRequest: {
      address?: components['schemas']['ChargebeeAddressValidation'] | null;
    };
    /** ChargebeeAddressValidationResponse */
    ChargebeeAddressValidationResponse: {
      status: components['schemas']['ChargebeeStatusEnum'];
    };
    /** ChargebeeCreateUpdate */
    ChargebeeCreateUpdate: {
      /** Url */
      url: string;
      /** Api Key */
      api_key: string;
    };
    /** ChargebeeCredentialValidationResponse */
    ChargebeeCredentialValidationResponse: {
      status: components['schemas']['ChargebeeStatusEnum'];
    };
    /**
     * ChargebeeCustomer
     * @description The details of the Customer
     */
    ChargebeeCustomer: {
      /**
       * Name
       * @description The name of the Customer in Chargebee.
       */
      name?: string | null;
      /**
       * Customercode
       * @description The unique identifier for the Customer in Chargebee.
       */
      customerCode: string;
      /** @description Represents the address used for validation. */
      address: components['schemas']['ValidationAddressBase'];
      /**
       * Taxregistrationnumber
       * @description The tax registration number of a business in a country. For example, this is the GSTIN for India or the VAT number for EU or Australia.
       */
      taxRegistrationNumber?: string | null;
      /**
       * Taxidentifiers
       * @description It represents the information related to the customer's tax identifiers. This includes details such as exemption status etc.
       */
      taxIdentifiers?: components['schemas']['TaxIdentifier'][] | null;
    };
    /**
     * ChargebeeInvoiceRequest
     * @description Chargebee invoice request model
     */
    ChargebeeInvoiceRequest: {
      /**
       * Invoicecode
       * @description The unique identifier of the invoice in Chargebee.
       */
      invoiceCode: string;
      /**
       * Documentdatetime
       * Format: date-time
       * @description The date and time at which the invoice was generated in Chargebee
       */
      documentDateTime: string;
      /**
       * Currency
       * @description The currency in the ISO-4217 format.
       */
      currency: string;
      /** @description The details of the seller involved in the transactionincluding company code and address. */
      seller: components['schemas']['Seller'];
      /** @description The details of the Customer. */
      customer: components['schemas']['ChargebeeCustomer'];
      /**
       * Subtotal
       * @description The amount after discounts. This is the sum of all lineItems.subtotal
       */
      subtotal: number;
      /**
       * Exemptamount
       * @description The part of the subtotal that is exempted from tax.
       */
      exemptAmount: number;
      /**
       * Discountamount
       * @description The total discount applied. This is the sum of all lineItems.discount
       */
      discountAmount: number;
      /**
       * Taxableamount
       * @description The part of the subtotal that is taxable.
       */
      taxableAmount: number;
      /**
       * Taxamount
       * @description The total tax payable. This is the sum of all lineItems.taxAmount
       */
      taxAmount: number;
      /**
       * Total
       * @description The total after discounts and taxes. This is the same as subtotal if it is tax inclusive; otherwise it is subtotal + taxAmount. total can also be expressed as exemptAmount + taxableAmount + taxAmount.
       */
      total: number;
      /**
       * Lineitems
       * @description The details of a line item.
       */
      lineItems: components['schemas']['LineItem-Input'][];
    };
    /**
     * ChargebeeInvoiceResponse
     * @description Chargebee invoice response model
     */
    ChargebeeInvoiceResponse: {
      /**
       * Invoicecode
       * @description The unique identifier of the invoice in Chargebee.
       */
      invoiceCode: string;
      /**
       * Documentdatetime
       * Format: date-time
       * @description The date and time at which the invoice was generated in Chargebee
       */
      documentDateTime: string;
      /**
       * Currency
       * @description The currency in the ISO-4217 format.
       */
      currency: string;
      /** @description The details of the seller involved in the transactionincluding company code and address. */
      seller: components['schemas']['Seller'];
      /** @description The details of the Customer. */
      customer: components['schemas']['ChargebeeCustomer'];
      /**
       * Subtotal
       * @description The amount after discounts. This is the sum of all lineItems.subtotal
       */
      subtotal: number;
      /**
       * Exemptamount
       * @description The part of the subtotal that is exempted from tax.
       */
      exemptAmount: number;
      /**
       * Discountamount
       * @description The total discount applied. This is the sum of all lineItems.discount
       */
      discountAmount: number;
      /**
       * Taxableamount
       * @description The part of the subtotal that is taxable.
       */
      taxableAmount: number;
      /**
       * Taxamount
       * @description The total tax payable. This is the sum of all lineItems.taxAmount
       */
      taxAmount: number;
      /**
       * Total
       * @description The total after discounts and taxes. This is the same as subtotal if it is tax inclusive; otherwise it is subtotal + taxAmount. total can also be expressed as exemptAmount + taxableAmount + taxAmount.
       */
      total: number;
      /**
       * Lineitems
       * @description The details of a line item.
       */
      lineItems: components['schemas']['backend__src__chargebee_adapter__models__invoice__LineItem-Output'][];
      /**
       * Invoiceid
       * @description The unique identifier of the invoice in the Tax Service Adapteror the Tax Service Provider.
       */
      invoiceId: string;
      /** @description Status of the invoice document. */
      status: components['schemas']['DocumentStatus'];
    };
    /**
     * ChargebeeRequestValidationEnum
     * @enum {string}
     */
    ChargebeeRequestValidationEnum: ChargebeeRequestValidationEnum;
    /**
     * ChargebeeStatusEnum
     * @enum {string}
     */
    ChargebeeStatusEnum: ChargebeeStatusEnum;
    /**
     * ChargebeeTaxEstimateRequest
     * @description Chargebee tax estimate model
     */
    ChargebeeTaxEstimateRequest: {
      /** @description The details of the seller involved in the transactionincluding company code and address. */
      seller: components['schemas']['Seller'];
      /** @description The details of the Customer. */
      customer: components['schemas']['ChargebeeCustomer'];
      /**
       * Estimatedatetime
       * Format: date-time
       * @description The time as of which the tax estimation is to be calculated. This can be a value in the past.
       */
      estimateDateTime: string;
      /**
       * Currency
       * @description The currency in the ISO-4217 format.
       */
      currency: string;
      /**
       * Lineitems
       * @description Contains the details of each line item in the tax estimation request.
       */
      lineItems: components['schemas']['TaxEstimateLineItemBase'][];
    };
    /**
     * ChargebeeTaxEstimationResponse
     * @description The response sent by the Tax Service Adapter to Chargebee
     *     for a tax estimation request.
     */
    ChargebeeTaxEstimationResponse: {
      /** @description The details of the seller involved in the transactionincluding company code and address. */
      seller: components['schemas']['Seller'];
      /** @description The details of the Customer. */
      customer: components['schemas']['ChargebeeCustomer'];
      /**
       * Estimatedatetime
       * Format: date-time
       * @description The time as of which the tax estimation is to be calculated. This can be a value in the past.
       */
      estimateDateTime: string;
      /**
       * Currency
       * @description The currency in the ISO-4217 format.
       */
      currency: string;
      /**
       * Subtotal
       * @description The amount after discounts. This is the sum of all lineItems.subtotal.
       */
      subtotal: number;
      /**
       * Exemptamount
       * @description The part of the subtotal that is exempted from tax.
       */
      exemptAmount: number;
      /**
       * Discountamount
       * @description The total discount applied. This is the sum of all lineItems.discount.
       */
      discountAmount: number;
      /**
       * Taxableamount
       * @description The part of the subtotal that is taxable.
       */
      taxableAmount: number;
      /**
       * Taxamount
       * @description The total tax payable. This is the sum of all lineItems.taxAmount.
       */
      taxAmount: number;
      /**
       * Total
       * @description The total after discounts and taxes. This is the same as subtotal if it is tax inclusive; otherwise it is subtotal + taxAmount. total can also be expressed as exemptAmount + taxableAmount + taxAmount.
       */
      total: number;
      /**
       * Lineitems
       * @description List of line item details for the tax estimation response.
       */
      lineItems: components['schemas']['TaxEstimateLineItem'][];
    };
    /** ChargebeeTaxabilityAddress */
    ChargebeeTaxabilityAddress: {
      /** Line1 */
      line1?: string | null;
      /** Line2 */
      line2?: string | null;
      /** Line3 */
      line3?: string | null;
      /** City */
      city?: string | null;
      /** State */
      state?: string | null;
      /**
       * Country
       * @description `country` should be in ISO 3166-1 alpha-2 format, e.g. US, CA and should not be empty. Not validating here as the validation structure can be different for different providers
       * @default US
       */
      country: string | null;
      /**
       * Postalcode
       * @description `postal_code` can be empty for some locales. Not validating here as the validation structure can be different for different providers
       * @default
       */
      postalCode: string | null;
    };
    /** ChargebeeTaxabilityAddressRequest */
    ChargebeeTaxabilityAddressRequest: {
      address?: components['schemas']['ChargebeeTaxabilityAddress'] | null;
    };
    /** ChargebeeUnexpectedError */
    ChargebeeUnexpectedError: {
      /**
       * Description
       * @default Unexpected error while processing request.
       */
      description: string;
    };
    /** ChargebeeValidationError */
    ChargebeeValidationError: {
      code: components['schemas']['ChargebeeRequestValidationEnum'];
      /** Message */
      message: string;
      /** Entity */
      entity: string;
      /** Entityfield */
      entityField?: string | null;
      /** Helpurl */
      helpUrl?: string | null;
    };
    /** CheckoutNewItem */
    CheckoutNewItem: {
      /** Item Price Id */
      item_price_id: string;
    };
    /** ConnectionRead */
    ConnectionRead: {
      /** Id */
      id: string;
      /** Updated At */
      updated_at: string | null;
      /**
       * Created At
       * Format: date-time
       */
      created_at: string;
      /** Last Synced */
      last_synced: string | null;
      /**
       * Initial Import Complete
       * @default false
       */
      initial_import_complete: boolean | null;
      source: components['schemas']['SourceEnum'];
      /** External Id */
      external_id: string;
      /** Url */
      url?: string | null;
      status: components['schemas']['ConnectionStatusEnum'];
      /** Records Synced */
      records_synced: number;
    };
    /**
     * ConnectionStatusEnum
     * @enum {string}
     */
    ConnectionStatusEnum: ConnectionStatusEnum;
    /** ConnectionUpdate */
    ConnectionUpdate: {
      status: components['schemas']['ConnectionStatusEnum'];
    };
    /** Content */
    Content: {
      item?: components['schemas']['Item'] | null;
      item_price?: components['schemas']['ItemPrice'] | null;
    };
    /**
     * CountryCodeEnum
     * @enum {string}
     */
    CountryCodeEnum: CountryCodeEnum;
    /**
     * CreditNoteRequest
     * @description The details of a credit note sent to the Tax Service Adapter by Chargebee.
     *     A credit note is used to reduce the amount due on an invoice.
     *     If the credit note is issued after payments have been made for the invoice, refunds
     *     can be issued to the Customer.
     */
    CreditNoteRequest: {
      /**
       * Creditnotecode
       * @description The unique identifier of the credit note in Chargebee.
       */
      creditNoteCode: string;
      /**
       * Invoicecode
       * @description The unique identifier of the invoice in Chargebee to which this credit note belongs.
       */
      invoiceCode: string;
      /** @description Whether the credit note was created for the full amount on the invoice or only for a part of the invoice amount. */
      creditNoteType: components['schemas']['CreditNoteType'];
      /**
       * Documentdatetime
       * Format: date-time
       * @description The date and time at which the credit note was created in Chargebee. For example, if the value is 2022-10-28T15:36:28.129+05:30, then the timestamp represents October 28, 2022, at 15:36:28.129, with an offset of +05:30. This means that the time represented is 5 hours and 30 minutes ahead of UTC/GMT. In the case of a merchant site located in UTC, these datatypes would send a timestamp in the format 2022-11-11T15:40:44.65Z. This timestamp represents November 11, 2022, at 15:40:44.65, with the 'Z' indicating that the time is in UTC.
       */
      documentDateTime: string;
      /**
       * Currency
       * @description The currency in the ISO-4217 format.
       */
      currency: string;
      /** @description The details of the seller involved in the transaction including company code and address. */
      seller: components['schemas']['Seller'];
      /** @description The details of the Customer. */
      customer: components['schemas']['ChargebeeCustomer'];
      /**
       * Total
       * @description The total amount of the credit note. total can be expressed as exemptAmount + taxableAmount + taxAmount.
       */
      total: number;
      /**
       * Exemptamount
       * @description The amount exempted from tax.
       */
      exemptAmount: number;
      /**
       * Discountamount
       * @description The total discount applied. This is the sum of all lineItems.discount.
       */
      discountAmount: number;
      /**
       * Taxableamount
       * @description The amount upon which the tax is calculated.
       */
      taxableAmount: number;
      /**
       * Taxamount
       * @description The total tax payable. This is the sum of all lineItems.taxAmount.
       */
      taxAmount: number;
      /**
       * Roundingamount
       * @description The rounding amount added to the total amount to account for fractional correction.
       */
      roundingAmount?: number | null;
      /**
       * Lineitems
       * @description The details of a line item.
       * @default []
       */
      lineItems: components['schemas']['LineItem-Input'][];
    };
    /**
     * CreditNoteResponse
     * @description The details of a credit note returned by the Tax Service Adapter.
     *     A credit note is used to reduce the amount due on an invoice.
     *     If the credit note is issued after payments have been made for the invoice, refunds
     *     can be issued to the Customer.
     */
    CreditNoteResponse: {
      /**
       * Creditnotecode
       * @description The unique identifier of the credit note in Chargebee.
       */
      creditNoteCode: string;
      /**
       * Invoicecode
       * @description The unique identifier of the invoice in Chargebee to which this credit note belongs.
       */
      invoiceCode: string;
      /** @description Whether the credit note was created for the full amount on the invoice or only for a part of the invoice amount. */
      creditNoteType: components['schemas']['CreditNoteType'];
      /**
       * Documentdatetime
       * Format: date-time
       * @description The date and time at which the credit note was created in Chargebee. For example, if the value is 2022-10-28T15:36:28.129+05:30, then the timestamp represents October 28, 2022, at 15:36:28.129, with an offset of +05:30. This means that the time represented is 5 hours and 30 minutes ahead of UTC/GMT. In the case of a merchant site located in UTC, these datatypes would send a timestamp in the format 2022-11-11T15:40:44.65Z. This timestamp represents November 11, 2022, at 15:40:44.65, with the 'Z' indicating that the time is in UTC.
       */
      documentDateTime: string;
      /**
       * Currency
       * @description The currency in the ISO-4217 format.
       */
      currency: string;
      /** @description The details of the seller involved in the transaction including company code and address. */
      seller: components['schemas']['Seller'];
      /** @description The details of the Customer. */
      customer: components['schemas']['ChargebeeCustomer'];
      /**
       * Total
       * @description The total amount of the credit note. total can be expressed as exemptAmount + taxableAmount + taxAmount.
       */
      total: number;
      /**
       * Exemptamount
       * @description The amount exempted from tax.
       */
      exemptAmount: number;
      /**
       * Discountamount
       * @description The total discount applied. This is the sum of all lineItems.discount.
       */
      discountAmount: number;
      /**
       * Taxableamount
       * @description The amount upon which the tax is calculated.
       */
      taxableAmount: number;
      /**
       * Taxamount
       * @description The total tax payable. This is the sum of all lineItems.taxAmount.
       */
      taxAmount: number;
      /**
       * Roundingamount
       * @description The rounding amount added to the total amount to account for fractional correction.
       */
      roundingAmount?: number | null;
      /**
       * Lineitems
       * @description The details of a line item.
       * @default []
       */
      lineItems: components['schemas']['backend__src__chargebee_adapter__models__invoice__LineItem-Output'][];
      /**
       * Creditnoteid
       * @description The unique identifier of the credit note at the Tax Service Provider or Tax Service Adapter.
       */
      creditNoteId: string;
      /** @description Status of the invoice document. */
      status: components['schemas']['DocumentStatus'];
      /**
       * Subtotal
       * @description The amount after discounts. This is the sum of all lineItems.subtotal.
       */
      subtotal?: number | null;
    };
    /**
     * CreditNoteType
     * @enum {string}
     */
    CreditNoteType: CreditNoteType;
    /**
     * CurrencyEnum
     * @enum {string}
     */
    CurrencyEnum: CurrencyEnum;
    /** Customer */
    'Customer-Input': {
      /** Id */
      id?: string;
      /**
       * Created At
       * Format: date-time
       */
      created_at?: string;
      /** Updated At */
      updated_at?: string | null;
      /** Phone */
      phone?: string | null;
      /** Street 1 */
      street_1?: string | null;
      /** Street 2 */
      street_2?: string | null;
      /** City */
      city?: string | null;
      /** County */
      county?: string | null;
      /** State */
      state?: string | null;
      /** Postal Code */
      postal_code?: string | null;
      country?: components['schemas']['CountryCodeEnum'] | null;
      /** Name */
      name?: string | null;
      /** External Id */
      external_id?: string | null;
      /** @default ACTIVE */
      status: components['schemas']['StatusEnum'];
      /** Email */
      email?: string | null;
      /** @default UNVERIFIED */
      address_status: components['schemas']['AddressStatus'];
      /** Organization Id */
      organization_id?: string;
      /**
       * Is Test Data
       * @default false
       */
      is_test_data: boolean;
    };
    /** Customer */
    'Customer-Output': {
      /** Id */
      id: string;
      /** Email */
      email: string;
      /** Auto Collection */
      auto_collection: string;
      /** Net Term Days */
      net_term_days: number;
      /** Allow Direct Debit */
      allow_direct_debit: boolean;
      /** Created At */
      created_at: number;
      /** Created From Ip */
      created_from_ip?: string | null;
      /** Taxability */
      taxability: string;
      /** Updated At */
      updated_at: number;
      /** Pii Cleared */
      pii_cleared: string;
      /** Channel */
      channel: string;
      /** Resource Version */
      resource_version: number;
      /** Deleted */
      deleted: boolean;
      /** Object */
      object: string;
      billing_address?: components['schemas']['Address-Output'] | null;
      /** Card Status */
      card_status: string;
      /** Promotional Credits */
      promotional_credits: number;
      /** Refundable Credits */
      refundable_credits: number;
      /** Excess Payments */
      excess_payments: number;
      /** Unbilled Charges */
      unbilled_charges: number;
      /** Preferred Currency Code */
      preferred_currency_code: string;
      /** Mrr */
      mrr: number;
      /** Primary Payment Source Id */
      primary_payment_source_id?: string | null;
      payment_method?: components['schemas']['PaymentMethod'] | null;
      /** Auto Close Invoices */
      auto_close_invoices: boolean;
    };
    /** CustomerBase */
    CustomerBase: {
      /** Phone */
      phone?: string | null;
      /** Street 1 */
      street_1?: string | null;
      /** Street 2 */
      street_2?: string | null;
      /** City */
      city?: string | null;
      /** County */
      county?: string | null;
      /** State */
      state?: string | null;
      /** Postal Code */
      postal_code?: string | null;
      country?: components['schemas']['CountryCodeEnum'] | null;
      /** Name */
      name?: string | null;
      /** External Id */
      external_id?: string | null;
      /** @default ACTIVE */
      status: components['schemas']['StatusEnum'];
      /** Email */
      email?: string | null;
      /** @default UNVERIFIED */
      address_status: components['schemas']['AddressStatus'];
    };
    /** CustomerCreate */
    CustomerCreate: {
      /** Phone */
      phone?: string | null;
      /** Street 1 */
      street_1?: string | null;
      /** Street 2 */
      street_2?: string | null;
      /** City */
      city?: string | null;
      /** County */
      county?: string | null;
      /** State */
      state?: string | null;
      /** Postal Code */
      postal_code?: string | null;
      country?: components['schemas']['CountryCodeEnum'] | null;
      /** Name */
      name?: string | null;
      /** External Id */
      external_id?: string | null;
      /** @default ACTIVE */
      status: components['schemas']['StatusEnum'];
      /** Email */
      email?: string | null;
      /** @default UNVERIFIED */
      address_status: components['schemas']['AddressStatus'];
      /** Organization Id */
      organization_id?: string | null;
    };
    /** CustomerRead */
    CustomerRead: {
      /** Phone */
      phone?: string | null;
      /** Street 1 */
      street_1?: string | null;
      /** Street 2 */
      street_2?: string | null;
      /** City */
      city?: string | null;
      /** County */
      county?: string | null;
      /** State */
      state?: string | null;
      /** Postal Code */
      postal_code?: string | null;
      country?: components['schemas']['CountryCodeEnum'] | null;
      /** Name */
      name?: string | null;
      /** External Id */
      external_id?: string | null;
      /** @default ACTIVE */
      status: components['schemas']['StatusEnum'];
      /** Email */
      email?: string | null;
      /** @default UNVERIFIED */
      address_status: components['schemas']['AddressStatus'];
      /** Id */
      id: string;
    };
    /** CustomerUpdate */
    CustomerUpdate: {
      /** Phone */
      phone?: string | null;
      /** Street 1 */
      street_1?: string | null;
      /** Street 2 */
      street_2?: string | null;
      /** City */
      city?: string | null;
      /** County */
      county?: string | null;
      /** State */
      state?: string | null;
      /** Postal Code */
      postal_code?: string | null;
      country?: components['schemas']['CountryCodeEnum'] | null;
      /** Name */
      name?: string | null;
      /** External Id */
      external_id?: string | null;
      /** @default ACTIVE */
      status: components['schemas']['StatusEnum'];
      /** Email */
      email?: string | null;
      /** @default UNVERIFIED */
      address_status: components['schemas']['AddressStatus'];
      /** Organization Id */
      organization_id: string;
    };
    /** DashboardData */
    DashboardData: {
      /** Total Sales */
      total_sales: number;
      /** Total Transactions Count */
      total_transactions_count: number;
      /** Total Tax Amount Imported */
      total_tax_amount_imported: number;
      /** Total Tax Amount Calculated */
      total_tax_amount_calculated: number;
      /** Unpaid Filings */
      unpaid_filings: number;
      /** Unregistered Registrations */
      unregistered_registrations: number;
      /** Nexus Met Count */
      nexus_met_count: number;
      /** Invalid Address Count */
      invalid_address_count: number;
      /** Pending Product Count */
      pending_product_count: number;
    };
    /** DashboardDetailsData */
    DashboardDetailsData: {
      /** Aggregation */
      aggregation: string;
      /** Sales */
      sales: Record<string, never>[];
      /** Transactions */
      transactions: Record<string, never>[];
      /** Tax Liability */
      tax_liability: Record<string, never>[];
    };
    /**
     * DocumentStatus
     * @enum {string}
     */
    DocumentStatus: DocumentStatus;
    /** EmailRequest */
    EmailRequest: {
      /** To Email */
      to_email: string;
      /** Subject */
      subject: string;
      /** Content */
      content: string;
      /** Link */
      link: string;
    };
    /**
     * EntityTypeEnum
     * @enum {string}
     */
    EntityTypeEnum: EntityTypeEnum;
    /** Event */
    Event: {
      /** Id */
      id?: string | null;
      /** Occurred At */
      occurred_at?: number | null;
      /** Source */
      source?: string | null;
      /** Object */
      object?: string | null;
      /** Api Version */
      api_version?: string | null;
      content?: components['schemas']['Content'] | null;
      /** Event Type */
      event_type?: string | null;
      /** Webhook Status */
      webhook_status?: string | null;
      /** Webhooks */
      webhooks?: components['schemas']['Webhook'][] | null;
    };
    /** ExemptionCreate */
    ExemptionCreate: {
      exemption_type: components['schemas']['ExemptionType'];
      /** Jurisdiction */
      jurisdiction: string;
      /**
       * Start Date
       * Format: date
       */
      start_date: string;
      /** End Date */
      end_date?: string | null;
      /** Customer Id */
      customer_id: string;
      /**
       * Reseller
       * @default false
       */
      reseller: boolean;
      /** Fein */
      FEIN?: string | null;
      /** Sales Tax Id */
      sales_tax_id?: string | null;
      status?: components['schemas']['ExemptionStatus'] | null;
    };
    /** ExemptionRead */
    ExemptionRead: {
      exemption_type: components['schemas']['ExemptionType'];
      /** Jurisdiction */
      jurisdiction: string;
      /**
       * Start Date
       * Format: date
       */
      start_date: string;
      /** End Date */
      end_date?: string | null;
      /** Customer Id */
      customer_id: string;
      /**
       * Reseller
       * @default false
       */
      reseller: boolean;
      /** Fein */
      FEIN?: string | null;
      /** Sales Tax Id */
      sales_tax_id?: string | null;
      status?: components['schemas']['ExemptionStatus'] | null;
      /** Id */
      id: string;
      /** Customer Name */
      customer_name?: string | null;
    };
    /**
     * ExemptionStatus
     * @enum {string}
     */
    ExemptionStatus: ExemptionStatus;
    /**
     * ExemptionType
     * @enum {string}
     */
    ExemptionType: ExemptionType;
    /** FileUploadData */
    FileUploadData: {
      /** File Name */
      file_name: string;
    };
    /** FileUploadURLs */
    FileUploadURLs: {
      /** File Name */
      file_name: string;
      /** Is Local */
      is_local: boolean;
      /** Upload Url Config */
      upload_url_config: Record<string, never>;
      imports: components['schemas']['imports'];
    };
    /** FilingCreate */
    FilingCreate: {
      /** Registration Id */
      registration_id: string;
      status: components['schemas']['FilingStatusEnum'];
      /**
       * Start Date
       * Format: date
       */
      start_date: string;
      /**
       * End Date
       * Format: date
       */
      end_date: string;
      /** State Name */
      state_name: string;
      country_code: components['schemas']['CountryCodeEnum'];
    };
    /** FilingDetailsRead */
    FilingDetailsRead: {
      /** @default UNFILED */
      status: components['schemas']['FilingStatusEnum'];
      /**
       * Start Date
       * Format: date
       */
      start_date: string;
      /**
       * End Date
       * Format: date
       */
      end_date: string;
      /** Due Date */
      due_date?: string | null;
      /** Is Manual */
      is_manual?: boolean | null;
      /** State Code */
      state_code?: string | null;
      /** State Name */
      state_name?: string | null;
      country_code?: components['schemas']['CountryCodeEnum'] | null;
      /** Jira Issue Key */
      jira_issue_key?: string | null;
      /**
       * Amount Calculated
       * @default 0.00
       */
      amount_calculated: number;
      /**
       * Amount Adjusted
       * @default 0.00
       */
      amount_adjusted: number;
      /**
       * Amount Discounts
       * @default 0.00
       */
      amount_discounts: number;
      /**
       * Amount Fees
       * @default 0.00
       */
      amount_fees: number;
      /**
       * Amount Penalties
       * @default 0.00
       */
      amount_penalties: number;
      /**
       * Amount Tax Collected
       * @default 0.00
       */
      amount_tax_collected: number;
      /**
       * Amount Sales
       * @default 0.00
       */
      amount_sales: number;
      /**
       * Amount
       * @default 0.00
       */
      amount: number;
      /** Id */
      id: string;
      /** Registration Id */
      registration_id: string;
      /**
       * Can File
       * @default false
       */
      can_file: boolean;

      total_tax_liability: number;
      /**
       * Amount
       * @default 0.00
       */
      transaction_count: number;
      /**
       * Amount
       * @default 0
       */
      tax_remitted?: number | null;
      return_confirmation_id?: string | null;
      payment_confirmation_id?: string | null;

      internal_notes: string | null;
      /**
       * attachments
       * @default {}
       * key is attachmentId and value is attachment name
       */
      attachments: Record<string, never> | Record<never, never>;
    };
    /**
     * FilingFrequencyEnum
     * @enum {string}
     */
    FilingFrequencyEnum: FilingFrequencyEnum;
    /** FilingRead */
    FilingRead: {
      /** @default UNFILED */
      status: components['schemas']['FilingStatusEnum'];
      /**
       * Start Date
       * Format: date
       */
      start_date: string;
      /**
       * End Date
       * Format: date
       */
      end_date: string;
      /** Due Date */
      due_date?: string | null;
      /** Is Manual */
      date_filed?: string | null;
      is_manual?: boolean | null;
      /** State Code */
      state_code?: string | null;
      /** State Name */
      state_name?: string | null;
      country_code?: components['schemas']['CountryCodeEnum'] | null;
      /** Jira Issue Key */
      jira_issue_key?: string | null;
      /**
       * Amount Calculated
       * @default 0.00
       */
      amount_calculated: string;
      /**
       * Amount Adjusted
       * @default 0.00
       */
      amount_adjusted: string;
      /**
       * Amount Discounts
       * @default 0.00
       */
      amount_discounts: string;
      /**
       * Amount Fees
       * @default 0.00
       */
      amount_fees: string;
      /**
       * Amount Penalties
       * @default 0.00
       */
      amount_penalties: string;
      /**
       * Amount Tax Collected
       * @default 0.00
       */
      amount_tax_collected: string;
      /**
       * Amount Sales
       * @default 0.00
       */
      amount_sales: string;
      /**
       * Amount
       * @default 0.00
       */
      amount: string;
      /** Id */
      id: string;
      /** Registration Id */
      registration_id: string;
      total_tax_liability: string;
      /**
       * Amount
       * @default 0.00
       */
    };
    /**
     * FilingStatusEnum
     * @enum {string}
     */
    FilingStatusEnum: FilingStatusEnum;
    /** FilingUpdate */
    FilingUpdate: {
      /** @default UNFILED */
      status: components['schemas']['FilingStatusEnum'];
      /**
       * Start Date
       * Format: date
       */
      start_date: string;
      /**
       * End Date
       * Format: date
       */
      end_date: string;
      /** Due Date */
      due_date?: string | null;
      /** Is Manual */
      is_manual?: boolean | null;
      /** State Code */
      state_code?: string | null;
      /** State Name */
      state_name?: string | null;
      country_code?: components['schemas']['CountryCodeEnum'] | null;
      /** Jira Issue Key */
      jira_issue_key?: string | null;
    };
    /** FilingUpdateInternal */
    FilingUpdateInternal: {
      /** @default UNFILED */
      status: components['schemas']['FilingStatusEnum'];
      /**
       * Start Date
       * Format: date
       */
      start_date: string;
      /**
       * End Date
       * Format: date
       */
      end_date: string;
      /** Due Date */
      due_date?: string | null;
      /** Is Manual */
      is_manual?: boolean | null;
      /** State Code */
      state_code?: string | null;
      /** State Name */
      state_name?: string | null;
      country_code?: components['schemas']['CountryCodeEnum'] | null;
      /** Jira Issue Key */
      jira_issue_key?: string | null;
      /**
       * Amount Calculated
       * @default 0.00
       */
      amount_calculated: number | string;
      /**
       * Amount Adjusted
       * @default 0.00
       */
      amount_adjusted: number | string;
      /**
       * Amount Discounts
       * @default 0.00
       */
      amount_discounts: number | string;
      /**
       * Amount Fees
       * @default 0.00
       */
      amount_fees: number | string;
      /**
       * Amount Penalties
       * @default 0.00
       */
      amount_penalties: number | string;
      /**
       * Amount Tax Collected
       * @default 0.00
       */
      amount_tax_collected: number | string;
      /**
       * Amount Sales
       * @default 0.00
       */
      amount_sales: number | string;
      /**
       * Amount
       * @default 0.00
       */
      amount: number | string;
    };
    /** HTTPValidationError */
    HTTPValidationError: {
      /** Detail */
      detail?: components['schemas']['ValidationError'][];
    };
    /** HostedPage */
    HostedPage: {
      /** Id */
      id: string;
      /** Type */
      type: string;
      /** Url */
      url: string;
      /** State */
      state: string;
      /** Embed */
      embed: boolean;
      /** Created At */
      created_at: number;
      /** Expires At */
      expires_at: number;
      /** Object */
      object: string;
      /** Updated At */
      updated_at: number;
      /** Resource Version */
      resource_version: number;
    };
    /** ImportFormat */
    ImportFormat: {
      /** related_external_id*/
      related_external_id?: string | null;
      /**
       * Realed extrernal id
       * @description Required when the transaction type is PARTIAL_CREDIT_NOTE or FULL_CREDIT_NOTE.
       */
      /** Transaction External Id */
      transaction_external_id: string;
      /**
       * Transaction Status
       * @description The status of the transaction in the source system.
       * @default COMMITTED
       */
      status: components['schemas']['TransactionStatusEnum'];
      /**
       * Transaction Source
       * @description The source of the transaction.
       * @default IMPORT
       */
      transaction_source: components['schemas']['SourceEnum'];
      /**
       * Transaction Date
       * Format: date-time
       * @description Date the transaction took place.
       */
      date: string;
      /**
       * Currency
       * @description Currency of the transaction.
       * @default USD
       */
      currency: components['schemas']['CurrencyEnum'];
      /**
       * Description
       * @description Description of the transaction.
       */
      description?: string | null;
      /**
       * Customer ID
       * @description Unique identifier of the customer in the source system.
       */
      customer_id: string;
      /**
       * Customer Name
       * @description Full name of the customer.
       */
      customer_name?: string | null;
      /**
       * Customer Email
       * @description Email address of the customer.
       */
      customer_email?: string | null;
      /**
       * Marketplace
       * @description Indicates if the transaction is a marketplace transaction.
       * @default false
       */
      marketplace: boolean | null;
      /**
       * Ship To Address Phone
       * @description Phone number of the ship to address.
       */
      ship_to_phone?: string | null;
      /**
       * Ship To Address Street Line 1
       * @description First line of the ship to address.
       */
      ship_to_street_line_1?: string | null;
      /**
       * Ship To Address Street Line 2
       * @description Second line of the ship to address.
       */
      ship_to_street_line_2?: string | null;
      /**
       * Ship To Address City
       * @description City of the ship to address.
       */
      ship_to_city?: string | null;
      /**
       * Ship To Address State
       * @description State/Province of the ship to address.
       */
      ship_to_state?: string | null;
      /**
       * Ship To Address Postal Code
       * @description Postal code of the ship to address.
       */
      ship_to_postal_code: string;
      /**
       * Ship To Address Country
       * @description Country of the ship to address.
       */
      ship_to_country?: string | null;
      /**
       * Bill To Address Phone
       * @description Phone number of the bill to address.
       */
      bill_to_phone?: string | null;
      /**
       * Bill To Address Street Line 1
       * @description First line of the bill to address.
       */
      bill_to_street_line_1?: string | null;
      /**
       * Bill To Address Street Line 2
       * @description Second line of the bill to address.
       */
      bill_to_street_line_2?: string | null;
      /**
       * Bill To Address City
       * @description City of the bill to address.
       */
      bill_to_city?: string | null;
      /**
       * Bill To Address State
       * @description State/Province of the bill to address.
       */
      bill_to_state?: string | null;
      /**
       * Bill To Address Postal Code
       * @description Postal code of the bill to address.
       */
      bill_to_postal_code: string;
      /**
       * Bill To Address Country
       * @description Country of the bill to address.
       */
      bill_to_country?: string | null;
      /**
       * Line Item ID
       * @description Unique identifier of the line item in the source system.
       */
      line_item_id?: string | null;
      /**
       * Product External ID
       * @description Unique identifier of the product in the source system.
       */
      product_external_id: string;
      /**
       * Product Name
       * @description Name of the product on this line item.
       */
      product_name?: string | null;
      /**
       * Product Description
       * @description Description of the product on this line item.
       */
      product_description?: string | null;
      /**
       * Amount
       * @description Amount of this line item.
       * @default 0.00
       */
      amount: string;
      /**
       * Tax Amount
       * @description Tax amount collected.
       * @default 0.00
       */
      tax_amount: string;
      /**
       * Quantity
       * @description Quantity of this line item sold.
       * @default 1
       */
      quantity: string;
      /**
       * Exempt
       * @description Indicates if this line item is exempt from tax.
       * @default false
       */
      exempt: boolean;
      /**
       * Transaction Type
       * @description Type of the transaction.
       * @default SALE
       */
      transaction_type: components['schemas']['TransactionTypeEnum'] | null;
      /**
       * Operation
       * @description Operation to perform on the transaction.
       * @default IMPORT
       */
      operation: components['schemas']['ImportOperationEnum'] | null;
    };
    /**
     * ImportOperationEnum
     * @enum {string}
     */
    ImportOperationEnum: ImportOperationEnum;
    /** Invoice */
    Invoice: {
      /** Id */
      id: string;
      /** Customer Id */
      customer_id: string;
      /** Po Number */
      po_number?: string | null;
      /** Subscription Id */
      subscription_id?: string | null;
      /** Vat Number */
      vat_number?: string | null;
      /** Recurring */
      recurring: boolean;
      /** Status */
      status: string;
      /** Price Type */
      price_type: string;
      /** Date */
      date?: number | null;
      /** Due Date */
      due_date?: number | null;
      /** Net Term Days */
      net_term_days?: number | null;
      /** Exchange Rate */
      exchange_rate?: number | null;
      /** Total */
      total?: number | null;
      /** Amount Paid */
      amount_paid?: number | null;
      /** Amount Adjusted */
      amount_adjusted?: number | null;
      /** Write Off Amount */
      write_off_amount?: number | null;
      /** Credits Applied */
      credits_applied?: number | null;
      /** Amount Due */
      amount_due?: number | null;
      /** Paid At */
      paid_at?: number | null;
      /** Dunning Status */
      dunning_status?: string | null;
      /** Updated At */
      updated_at?: number | null;
      /** Resource Version */
      resource_version?: number | null;
      /** Deleted */
      deleted: boolean;
      /** Object */
      object?: string | null;
      /** First Invoice */
      first_invoice: boolean;
      /** Amount To Collect */
      amount_to_collect?: number | null;
      /** Round Off Amount */
      round_off_amount?: number | null;
      /** New Sales Amount */
      new_sales_amount?: number | null;
      /** Has Advance Charges */
      has_advance_charges: boolean;
      /** Currency Code */
      currency_code?: string | null;
      /** Base Currency Code */
      base_currency_code?: string | null;
      /** Generated At */
      generated_at?: number | null;
      /** Is Gifted */
      is_gifted: boolean;
      /** Term Finalized */
      term_finalized: boolean;
      /** Channel */
      channel?: string | null;
      /** Tax */
      tax?: number | null;
      /** Line Items */
      line_items?: components['schemas']['backend__src__billing__models__LineItem'][] | null;
      /** Sub Total */
      sub_total?: number | null;
      billing_address?: components['schemas']['Address-Output'] | null;
      shipping_address?: components['schemas']['Address-Output'] | null;
    };
    /** Item */
    Item: {
      /** Id */
      id?: string | null;
      /** Name */
      name?: string | null;
      /** External Name */
      external_name?: string | null;
      /** Description */
      description?: string | null;
      /** Status */
      status?: string | null;
      /** Resource Version */
      resource_version?: number | null;
      /** Updated At */
      updated_at?: number | null;
      /** Item Family Id */
      item_family_id?: string | null;
      /** Type */
      type?: string | null;
      /** Is Shippable */
      is_shippable?: boolean | null;
      /** Is Giftable */
      is_giftable?: boolean | null;
      /** Enabled For Checkout */
      enabled_for_checkout?: boolean | null;
      /** Enabled In Portal */
      enabled_in_portal?: boolean | null;
      /** Item Applicability */
      item_applicability?: string | null;
      /** Metered */
      metered?: boolean | null;
      /** Channel */
      channel?: string | null;
      /** Object */
      object?: string | null;
      /** Archivable */
      archivable?: boolean | null;
    };
    /** ItemPrice */
    ItemPrice: {
      /** Id */
      id?: string | null;
      /** Name */
      name?: string | null;
      /** Description */
      description?: string | null;
      /** Item Family Id */
      item_family_id?: string | null;
      /** Item Id */
      item_id?: string | null;
      /** Status */
      status?: string | null;
      /** External Name */
      external_name?: string | null;
      /** Pricing Model */
      pricing_model?: string | null;
      /** Price */
      price?: number | null;
      /** Currency Code */
      currency_code?: string | null;
      /** Free Quantity */
      free_quantity?: number | null;
      /** Channel */
      channel?: string | null;
      /** Resource Version */
      resource_version?: number | null;
      /** Updated At */
      updated_at?: number | null;
      /** Created At */
      created_at?: number | null;
      /** Is Taxable */
      is_taxable?: boolean | null;
      /** Tax Providers Fields */
      tax_providers_fields?: Record<string, never>[] | null;
      /** Item Type */
      item_type?: string | null;
      /** Show Description In Invoices */
      show_description_in_invoices?: boolean | null;
      /** Show Description In Quotes */
      show_description_in_quotes?: boolean | null;
      /** Object */
      object?: string | null;
      /** Archivable */
      archivable?: boolean | null;
      /** Tax Detail */
      tax_detail?: Record<string, never> | null;
    };
    /**
     * Jurisdiction
     * @description The tax jurisdiction details
     */
    Jurisdiction: {
      /**
       * Code
       * @description The jurisdiction code.
       */
      code: string;
      /** @description The type of tax jurisdiction. */
      type: components['schemas']['TaxJurisdictionType'];
      /**
       * Name
       * @description The jurisdiction name.
       */
      name: string;
    };
    /**
     * JurisdictionType
     * @enum {string}
     */
    JurisdictionType: JurisdictionType;
    /**
     * LineItem
     * @description The details of a line item
     */
    'LineItem-Input': {
      /**
       * Number
       * @description Index or serial number of the line item.
       */
      number: number;
      /**
       * Itemcode
       * @description The unique identifier (in Chargebee) of the product correspondingto the line item. If the line item corresponds to a one-off charge,then this identifier is not present.
       */
      itemCode?: string | null;
      /**
       * Description
       * @description The description of the line item.
       */
      description?: string | null;
      /**
       * Quantity
       * @description The quantity associated with this line item.
       */
      quantity?: number | null;
      /**
       * Unitprice
       * @description The unit price for this line item. In case of tiered pricing where theunit price varies for each quantity tier, this is the average unit price.
       */
      unitPrice?: number | null;
      /**
       * Amount
       * @description The amount for this line item. This is unitPrice*quantity.
       */
      amount: number;
      /**
       * Istaxinclusive
       * @description Indicates whether the subtotal for this line item is inclusive of taxes.
       */
      isTaxInclusive: boolean;
      /**
       * Istaxable
       * @description Indicates whether this line item is taxable.
       */
      isTaxable: boolean;
      /**
       * Taxidentifiers
       * @description The tax code fields of the product used for tax calculation.
       */
      taxIdentifiers?: components['schemas']['TaxIdentifier'][] | null;
      /** @description The tax exemption type for a line item. This is a mandatory parameter while applying tax exemption on any line-item. */
      taxExemptType?: components['schemas']['TaxExemptType'] | null;
      /**
       * Taxexemptreason
       * @description The reason due to which a line item is exempted from tax. This is a mandatory parameter while applying tax exemption on any line-item.
       */
      taxExemptReason?: string | null;
      /**
       * Exemptamount
       * @description The part of this line item's subtotal that is exempted from tax.
       */
      exemptAmount: number;
      /**
       * Discountamount
       * @description The discount applied to this line item.
       */
      discountAmount: number;
      /**
       * Subtotal
       * @description The amount after discounts for this line item.This is amount - discountAmount.
       */
      subtotal: number;
      /**
       * Taxableamount
       * @description The part of this line item's subtotal that is taxable.
       */
      taxableAmount: number;
      /**
       * Taxamount
       * @description The tax payable for this line item. This is the sum of all taxes.taxAmount for this line item.
       */
      taxAmount: number;
      /**
       * Total
       * @description The total for this line item after discounts and taxes. This is the same as subtotal if it is tax inclusive; otherwise it is subtotal + taxAmount. total can also be expressed as exemptAmount + taxableAmount + taxAmount.
       */
      total: number;
      /**
       * Ispartialtax
       * @description Indicates if taxes were applied only partially for this line item.
       */
      isPartialTax?: boolean | null;
      /**
       * Taxes
       * @description List of taxes applied for this line item under each jurisdiction.
       */
      taxes: components['schemas']['TaxLineItem-Input'][];
    };
    /** LinkedCustomer */
    LinkedCustomer: {
      /** Customer Id */
      customer_id: string;
      /** Has Active Subscription */
      has_active_subscription: boolean;
      /** Has Billing Address */
      has_billing_address: boolean;
      /** Has Payment Method */
      has_payment_method: boolean;
      /** Object */
      object: string;
      /** Email */
      email: string;
    };
    /** OnBoardingStepStatusData */
    OnBoardingStepStatusData: {
      /** Transactions Status */
      transactions_status: boolean;
      /** Physical Nexus Status */
      physical_nexus_status: boolean;
      /** Organization Details Status */
      organization_details_status: boolean;
      /** Bank Details Status */
      bank_details_status: boolean;

      onboarding_steps_status: boolean;
    };
    /** OnboardingData */
    OnboardingData: {
      status: components['schemas']['OnboardingStatus'];
    };
    /**
     * OnboardingStatus
     * @enum {string}
     */
    OnboardingStatus: OnboardingStatus;
    /** OrgCreated */
    OrgCreated: {
      /** Event Type */
      event_type: string;
      /** Name */
      name: string;
      /** Org Id */
      org_id: string;
    };
    /** OrgUpdatedDeleted */
    OrgUpdatedDeleted: {
      /** Event Type */
      event_type: string;
      /** Org Id */
      org_id: string;
    };
    /** OrganizationBillingPlan */
    OrganizationBillingPlan: {
      /** Billing Plan */
      billing_plan: string;
      /** Subscription Id */
      subscription_id?: string | null;
      subscription?: components['schemas']['Subscription'] | null;
      customer?: components['schemas']['Customer-Output'] | null;
      card?: components['schemas']['Card'] | null;
    };
    /** OrganizationDetailsBusinessContactCreateUpdate */
    OrganizationDetailsBusinessContactCreateUpdate: {
      /** Business Contact Name */
      business_contact_name: string;
      /** Business Contact Phone */
      business_contact_phone: string;
      /** Business Contact Email */
      business_contact_email: string;
      /**
       * Business Contact Dob
       * Format: date
       */
      business_contact_dob: string;
      /** Business Contact Title */
      business_contact_title: string;
      /** Business Contact Address 1 */
      business_contact_address_1: string;
      /** Business Contact Address 2 */
      business_contact_address_2: string | null;
      /** Business Contact City */
      business_contact_city: string;
      /** Business Contact State */
      business_contact_state: string;
      /** Business Contact Postal Code */
      business_contact_postal_code: string;
      business_contact_country_code: components['schemas']['CountryCodeEnum'];
      /** Business Contact Ssn */
      business_contact_ssn: string;
      /** Business Contact Dl */
      business_contact_dl: string;
    };
    /** OrganizationDetailsBusinessDetailsCreateUpdate */
    OrganizationDetailsBusinessDetailsCreateUpdate: {
      /** Business Name */
      business_name: string;
      entity_type: components['schemas']['EntityTypeEnum'];
      /** Dba */
      dba: string;
      /** Incorporation State */
      incorporation_state: string;
      /** Ein */
      ein: string;
      /** Company Address 1 */
      company_address_1: string;
      /** Company Address 2 */
      company_address_2?: string | null;
      /** Company City */
      company_city: string;
      /** Company State */
      company_state: string;
      /** Company Postal Code */
      company_postal_code: string;
      company_country_code: components['schemas']['CountryCodeEnum'];
      /** Business Description */
      business_description: string;
      /** Home State Registration */
      home_state_registration: string;
      /**
       * First Operations Date
       * Format: date
       */
      first_operations_date: string;
    };
    /** OrganizationDetailsBusinessInformationCreateUpdate */
    OrganizationDetailsBusinessInformationCreateUpdate: {
      /** Business Phone */
      business_phone: string;
      /** Business Email */
      business_email: string;
      /** Business Address 1 */
      business_address_1: string;
      /** Business Address 2 */
      business_address_2: string | null;
      /** Business City */
      business_city: string;
      /** Business State */
      business_state: string;
      /** Business Postal Code */
      business_postal_code: string;
      business_country_code: components['schemas']['CountryCodeEnum'];
      /** Business Mailing Address 1 */
      business_mailing_address_1: string;
      /** Business Mailing Address 2 */
      business_mailing_address_2: string | null;
      /** Business Mailing City */
      business_mailing_city: string;
      /** Business Mailing State */
      business_mailing_state: string;
      /** Business Mailing Postal Code */
      business_mailing_postal_code: string;
      business_mailing_country_code: components['schemas']['CountryCodeEnum'];
      /**
       * Business Fiscal Year End
       * Format: date
       */
      business_fiscal_year_end: string;
    };
    /** OrganizationDetailsBusinessOwnersCreateUpdate */
    OrganizationDetailsBusinessOwnersCreateUpdate: {
      /** Business Owner1 Name */
      business_owner1_name: string;
      /** Business Owner1 Phone */
      business_owner1_phone: string;
      /** Business Owner1 Email */
      business_owner1_email: string;
      /**
       * Business Owner1 Dob
       * Format: date
       */
      business_owner1_dob: string;
      /** Business Owner1 Title */
      business_owner1_title: string;
      /** Business Owner1 Address 1 */
      business_owner1_address_1: string;
      /** Business Owner1 Address 2 */
      business_owner1_address_2: string | null;
      /** Business Owner1 City */
      business_owner1_city: string;
      /** Business Owner1 State */
      business_owner1_state: string;
      /** Business Owner1 Postal Code */
      business_owner1_postal_code: string;
      business_owner1_country_code: components['schemas']['CountryCodeEnum'];
      /** Business Owner1 Ssn */
      business_owner1_ssn: string;
      /** Business Owner1 Dl */
      business_owner1_dl: string;
      /** Business Owner1 Percent Ownership */
      business_owner1_percent_ownership: string;
      /** Business Owner2 Name */
      business_owner2_name: string | null;
      /** Business Owner2 Phone */
      business_owner2_phone: string | null;
      /** Business Owner2 Email */
      business_owner2_email: string | null;
      /** Business Owner2 Dob */
      business_owner2_dob: string | null;
      /** Business Owner2 Title */
      business_owner2_title: string | null;
      /** Business Owner2 Address 1 */
      business_owner2_address_1: string | null;
      /** Business Owner2 Address 2 */
      business_owner2_address_2: string | null;
      /** Business Owner2 City */
      business_owner2_city: string | null;
      /** Business Owner2 State */
      business_owner2_state: string | null;
      /** Business Owner2 Postal Code */
      business_owner2_postal_code: string | null;
      business_owner2_country_code: components['schemas']['CountryCodeEnum'] | null;
      /** Business Owner2 Ssn */
      business_owner2_ssn: string | null;
      /** Business Owner2 Dl */
      business_owner2_dl: string | null;
      /** Business Owner2 Percent Ownership */
      business_owner2_percent_ownership: string | null;
      /** Business Owner3 Name */
      business_owner3_name: string | null;
      /** Business Owner3 Phone */
      business_owner3_phone: string | null;
      /** Business Owner3 Email */
      business_owner3_email: string | null;
      /** Business Owner3 Dob */
      business_owner3_dob: string | null;
      /** Business Owner3 Title */
      business_owner3_title: string | null;
      /** Business Owner3 Address 1 */
      business_owner3_address_1: string | null;
      /** Business Owner3 Address 2 */
      business_owner3_address_2: string | null;
      /** Business Owner3 City */
      business_owner3_city: string | null;
      /** Business Owner3 State */
      business_owner3_state: string | null;
      /** Business Owner3 Postal Code */
      business_owner3_postal_code: string | null;
      business_owner3_country_code: components['schemas']['CountryCodeEnum'] | null;
      /** Business Owner3 Ssn */
      business_owner3_ssn: string | null;
      /** Business Owner3 Dl */
      business_owner3_dl: string | null;
      /** Business Owner3 Percent Ownership */
      business_owner3_percent_ownership: string | null;
    };
    /** OrganizationDetailsCompanyAddressCreateUpdate */
    OrganizationDetailsCompanyAddressCreateUpdate: {
      /** Business Name */
      business_name: string;
      /** Company Address 1 */
      company_address_1?: string | null;
      /** Company Address 2 */
      company_address_2?: string | null;
      /** Company City */
      company_city: string;
      /** Company State */
      company_state: string;
      /** Company Postal Code */
      company_postal_code: string;
      company_country_code?: components['schemas']['CountryCodeEnum'] | null;
    };
    /** OrganizationDetailsRead */
    OrganizationDetailsRead: {
      /** Business Name */
      business_name?: string | null;
      entity_type?: components['schemas']['EntityTypeEnum'] | null;
      /** Dba */
      dba?: string | null;
      /** Incorporation State */
      incorporation_state?: string | null;
      /** Incorporation Country */
      incorporation_country?: string | null;
      /** Ein */
      ein?: string | null;
      /** Company Address 1 */
      company_address_1?: string | null;
      /** Company Address 2 */
      company_address_2?: string | null;
      /** Company City */
      company_city?: string | null;
      /** Company State */
      company_state?: string | null;
      /** Company Postal Code */
      company_postal_code?: string | null;
      company_country_code?: components['schemas']['CountryCodeEnum'] | null;
      /** Business Description */
      business_description?: string | null;
      /** Home State Registration */
      home_state_registration?: string | null;
      /** First Operations Date */
      first_operations_date?: string | null;
      /** Business Phone */
      business_phone?: string | null;
      /** Business Email */
      business_email?: string | null;
      /** Business Address 1 */
      business_address_1?: string | null;
      /** Business Address 2 */
      business_address_2?: string | null;
      /** Business City */
      business_city?: string | null;
      /** Business State */
      business_state?: string | null;
      /** Business Postal Code */
      business_postal_code?: string | null;
      business_country_code?: components['schemas']['CountryCodeEnum'] | null;
      /** Business Mailing Address 1 */
      business_mailing_address_1?: string | null;
      /** Business Mailing Address 2 */
      business_mailing_address_2?: string | null;
      /** Business Mailing City */
      business_mailing_city?: string | null;
      /** Business Mailing State */
      business_mailing_state?: string | null;
      /** Business Mailing Postal Code */
      business_mailing_postal_code?: string | null;
      business_mailing_country_code?: components['schemas']['CountryCodeEnum'] | null;
      /** Business Fiscal Year End */
      business_fiscal_year_end?: string | null;
      /** Accounting Model */
      accounting_model?: components['schemas']['AccountingModelEnum'] | null;
      /** Business Contact Name */
      business_contact_name?: string | null;
      /** Business Contact Phone */
      business_contact_phone?: string | null;
      /** Business Contact Email */
      business_contact_email?: string | null;
      /** Business Contact Dob */
      business_contact_dob?: string | null;
      /** Business Contact Title */
      business_contact_title?: string | null;
      /** Business Contact Address 1 */
      business_contact_address_1?: string | null;
      /** Business Contact Address 2 */
      business_contact_address_2?: string | null;
      /** Business Contact City */
      business_contact_city?: string | null;
      /** Business Contact State */
      business_contact_state?: string | null;
      /** Business Contact Postal Code */
      business_contact_postal_code?: string | null;
      business_contact_country_code?: components['schemas']['CountryCodeEnum'] | null;
      /** Business Contact Ssn */
      business_contact_ssn?: string | null;
      /** Business Contact Dl */
      business_contact_dl?: string | null;
      /** Business Owner1 Name */
      business_owner1_name?: string | null;
      /** Business Owner1 Phone */
      business_owner1_phone?: string | null;
      /** Business Owner1 Email */
      business_owner1_email?: string | null;
      /** Business Owner1 Dob */
      business_owner1_dob?: string | null;
      /** Business Owner1 Title */
      business_owner1_title?: string | null;
      /** Business Owner1 Address 1 */
      business_owner1_address_1?: string | null;
      /** Business Owner1 Address 2 */
      business_owner1_address_2?: string | null;
      /** Business Owner1 City */
      business_owner1_city?: string | null;
      /** Business Owner1 State */
      business_owner1_state?: string | null;
      /** Business Owner1 Postal Code */
      business_owner1_postal_code?: string | null;
      business_owner1_country_code?: components['schemas']['CountryCodeEnum'] | null;
      /** Business Owner1 Ssn */
      business_owner1_ssn?: string | null;
      /** Business Owner1 Dl */
      business_owner1_dl?: string | null;
      /** Business Owner1 Percent Ownership */
      business_owner1_percent_ownership?: string | null;
      /** Business Owner2 Name */
      business_owner2_name?: string | null;
      /** Business Owner2 Phone */
      business_owner2_phone?: string | null;
      /** Business Owner2 Email */
      business_owner2_email?: string | null;
      /** Business Owner2 Dob */
      business_owner2_dob?: string | null;
      /** Business Owner2 Title */
      business_owner2_title?: string | null;
      /** Business Owner2 Address 1 */
      business_owner2_address_1?: string | null;
      /** Business Owner2 Address 2 */
      business_owner2_address_2?: string | null;
      /** Business Owner2 City */
      business_owner2_city?: string | null;
      /** Business Owner2 State */
      business_owner2_state?: string | null;
      /** Business Owner2 Postal Code */
      business_owner2_postal_code?: string | null;
      business_owner2_country_code?: components['schemas']['CountryCodeEnum'] | null;
      /** Business Owner2 Ssn */
      business_owner2_ssn?: string | null;
      /** Business Owner2 Dl */
      business_owner2_dl?: string | null;
      /** Business Owner2 Percent Ownership */
      business_owner2_percent_ownership?: string | null;
      /** Business Owner3 Name */
      business_owner3_name?: string | null;
      /** Business Owner3 Phone */
      business_owner3_phone?: string | null;
      /** Business Owner3 Email */
      business_owner3_email?: string | null;
      /** Business Owner3 Dob */
      business_owner3_dob?: string | null;
      /** Business Owner3 Title */
      business_owner3_title?: string | null;
      /** Business Owner3 Address 1 */
      business_owner3_address_1?: string | null;
      /** Business Owner3 Address 2 */
      business_owner3_address_2?: string | null;
      /** Business Owner3 City */
      business_owner3_city?: string | null;
      /** Business Owner3 State */
      business_owner3_state?: string | null;
      /** Business Owner3 Postal Code */
      business_owner3_postal_code?: string | null;
      business_owner3_country_code?: components['schemas']['CountryCodeEnum'] | null;
      /** Business Owner3 Ssn */
      business_owner3_ssn?: string | null;
      /** Business Owner3 Dl */
      business_owner3_dl?: string | null;
      /** Business Owner3 Percent Ownership */
      business_owner3_percent_ownership?: string | null;
      /** Id */
      id: string;
    };
    /** OrganizationRead */
    OrganizationRead: {
      /** Name */
      name: string | null;
      /** External Id */
      external_id: string;
      /** @default ACTIVE */
      status: components['schemas']['OrganizationStatusEnum'];
      /**
       * Is Test
       * @default true
       */
      is_test: boolean;
      /** @default FREE */
      billing_plan: components['schemas']['BillingPlanEnum'];
      source: components['schemas']['OrganizationSourceEnum'] | null;
      /** Promo */
      promo: string | null;
      /**
       * Disable Emails
       * @default false
       */
      disable_emails: boolean;
      /** Customer Id */
      customer_id?: string | null;
      /** Subscription Id */
      subscription_id?: string | null;
      /** Id */
      id: string;
    };
    /**
     * OrganizationSourceEnum
     * @enum {string}
     */
    OrganizationSourceEnum: OrganizationSourceEnum;
    /**
     * OrganizationStatusEnum
     * @enum {string}
     */
    OrganizationStatusEnum: OrganizationStatusEnum;
    /** OrganizationUpdate */
    OrganizationUpdate: {
      /** Name */
      name: string | null;
      /** Phone */
      phone: string | null;
      /** Street 1 */
      street_1: string | null;
      /** Street 2 */
      street_2: string | null;
      /** City */
      city: string | null;
      /** State */
      state: string | null;
      /** Postal Code */
      postal_code: string | null;
      /** Country */
      country: string | null;
      source: components['schemas']['OrganizationSourceEnum'] | null;
      /** Promo */
      promo: string | null;
    };
    /** Page[AttachmentRead] */
    Page_AttachmentRead_: {
      /** Items */
      items: components['schemas']['AttachmentRead'][];
      /** Total */
      total: number | null;
      /** Page */
      page: number | null;
      /** Size */
      size: number | null;
      /** Pages */
      pages?: number | null;
    };
    /** Page[ConnectionRead] */
    Page_ConnectionRead_: {
      /** Items */
      items: components['schemas']['ConnectionRead'][];
      /** Total */
      total: number | null;
      /** Page */
      page: number | null;
      /** Size */
      size: number | null;
      /** Pages */
      pages?: number | null;
    };
    /** Page[CustomerRead] */
    Page_CustomerRead_: {
      /** Items */
      items: components['schemas']['CustomerRead'][];
      /** Total */
      total: number | null;
      /** Page */
      page: number | null;
      /** Size */
      size: number | null;
      /** Pages */
      pages?: number | null;
    };
    /** Page[FilingRead] */
    Page_FilingRead_: {
      /** Items */
      items: components['schemas']['FilingRead'][];
      /** Total */
      total: number | null;
      /** Page */
      page: number | null;
      /** Size */
      size: number | null;
      /** Pages */
      pages?: number | null;
    };
    /** Page[OrganizationRead] */
    Page_OrganizationRead_: {
      /** Items */
      items: components['schemas']['OrganizationRead'][];
      /** Total */
      total: number | null;
      /** Page */
      page: number | null;
      /** Size */
      size: number | null;
      /** Pages */
      pages?: number | null;
    };
    /** Page[PhysicalNexusRead] */
    Page_PhysicalNexusRead_: {
      /** Items */
      items: components['schemas']['PhysicalNexusRead'][];
      /** Total */
      total: number | null;
      /** Page */
      page: number | null;
      /** Size */
      size: number | null;
      /** Pages */
      pages?: number | null;
    };
    /** Page[ProductRead] */
    Page_ProductRead_: {
      /** Items */
      items: components['schemas']['ProductRead'][];
      /** Total */
      total: number | null;
      /** Page */
      page: number | null;
      /** Size */
      size: number | null;
      /** Pages */
      pages?: number | null;
    };
    /** Page[RegistrationReadWithPassword] */
    Page_RegistrationReadWithPassword_: {
      /** Items */
      items: components['schemas']['RegistrationReadWithPassword'][];
      /** Total */
      total: number | null;
      /** Page */
      page: number | null;
      /** Size */
      size: number | null;
      /** Pages */
      pages?: number | null;
    };
    /** Page[RuleRead] */
    Page_RuleRead_: {
      /** Items */
      items: components['schemas']['RuleRead'][];
      /** Total */
      total: number | null;
      /** Page */
      page: number | null;
      /** Size */
      size: number | null;
      /** Pages */
      pages?: number | null;
    };
    /** Page[TransactionAddressRead] */
    Page_TransactionAddressRead_: {
      /** Items */
      items: components['schemas']['TransactionAddressRead'][];
      /** Total */
      total: number | null;
      /** Page */
      page: number | null;
      /** Size */
      size: number | null;
      /** Pages */
      pages?: number | null;
    };
    /** Page[TransactionRead] */
    Page_TransactionRead_: {
      /** Items */
      items: components['schemas']['TransactionRead'][];
      /** Total */
      total: number | null;
      /** Page */
      page: number | null;
      /** Size */
      size: number | null;
      /** Pages */
      pages?: number | null;
    };
    /** PaymentMethod */
    PaymentMethod: {
      /** Object */
      object: string;
      /** Type */
      type: string;
      /** Reference Id */
      reference_id: string;
      /** Gateway */
      gateway: string;
      /** Gateway Account Id */
      gateway_account_id: string;
      /** Status */
      status: string;
    };
    /** PhysicalNexusCreate */
    PhysicalNexusCreate: {
      country_code: components['schemas']['CountryCodeEnum'];
      /** State Code */
      state_code: string;
      /**
       * Start Date
       * Format: date
       */
      start_date: string;
      /** End Date */
      end_date?: string | null;
    };
    /** PhysicalNexusRead */
    PhysicalNexusRead: {
      country_code: components['schemas']['CountryCodeEnum'];
      /** State Code */
      state_code: string;
      /**
       * Start Date
       * Format: date
       */
      start_date: string;
      /** End Date */
      end_date?: string;
      /** Id */
      id: string;
      /** Category */
      category: PhysicalNexusCategoryEnum;
    };
    /** PhysicalNexusUpdate */
    PhysicalNexusUpdate: {
      /**
       * Start Date
       * Format: date
       */
      start_date: string;
      /** End Date */
      end_date: string | null;
    };
    /** PortalSessionHostedPage */
    PortalSessionHostedPage: {
      /** Access Url */
      access_url: string;
      /** Created At */
      created_at: number;
      /** Customer Id */
      customer_id: string;
      /** Expires At */
      expires_at: number;
      /** Id */
      id: string;
      /** Linked Customers */
      linked_customers: components['schemas']['LinkedCustomer'][];
      /** Object */
      object: string;
      /** Redirect Url */
      redirect_url?: string | null;
      /** Status */
      status: string;
      /** Token */
      token: string;
    };
    /**
     * ProcessingStatusEnum
     * @description Our transaction state, used to determine when/if a transaction needs additional
     *     processing.
     * @enum {string}
     */
    ProcessingStatusEnum: ProcessingStatusEnum;
    /** ProductCatCreateUpdate */
    ProductCatCreateUpdate: {
      /** Name */
      name: string;
      /** Description */
      description: string;
      product_category: components['schemas']['ProductCategoryEnum'];
      product_subcategory: components['schemas']['ProductSubCategoryEnum'];
      product_code: components['schemas']['ProductCodeEnum'];
    };
    /** ProductCatRead */
    ProductCatRead: {
      /** Id */
      id?: string;
      /**
       * Created At
       * Format: date-time
       */
      created_at?: string;
      /** Updated At */
      updated_at?: string | null;
      /** Name */
      name?: string;
      /** Description */
      description?: string;
      product_category?: components['schemas']['ProductCategoryEnum'];
      product_subcategory?: components['schemas']['ProductSubCategoryEnum'];
      product_code?: components['schemas']['ProductCodeEnum'];
    };
    /**
     * ProductCategoryEnum
     * @enum {string}
     */
    ProductCategoryEnum: ProductCategoryEnum;
    /** ProductCategoryRead */
    ProductCategoryRead: {
      /** Name */
      name: string;
      /** Description */
      description: string;
      /** Subcategories */
      subcategories: components['schemas']['ProductSubCategoryRead'][];
    };
    /**
     * ProductCodeEnum
     * @enum {string}
     */
    ProductCodeEnum: ProductCodeEnum;
    /** ProductConfigCreateUpdate */
    ProductConfigCreateUpdate: {
      primary_product_category: components['schemas']['ProductCategoryEnum'] | null;
      primary_product_subcategory: components['schemas']['ProductSubCategoryEnum'] | null;
      /**
       * Ai Enabled
       * @default false
       */
      ai_enabled: boolean;
    };
    /** ProductConfigRead */
    ProductConfigRead: {
      primary_product_category: components['schemas']['ProductCategoryEnum'] | null;
      primary_product_subcategory: components['schemas']['ProductSubCategoryEnum'] | null;
      /**
       * Ai Enabled
       * @default false
       */
      ai_enabled: boolean;
    };
    /** ProductRead */
    ProductRead: {
      /** External Id */
      external_id: string;
      code: components['schemas']['ProductCodeEnum'];
      /** Name */
      name: string;
      /** Description */
      description: string | null;
      status: components['schemas']['ProductStatusEnum'];
      product_category: components['schemas']['ProductCategoryEnum'];
      product_subcategory: components['schemas']['ProductSubCategoryEnum'];
      /** Tax Exempt */
      tax_exempt: boolean;
      source: components['schemas']['SourceEnum'];
      /** Id */
      id: string;
    };
    /**
     * ProductStatusEnum
     * @enum {string}
     */
    ProductStatusEnum: ProductStatusEnum;
    /**
     * ProductSubCategoryEnum
     * @enum {string}
     */
    ProductSubCategoryEnum: ProductSubCategoryEnum;
    /** ProductSubCategoryRead */
    ProductSubCategoryRead: {
      /** Name */
      name: string;
      /** Description */
      description: string;
    };
    /** ProductUpdate */
    ProductUpdate: {
      /** Name */
      name: string;
      /** Description */
      description: string | null;
      status: components['schemas']['ProductStatusEnum'];
      product_category: components['schemas']['ProductCategoryEnum'];
      product_subcategory: components['schemas']['ProductSubCategoryEnum'];
      /** Tax Exempt */
      tax_exempt: boolean;
    };
    /** RawImportData */
    RawImportData: {
      /** File Name */
      file_name: string;
      /** Data */
      data: string;
    };
    /** RegistrationCreate */
    RegistrationCreate: {
      /** Registration Date */
      registration_date?: string | null;
      /** Registration Email */
      registration_email?: string | null;
      status: components['schemas']['RegistrationStatusEnum'];
      /** Registration Key */
      registration_key?: string | null;
      /** Deregistration Key */
      deregistration_key?: string | null;
      country_code: components['schemas']['CountryCodeEnum'];
      /** State Code */
      state_code: string;
      /** State Name */
      state_name: string;
      filing_frequency: components['schemas']['FilingFrequencyEnum'];
      /** Filing Days */
      filing_days: number;
      /** Is Manual */
      is_manual?: boolean | null;
      /** Username */
      username?: string | null;
      /** Password Plain Text */
      password_plain_text?: string | null;
      /** Password Metadata Plain Text */
      password_metadata_plain_text?: string | null;
    };
    /** RegistrationRead */
    RegistrationRead: {
      /** Registration Date */
      registration_date?: string | null;
      /** Registration Email */
      registration_email?: string | null;
      status: components['schemas']['RegistrationStatusEnum'];
      /** Registration Key */
      registration_key?: string | null;
      /** Deregistration Key */
      deregistration_key?: string | null;
      country_code: components['schemas']['CountryCodeEnum'];
      /** State Code */
      state_code: string;
      /** State Name */
      state_name: string;
      filing_frequency: components['schemas']['FilingFrequencyEnum'];
      /** Filing Days */
      filing_days: number;
      /** Is Manual */
      is_manual?: boolean | null;
      /** Username */
      username?: string | null;
      /** Id */
      id: string;
    };
    /** RegistrationReadWithPassword */
    RegistrationReadWithPassword: {
      /** Registration Date */
      registration_date?: string | null;
      /** Registration Email */
      registration_email?: string | null;
      status: components['schemas']['RegistrationStatusEnum'];
      /** Registration Key */
      registration_key?: string | null;
      /** Deregistration Key */
      deregistration_key?: string | null;
      country_code: components['schemas']['CountryCodeEnum'];
      /** State Code */
      state_code: string;
      /** State Name */
      state_name: string;
      filing_frequency: components['schemas']['FilingFrequencyEnum'];
      /** Filing Days */
      filing_days: number;
      /** Is Manual */
      is_manual?: boolean | null;
      /** Username */
      username?: string | null;
      /** Id */
      id: string;
      /** Password Encrypted */
      password_encrypted?: string | null;
      /**
       * Has Username Password
       * @default false
       */
      has_all_credentials: boolean;
    };
    /**
     * RegistrationStatusEnum
     * @enum {string}
     */
    RegistrationStatusEnum: RegistrationStatusEnum;
    /** RegistrationUpdate */
    RegistrationUpdate: {
      /** Registration Date */
      registration_date?: string | null;
      /** Registration Email */
      registration_email?: string | null;
      status: components['schemas']['RegistrationStatusEnum'];
      /** Registration Key */
      registration_key?: string | null;
      /** Deregistration Key */
      deregistration_key?: string | null;
      /** Username */
      username?: string | null;
      /** Password Plain Text */
      password_plain_text?: string | null;
      /** Password Metadata Plain Text */
      password_metadata_plain_text?: string | null;
      filing_frequency?: components['schemas']['FilingFrequencyEnum'] | null;
    };
    /**
     * RelatedEntityType
     * @enum {string}
     */
    RelatedEntityType: RelatedEntityType;
    /** RuleRead */
    RuleRead: {
      /** Id */
      id: string;
      /** Postal Code */
      postal_code: string;
      /** City */
      city: string;
      /** State */
      state: string;
      /** @default US */
      country: components['schemas']['CountryCodeEnum'];
      /**
       * County
       * @default
       */
      county: string | null;
      /** Combined Rate */
      combined_rate: string;
      /** State Rate */
      state_rate: string;
      /** County Rate */
      county_rate: string;
      /** City Rate */
      city_rate: string;
      /** Special Rate */
      special_rate: string;
      /**
       * Threshold
       * @default 0.0
       */
      threshold: string;
      /** @default FULL_AMOUNT */
      treatment: components['schemas']['TaxTreatment'];
      product_code: components['schemas']['ProductCodeEnum'];
      /**
       * Start Date
       * Format: date
       */
      start_date: string;
      /** End Date */
      end_date: string | null;
      /** Taxable Rate */
      taxable_rate: string;
    };
    /**
     * Seller
     * @description The details of the seller involved in the transaction
     *     including company code and address
     */
    Seller: {
      /** @description Represents the address used for validation. */
      address: components['schemas']['ValidationAddressBase'];
      /**
       * Taxregistrationnumber
       * @description The tax registration number of a business in a country. For example, this is the GSTIN for India or the VAT number for EU or Australia.
       */
      taxRegistrationNumber?: string | null;
      /**
       * Hasnexus
       * @description Determines whether a tax nexus exists between the Seller and the tax authority at the address provided.
       */
      hasNexus?: boolean | null;
    };
    /** ShopifyCreateUpdate */
    ShopifyCreateUpdate: {
      /** Url */
      url: string;
      /** Secret */
      secret: string;
    };
    /**
     * SourceEnum
     * @enum {string}
     */
    SourceEnum: SourceEnum;
    /**
     * StatusEnum
     * @enum {string}
     */
    StatusEnum: StatusEnum;
    /** StripeReadUpdate */
    StripeReadUpdate: {
      /** Api Key */
      api_key: string;
    };
    /** Subscription */
    Subscription: {
      /** Id */
      id: string;
      /** Billing Period */
      billing_period: number;
      /** Billing Period Unit */
      billing_period_unit: string;
      /** Customer Id */
      customer_id: string;
      /** Status */
      status: string;
      /** Current Term Start */
      current_term_start?: number | null;
      /** Current Term End */
      current_term_end?: number | null;
      /** Next Billing At */
      next_billing_at?: number | null;
      /** Created At */
      created_at?: number | null;
      /** Started At */
      started_at?: number | null;
      /** Activated At */
      activated_at?: number | null;
      /** Created From Ip */
      created_from_ip?: string | null;
      /** Updated At */
      updated_at?: number | null;
      /** Has Scheduled Changes */
      has_scheduled_changes: boolean;
      /** Channel */
      channel: string;
      /** Resource Version */
      resource_version: number;
      /** Deleted */
      deleted: boolean;
      /** Object */
      object: string;
      /** Currency Code */
      currency_code: string;
      /** Subscription Items */
      subscription_items: components['schemas']['SubscriptionItem'][];
      shipping_address?: components['schemas']['Address-Output'] | null;
      /** Due Invoices Count */
      due_invoices_count: number;
      /** Mrr */
      mrr?: number | null;
      /** Cf Orgid */
      cf_orgId?: string | null;
      /** Has Scheduled Advance Invoices */
      has_scheduled_advance_invoices: boolean;
      /** Create Pending Invoices */
      create_pending_invoices: boolean;
      /** Auto Close Invoices */
      auto_close_invoices: boolean;
    };
    /** SubscriptionItem */
    SubscriptionItem: {
      /** Item Price Id */
      item_price_id: string;
      /** Item Type */
      item_type: string;
      /** Quantity */
      quantity?: number | null;
      /** Unit Price */
      unit_price: number;
      /** Amount */
      amount?: number | null;
      /** Free Quantity */
      free_quantity?: number | null;
      /** Object */
      object: string;
      /** Metered Quantity */
      metered_quantity?: string | null;
    };
    /**
     * TaxEstimateLineItem
     * @description Represents the details of a line item in a tax estimation request.
     */
    TaxEstimateLineItem: {
      /**
       * Number
       * @description Index or serial number of the line item.
       */
      number: number;
      /**
       * Itemcode
       * @description The unique identifier (in Chargebee) of the product correspondingto the line item. If the line item corresponds to a one-off charge,then this identifier is not present.
       */
      itemCode?: string | null;
      /**
       * Description
       * @description The description of the line item.
       */
      description?: string | null;
      /**
       * Quantity
       * @description The quantity associated with this line item.
       */
      quantity: number;
      /**
       * Unitprice
       * @description The unit price for this line item. In case of tiered pricingwhere the unit price varies for each quantity tier, this is the average unit price.
       */
      unitPrice?: number | null;
      /**
       * Amount
       * @description The amount for this line item. This is unitPrice*quantity.
       */
      amount: number;
      /**
       * Discountamount
       * @description The discount applied to this line item.
       */
      discountAmount?: number | null;
      /**
       * Istaxinclusive
       * @description Indicates whether (amount - discountAmount) is inclusive of taxes.
       */
      isTaxInclusive: boolean;
      /**
       * Taxidentifiers
       * @description The tax code fields of the product used for tax calculation.
       */
      taxIdentifiers?: components['schemas']['TaxIdentifier'][] | null;
      /**
       * Subtotal
       * @description The amount after discounts for this line item. This is amount - discountAmount.
       */
      subtotal: number;
      /**
       * Istaxable
       * @description Indicates whether this line item is taxable.
       */
      isTaxable: boolean;
      /** @description The tax exemption type for a line item. This is a mandatory parameter while applying tax exemption on any line-item. */
      taxExemptType?: components['schemas']['TaxExemptType'] | null;
      /**
       * Taxexemptreason
       * @description The reason due to which a line item is exempted from tax. This is a mandatory parameter while applying tax exemption on any line-item.
       */
      taxExemptReason?: string | null;
      /**
       * Exemptamount
       * @description The part of this line item's subtotal that is exempted from tax.
       */
      exemptAmount: number;
      /**
       * Taxableamount
       * @description The part of this line item's subtotal that is taxable.
       */
      taxableAmount: number;
      /**
       * Taxamount
       * @description The tax payable for this line item. This is the sum of all taxes.taxAmount for this line item.
       */
      taxAmount: number;
      /**
       * Total
       * @description The total for this line item after discounts and taxes. This is the same as subtotal if it is tax inclusive; otherwise it is subtotal + taxAmount. total can also be expressed as exemptAmount + taxableAmount + taxAmount.
       */
      total: number;
      /**
       * Ispartialtax
       * @description Indicates if taxes were applied only partially for this line item.
       */
      isPartialTax?: boolean | null;
      /**
       * Taxes
       * @description List of taxes applied for this line item under each jurisdiction.
       */
      taxes: components['schemas']['TaxLineItem-Output'][];
    };
    /** TaxEstimateLineItemBase */
    TaxEstimateLineItemBase: {
      /**
       * Number
       * @description Index or serial number of the line item.
       */
      number: number;
      /**
       * Itemcode
       * @description The unique identifier (in Chargebee) of the product correspondingto the line item. If the line item corresponds to a one-off charge,then this identifier is not present.
       */
      itemCode?: string | null;
      /**
       * Description
       * @description The description of the line item.
       */
      description?: string | null;
      /**
       * Quantity
       * @description The quantity associated with this line item.
       */
      quantity: number;
      /**
       * Unitprice
       * @description The unit price for this line item. In case of tiered pricingwhere the unit price varies for each quantity tier, this is the average unit price.
       */
      unitPrice?: number | null;
      /**
       * Amount
       * @description The amount for this line item. This is unitPrice*quantity.
       */
      amount: number;
      /**
       * Discountamount
       * @description The discount applied to this line item.
       */
      discountAmount?: number | null;
      /**
       * Istaxinclusive
       * @description Indicates whether (amount - discountAmount) is inclusive of taxes.
       */
      isTaxInclusive: boolean;
      /**
       * Taxidentifiers
       * @description The tax code fields of the product used for tax calculation.
       */
      taxIdentifiers?: components['schemas']['TaxIdentifier'][] | null;
    };
    /**
     * TaxExemptType
     * @description The tax exemption type for a line item.
     *     This is a mandatory parameter while applying tax exemption on any line-item
     * @enum {string}
     */
    TaxExemptType: TaxExemptType;
    /**
     * TaxExemptionEnum
     * @description This enum is used to determine if a transaction is exempt from tax.
     * @enum {string}
     */
    TaxExemptionEnum: TaxExemptionEnum;
    /**
     * TaxIdentifier
     * @description It represents the information related to the customer's tax identifiers.
     *     This includes details such as exemption status etc.
     */
    TaxIdentifier: {
      /**
       * Id
       * @description The id of the field.
       */
      id: string;
      /**
       * Value
       * @description The value of the field.
       */
      value: string;
    };
    /** TaxItemEstimate */
    TaxItemEstimate: {
      /**
       * The tax rate for this this jurisdiction and tax type.
       * @default 0.0
       */
      rate: string;
      /** The name of the tax item for this jurisdiction and tax type. ie. 'State Tax' */
      name: string;
      /**
       * The amount of tax determined for this jurisdiction and tax type.
       * @default 0.0
       */
      amount: string;
      /**
       * True if this item is tax exempt.
       * @default false
       */
      exempt: boolean;
      /** The reason why we determined the tax item is exempt. */
      exempt_reason?: components['schemas']['TaxItemReturnReasonEnum'] | null;
      /** The rule id that was used to determine the tax. */
      rule?: string | null;
    };
    /** TaxItemRead */
    TaxItemRead: {
      /**
       * Rule Id
       * @default 0000
       */
      rule_id: string;
      /** Rate */
      rate: string;
      /** Amount */
      amount: string;
      /** External Id */
      external_id?: string | null;
      /**
       * Name
       * @description Deprecated: use `jurisdiction_type` instead
       */
      name: string;
      /** @default IMPORTED */
      type: components['schemas']['TaxItemTypeEnum'];
      jurisdiction_type?: components['schemas']['JurisdictionType'] | null;
      /** Jurisdiction Name */
      jurisdiction_name?: string | null;
    };
    /**
     * TaxItemReturnReasonEnum
     * @description We use this to understand the response from get_tax_items
     * @enum {string}
     */
    TaxItemReturnReasonEnum: TaxItemReturnReasonEnum;
    /**
     * TaxItemTypeEnum
     * @enum {string}
     */
    TaxItemTypeEnum: TaxItemTypeEnum;
    /**
     * TaxJurisdictionType
     * @description The type of tax jurisdiction
     * @enum {string}
     */
    TaxJurisdictionType: TaxJurisdictionType;
    /**
     * TaxLineItem
     * @description List of taxes applied for this line item under each jurisdiction
     */
    'TaxLineItem-Input': {
      /**
       * Number
       * @description Index or serial number of this tax line item.
       */
      number: number;
      /** @description The tax jurisdiction details. */
      jurisdiction: components['schemas']['Jurisdiction'];
      /**
       * Name
       * @description The name of the tax applied.
       */
      name: string;
      /**
       * Rate
       * @description The tax rate expressed in percentage.
       */
      rate: number;
      /**
       * Taxableamount
       * @description The part of the line item's subtotal that is taxableunder this jurisdiction.
       */
      taxableAmount: number;
      /**
       * Taxamount
       * @description The tax payable for the line item under this jurisdiction.
       */
      taxAmount: number;
    };
    /**
     * TaxLineItem
     * @description List of taxes applied for this line item under each jurisdiction
     */
    'TaxLineItem-Output': {
      /**
       * Number
       * @description Index or serial number of this tax line item.
       */
      number: number;
      /** @description The tax jurisdiction details. */
      jurisdiction: components['schemas']['Jurisdiction'];
      /**
       * Name
       * @description The name of the tax applied.
       */
      name: string;
      /**
       * Rate
       * @description The tax rate expressed in percentage.
       */
      rate: number;
      /**
       * Taxableamount
       * @description The part of the line item's subtotal that is taxableunder this jurisdiction.
       */
      taxableAmount: number;
      /**
       * Taxamount
       * @description The tax payable for the line item under this jurisdiction.
       */
      taxAmount: number;
    };
    /**
     * TaxTreatment
     * @enum {string}
     */
    TaxTreatment: TaxTreatment;
    /** TransactionAddress */
    TransactionAddress: {
      /** Phone */
      phone?: string | null;
      /** Street 1 */
      street_1?: string | null;
      /** Street 2 */
      street_2?: string | null;
      /** City */
      city?: string | null;
      /** County */
      county?: string | null;
      /** State */
      state?: string | null;
      /** Postal Code */
      postal_code?: string | null;
      country?: components['schemas']['CountryCodeEnum'] | null;
      type: components['schemas']['AddressType'];
      /** @default UNVERIFIED */
      status: components['schemas']['AddressStatus'];
    };
    /** TransactionAddressBuilder */
    TransactionAddressBuilder: {
      /** Phone */
      phone?: string | null;
      /** Street 1 */
      street_1?: string | null;
      /** Street 2 */
      street_2?: string | null;
      /** City */
      city?: string | null;
      /** County */
      county?: string | null;
      /** State */
      state?: string | null;
      /** Postal Code */
      postal_code?: string | null;
      country?: components['schemas']['CountryCodeEnum'] | null;
      type: components['schemas']['AddressType'];
      /** @default UNVERIFIED */
      status: components['schemas']['AddressStatus'];
      /** Organization Id */
      organization_id?: string | null;
    };
    /** TransactionAddressRead */
    TransactionAddressRead: {
      /** Phone */
      phone?: string;
      /** Street 1 */
      street_1?: string;
      /** Street 2 */
      street_2?: string;
      /** City */
      city?: string;
      /** County */
      county?: string;
      /** State */
      state?: string;
      /** Postal Code */
      postal_code?: string;
      country?: components['schemas']['CountryCodeEnum'];
      type: components['schemas']['AddressType'];
      /** @default UNVERIFIED */
      status: components['schemas']['AddressStatus'];
      /** Id */
      id: string;
      /** Transaction Id */
      transaction_id: string;
    };
    /** TransactionAddressUpdate */
    TransactionAddressUpdate: {
      /** Phone */
      phone?: string | null;
      /** Street 1 */
      street_1?: string | null;
      /** Street 2 */
      street_2?: string | null;
      /** City */
      city?: string | null;
      /** County */
      county?: string | null;
      /** State */
      state?: string | null;
      /** Postal Code */
      postal_code?: string | null;
      country?: components['schemas']['CountryCodeEnum'] | null;
      type: components['schemas']['AddressType'];
      /** @default UNVERIFIED */
      status: components['schemas']['AddressStatus'];
      /** Id */
      id: string;
      /** Transaction Id */
      transaction_id: string | null;
    };
    /** TransactionCreate */
    TransactionCreate: {
      status: components['schemas']['TransactionStatusEnum'];
      /**
       * Date
       * Format: date-time
       */
      date: string;
      /** External Id */
      external_id: string;
      currency: components['schemas']['CurrencyEnum'];
      customer?: components['schemas']['CustomerCreate'] | null;
      /** Description */
      description: string | null;
      /** Addresses */
      addresses: components['schemas']['TransactionAddressBuilder'][] | components['schemas']['Address-Input'][];
      /** @default UNVERIFIED */
      address_status: components['schemas']['AddressStatus'];
      /** Transaction Items */
      transaction_items: components['schemas']['TransactionItemCreateUpdate'][];
      /** Organization Id */
      organization_id: string;
      source: components['schemas']['SourceEnum'];
      /** Connection Id */
      connection_id?: string | null;
    };
    /** TransactionEstimateRequest */
    TransactionEstimateRequest: {
      /**
       * Date of this transaction in ISO 8601 format.
       * Format: date-time
       */
      date: string;
      /** Unique identifier of this transaction in the source system. */
      external_id: string;
      /** Total amount of the transaction, after discounts. */
      total_amount: number | string;
      /** Currency of the transaction in ISO 4217 format. */
      currency: components['schemas']['CurrencyEnum'];
      /** A description for this transaction. */
      description?: string | null;
      /**
       * The system from which the transaction originated.
       * @description While currently not used, it may be in the future to determine taxability.
       * @default UNKNOWN
       */
      source: components['schemas']['SourceEnum'];
      /**
       * Use to indicate if this transaction was placed through a Marketplace, which will mean that the item is not taxable.
       * @default false
       */
      marketplace: boolean | null;
      /**
       * The customer who placed the transaction.
       * @description If the customer is not found, it will be ignored.
       */
      customer?: components['schemas']['CustomerBase'] | null;
      /**
       * The address information for this transaction.
       * @description At least one address of type BILL_TO or SHIP_TO must be provided. The address will be validated during estimation, and the transaction may be rejected if the address does not pass validation. The SHIP_TO will be preferred to use for determining tax liability.
       */
      addresses: components['schemas']['TransactionAddress'][];
      /** The items that make up the transaction. */
      transaction_items: components['schemas']['TransactionItemEstimateBase'][];
    };
    /** TransactionEstimateResponse */
    TransactionEstimateResponse: {
      /**
       * Date of this transaction in ISO 8601 format.
       * Format: date-time
       */
      date: string;
      /** Unique identifier of this transaction in the source system. */
      external_id: string;
      /** Total amount of the transaction, after discounts. */
      total_amount: string;
      /** Currency of the transaction in ISO 4217 format. */
      currency: components['schemas']['CurrencyEnum'];
      /** A description for this transaction. */
      description?: string | null;
      /**
       * The system from which the transaction originated.
       * @description While currently not used, it may be in the future to determine taxability.
       * @default UNKNOWN
       */
      source: components['schemas']['SourceEnum'];
      /**
       * Use to indicate if this transaction was placed through a Marketplace, which will mean that the item is not taxable.
       * @default false
       */
      marketplace: boolean | null;
      /**
       * The customer who placed the transaction.
       * @description If the customer is not found, it will be ignored.
       */
      customer?: components['schemas']['CustomerBase'] | null;
      /**
       * The address information for this transaction.
       * @description At least one address of type BILL_TO or SHIP_TO must be provided. The address will be validated during estimation, and the transaction may be rejected if the address does not pass validation. The SHIP_TO will be preferred to use for determining tax liability.
       */
      addresses: components['schemas']['TransactionAddress'][];
      /** Transaction Items */
      transaction_items: components['schemas']['TransactionItemEstimateResponse'][];
      /**
       * Total Tax Amount Calculated
       * @default 0.00
       */
      total_tax_amount_calculated: string;
      /**
       * Taxable Amount
       * @default 0.00
       */
      taxable_amount: string;
      /**
       * Tax Rate Calculated
       * @default 0.00
       */
      tax_rate_calculated: string;
      /**
       * Nexus Met
       * @default false
       */
      nexus_met: boolean;
    };
    /**
     * TransactionExemptStatusEnum
     * @description Based on transaction item exempt status.
     *     EXEMPT: All items sold in the transaction are EXEMPT
     *     PARTIALLY EXEMPT: At least some of the items are NOT EXEMPT
     *     NOT EXEMPT: None of the items are NOT EXEMPT
     * @enum {string}
     */
    TransactionExemptStatusEnum: TransactionExemptStatusEnum;
    /** TransactionIdKey */
    TransactionIdKey: {
      /** External Id */
      external_id: string;
      /** Organization Id */
      organization_id: string;
      source: components['schemas']['SourceEnum'];
      /** Connection Id */
      connection_id?: string | null;
    };
    /** TransactionImport */
    TransactionImport: {
      /** External Id */
      external_id: string;
      status: components['schemas']['TransactionStatusEnum'];
      /**
       * Date
       * Format: date-time
       */
      date: string;
      currency: components['schemas']['CurrencyEnum'];
      /** Description */
      description: string | null;
      customer: components['schemas']['Customer-Input'];
      /** Addresses */
      addresses:
        | components['schemas']['TransactionAddressBuilder'][]
        | components['schemas']['Address-Input'][]
        | components['schemas']['TransactionAddress'][];
      /** @default UNVERIFIED */
      address_status: components['schemas']['AddressStatus'];
      /** Total Amount */
      total_amount: number | string;
      /**
       * Total Tax Amount Imported
       * @default 0.00
       */
      total_tax_amount_imported: number | string;
      /**
       * Tax Rate Imported
       * @default 0.00
       */
      tax_rate_imported: number | string;
      /**
       * Taxable Amount
       * @default 0.00
       */
      taxable_amount: number | string;
      /** Transaction Items */
      transaction_items: components['schemas']['TransactionItemImport'][];
      /** Connection Id */
      connection_id: string | null;
      /** @default UNKNOWN */
      source: components['schemas']['SourceEnum'];
      /**
       * Marketplace
       * @default false
       */
      marketplace: boolean | null;
      /** Related To */
      related_to?: string | null;
      /** @default SALE */
      type: components['schemas']['TransactionTypeEnum'] | null;
      /** @default IMPORT */
      operation: components['schemas']['ImportOperationEnum'] | null;
      /** Organization Id */
      organization_id: string;
    };
    /** TransactionImportResponse */
    TransactionImportResponse: {
      /** Created Count */
      created_count: number;
      /** Created Items */
      created_items: components['schemas']['TransactionIdKey'][];
      /** Skipped Count */
      skipped_count: number;
      /** Skipped Items */
      skipped_items: components['schemas']['TransactionIdKey'][];
      /** Updated Count */
      updated_count: number;
      /** Updated Items */
      updated_items: components['schemas']['TransactionIdKey'][];
      /** Existing Count */
      existing_count: number;
      /** Existing Items */
      existing_items: components['schemas']['TransactionIdKey'][];
      /** Triggered By */
      triggered_by: string;
    };
    /** TransactionItemCreateUpdate */
    TransactionItemCreateUpdate: {
      /** External Id */
      external_id: string | null;
      /**
       * Date
       * Format: date-time
       */
      date: string;
      /** Description */
      description: string | null;
      /** Product */
      product: string | null;
      /** External Product Id */
      external_product_id: string;
      /** Quantity */
      quantity: number | string;
      /** Amount */
      amount: number | string;
      tax_exemption?: components['schemas']['TaxExemptionEnum'] | null;
    };
    /** TransactionItemEstimateBase */
    TransactionItemEstimateBase: {
      /** Unique identifier of this transaction item in the source system */
      external_id?: string | null;
      /**
       * Date of this transaction item in ISO 8601 format.
       * Format: date-time
       */
      date: string;
      /** Description of this transaction item. */
      description?: string | null;
      /**
       * Unique identifier of the product in the source system
       * @description If a product is not found by this ID, it will be created during estimation.
       */
      external_product_id: string;
      /**
       * Name of the product
       * @description Will be used as the name for the product if we create it.
       */
      product_name: string | null;
      /**
       * Description of the product
       * @description Will be used as the description for the product if we create it.
       */
      product_description: string | null;
      /**
       * Quantity of the product.
       * @default 1.0
       */
      quantity: number | string;
      /** Total amount of this transaction item, after discounts. */
      amount: number | string;
      /**
       * Whether or not this transaction item is exempt from tax.
       * @default false
       */
      exempt: boolean;
    };
    /** TransactionItemEstimateResponse */
    TransactionItemEstimateResponse: {
      /** Unique identifier of this transaction item in the source system */
      external_id?: string | null;
      /**
       * Date of this transaction item in ISO 8601 format.
       * Format: date-time
       */
      date: string;
      /** Description of this transaction item. */
      description?: string | null;
      /**
       * Unique identifier of the product in the source system
       * @description If a product is not found by this ID, it will be created during estimation.
       */
      external_product_id: string;
      /**
       * Name of the product
       * @description Will be used as the name for the product if we create it.
       */
      product_name: string | null;
      /**
       * Description of the product
       * @description Will be used as the description for the product if we create it.
       */
      product_description: string | null;
      /**
       * Quantity of the product.
       * @default 1.0
       */
      quantity: string;
      /** Total amount of this transaction item, after discounts. */
      amount: string;
      /**
       * True if this item is tax exempt.
       * @default false
       */
      exempt: boolean;
      /**
       * The amount of tax determined for this transaction item.
       * @default 0.00
       */
      tax_amount: string;
      /**
       * The amount of this item that was determined to be taxable.
       * @default 0.00
       */
      taxable_amount: string;
      /**
       * The calculated combined tax rate for this transaction item.
       * @default 0.00
       */
      tax_rate: string;
      /** The reason why we determined the transaction item is exempt. */
      exempt_reason?: components['schemas']['TaxExemptionEnum'] | null;
      /**
       * The tax items that were determined for this transaction item.
       * @default []
       */
      tax_items: components['schemas']['TaxItemEstimate'][];
    };
    /** TransactionItemImport */
    TransactionItemImport: {
      /** External Id */
      external_id?: string | null;
      /**
       * Date
       * Format: date-time
       */
      date: string;
      /** Quantity */
      quantity: number | string;
      /** Amount */
      amount: number | string;
      /** Description */
      description?: string | null;
      /** Product */
      product?: string | null;
      /** External Product Id */
      external_product_id: string;
      /** Product Description */
      product_description?: string | null;
      /** Product Name */
      product_name?: string | null;
      /**
       * Tax Amount Imported
       * @default 0.00
       */
      tax_amount_imported: number | string;
      /**
       * Tax Rate Imported
       * @default 0.00
       */
      tax_rate_imported: number | string;
      /**
       * Tax Items
       * @default []
       */
      tax_items: components['schemas']['TransactionTaxItemImport'][] | null;
      /**
       * Taxable Amount
       * @default 0.00
       */
      taxable_amount: number | string;
      /** Exempt */
      exempt: boolean;
      /** Organization Id */
      organization_id: string | null;
    };
    /** TransactionItemRead */
    TransactionItemRead: {
      /** External Id */
      external_id?: string | null;
      /**
       * Date
       * Format: date-time
       */
      date: string;
      /** Description */
      description?: string | null;
      /** Product */
      product: string;
      /** External Product Id */
      external_product_id: string;
      /**
       * Quantity
       * @default 1.0
       */
      quantity: string;
      /**
       * Amount
       * @default 0.00
       */
      amount: string;
      /**
       * Tax Amount Calculated
       * @default 0.00
       */
      tax_amount_calculated: string;
      /**
       * Tax Rate Calculated
       * @default 0.00
       */
      tax_rate_calculated: string;
      /**
       * Tax Amount Imported
       * @default 0.00
       */
      tax_amount_imported: string;
      /**
       * Tax Rate Imported
       * @default 0.00
       */
      tax_rate_imported: string;
      /**
       * Taxable Amount
       * @default 0.00
       */
      taxable_amount: string;
      tax_exemption?: components['schemas']['TaxExemptionEnum'] | null;
      /**
       * Exempt
       * @default false
       */
      exempt: boolean;
      /** Organization Id */
      organization_id: string | null;
      /** Id */
      id: string;
      /** Product Id */
      product_id: string | null;
      /** Tax Items */
      tax_items: components['schemas']['TaxItemRead'][];
    };
    /** TransactionRead */
    TransactionRead: {
      /** @default COMMITTED */
      status: components['schemas']['TransactionStatusEnum'];
      /** @default SALE */
      type: components['schemas']['TransactionTypeEnum'];
      /**
       * Date
       * Format: date-time
       */
      date: string;
      /** External Id */
      external_id: string;
      /** Total Amount */
      total_amount: string;
      /**
       * Total Tax Amount Imported
       * @default 0.00
       */
      total_tax_amount_imported: string;
      /**
       * Tax Rate Imported
       * @default 0.00
       */
      tax_rate_imported: string;
      /**
       * Total Tax Amount Calculated
       * @default 0.00
       */
      total_tax_amount_calculated: string;
      /**
       * Tax Rate Calculated
       * @default 0.00
       */
      tax_rate_calculated: string;
      /**
       * Taxable Amount
       * @default 0.00
       */
      taxable_amount: string;
      currency: components['schemas']['CurrencyEnum'];
      /**
       * Locked
       * @default false
       */
      locked: boolean;
      /** Description */
      description?: string | null;
      /** @default UNKNOWN */
      source: components['schemas']['SourceEnum'];
      /** City */
      city?: string | null;
      /** State */
      state?: string | null;
      country?: components['schemas']['CountryCodeEnum'] | null;
      /** Postal Code */
      postal_code?: string | null;
      /**
       * Marketplace
       * @default false
       */
      marketplace: boolean | null;
      /** @default NOT_EXEMPT */
      exempt: components['schemas']['TransactionExemptStatusEnum'] | null;
      /** Organization Id */
      organization_id: string;
      /** Connection Id */
      connection_id?: string | null;
      /** Id */
      id: string;
      /** Addresses */
      addresses: components['schemas']['TransactionAddressRead'][];
      address_status: components['schemas']['AddressStatus'];
      /** Transaction Items */
      transaction_items: components['schemas']['TransactionItemRead'][];
      /** Customer Id */
      customer_id: string;
      /** Filing Id */
      filing_id: string | null;
      /** Processing status */
      processing_status: ProcessingStatusEnum;
      /** Exemptions */
      exemptions: string[];
    };
    /**
     * TransactionStatusEnum
     * @enum {string}
     */
    TransactionStatusEnum: TransactionStatusEnum;
    /** TransactionTaxItemImport */
    TransactionTaxItemImport: {
      /** Amount */
      amount: number | string;
      /** Name */
      name: string;
      /**
       * Rate
       * @default 0.00
       */
      rate: number | string;
      /** External Id */
      external_id?: string | null;
      /**
       * Taxable Amount
       * @default 0.00
       */
      taxable_amount: number | string;
      jurisdiction_type?: components['schemas']['JurisdictionType'] | null;
      /** Jurisdiction Name */
      jurisdiction_name?: string | null;
      /** @default IMPORTED */
      type: components['schemas']['TaxItemTypeEnum'];
      /** Organization Id */
      organization_id?: string | null;
    };
    /**
     * TransactionTypeEnum
     * @enum {string}
     */
    TransactionTypeEnum: TransactionTypeEnum;
    /** TransactionUpdate */
    TransactionUpdate: {
      status: components['schemas']['TransactionStatusEnum'];
      /**
       * Date
       * Format: date-time
       */
      date: string;
      /** External Id */
      external_id: string | null;
      currency: components['schemas']['CurrencyEnum'];
      /** Description */
      description: string | null;
      /** Addresses */
      addresses: components['schemas']['TransactionAddressBuilder'][] | components['schemas']['Address-Input'][];
      /** @default UNVERIFIED */
      address_status: components['schemas']['AddressStatus'];
      /** Transaction Items */
      transaction_items: components['schemas']['TransactionItemCreateUpdate'][];
      /** Organization Id */
      organization_id?: string | null;
      type?: components['schemas']['TransactionTypeEnum'] | null;
      source: components['schemas']['SourceEnum'];
      customer: components['schemas']['CustomerUpdate'];
    };
    /** UpdateExistingItem */
    UpdateExistingItem: {
      /** Item Price Id */
      item_price_id: string;
      billing_plan: components['schemas']['BillingPlanEnum'];
    };
    /** UserCreated */
    UserCreated: {
      /** Email */
      email: string;
      /** Email Confirmed */
      email_confirmed: boolean;
      /** Event Type */
      event_type: string;
      /** Picture Url */
      picture_url: string;
      /** User Id */
      user_id: string;
    };
    /** UserRead */
    UserRead: {
      /** User Id */
      user_id: string;
      /** Org Id To Org Member Info */
      org_id_to_org_member_info: Record<string, never>;
      /** Email */
      email: string;
      /** First Name */
      first_name: string;
      /** Last Name */
      last_name: string;
      /** Username */
      username: string;
      /** Legacy User Id */
      legacy_user_id: string;
      /** Impersonator User Id */
      impersonator_user_id: string;
      /** Metadata */
      metadata: string;
      /** Properties */
      properties: Record<string, never>;
    };
    /** ValidationAddress */
    ValidationAddress: {
      /** Line1 */
      line1?: string | null;
      /** Line2 */
      line2?: string | null;
      /** Line3 */
      line3?: string | null;
      /** City */
      city?: string | null;
      /** State */
      state?: string | null;
      /**
       * Country
       * @description `country` should be in ISO 3166-1 alpha-2 format, e.g. US, CA and should not be empty. Not validating here as the validation structure can be different for different providers
       * @default US
       */
      country: string | null;
      /**
       * Postalcode
       * @description `postal_code` can be empty for some locales. Not validating here as the validation structure can be different for different providers
       * @default
       */
      postalCode: string | null;
      /** Id */
      id?: number | null;
      /** County */
      county?: string | null;
    };
    /** ValidationAddressBase */
    ValidationAddressBase: {
      /** Line1 */
      line1?: string | null;
      /** Line2 */
      line2?: string | null;
      /** Line3 */
      line3?: string | null;
      /** City */
      city?: string | null;
      /** State */
      state?: string | null;
      /**
       * Country
       * @description `country` should be in ISO 3166-1 alpha-2 format, e.g. US, CA and should not be empty. Not validating here as the validation structure can be different for different providers
       * @default US
       */
      country: string | null;
      /**
       * Postalcode
       * @description `postal_code` can be empty for some locales. Not validating here as the validation structure can be different for different providers
       * @default
       */
      postalCode: string | null;
    };
    /** ValidationError */
    ValidationError: {
      /** Location */
      loc: (string | number)[];
      /** Message */
      msg: string;
      /** Error Type */
      type: string;
    };
    /** ValidationResult */
    ValidationResult: {
      /** Is Valid */
      is_valid: boolean;
      /** File Name */
      file_name: string;
      /** Rows */
      rows: number;
      /** Detected Type */
      detected_type?: string | null;
      /** Detected Dialect */
      detected_dialect?: string | null;
      /**
       * Result Data
       * @default []
       */
      result_data: components['schemas']['ImportFormat'][];
      /** Errors */
      errors: unknown;

      file_errors: string | null;
    };
    /** Webhook */
    Webhook: {
      /** Id */
      id?: string | null;
      /** Webhook Status */
      webhook_status?: string | null;
      /** Object */
      object?: string | null;
    };
    /** LineItem */
    backend__src__billing__models__LineItem: {
      /** Id */
      id: string;
      /** Date From */
      date_from: number;
      /** Date To */
      date_to: number;
      /** Unit Amount */
      unit_amount: number;
      /** Quantity */
      quantity: number;
      /** Amount */
      amount: number;
      /** Pricing Model */
      pricing_model: string;
      /** Is Taxed */
      is_taxed: boolean;
      /** Tax Amount */
      tax_amount: number;
      /** Object */
      object: string;
      /** Subscription Id */
      subscription_id: string;
      /** Customer Id */
      customer_id: string;
      /** Description */
      description: string;
      /** Entity Type */
      entity_type: string;
      /** Entity Id */
      entity_id: string;
      /** Metered */
      metered: boolean;
      /** Discount Amount */
      discount_amount: number;
      /** Item Level Discount Amount */
      item_level_discount_amount: number;
    };
    /**
     * LineItem
     * @description The details of a line item
     */
    'backend__src__chargebee_adapter__models__invoice__LineItem-Output': {
      /**
       * Number
       * @description Index or serial number of the line item.
       */
      number: number;
      /**
       * Itemcode
       * @description The unique identifier (in Chargebee) of the product correspondingto the line item. If the line item corresponds to a one-off charge,then this identifier is not present.
       */
      itemCode?: string | null;
      /**
       * Description
       * @description The description of the line item.
       */
      description?: string | null;
      /**
       * Quantity
       * @description The quantity associated with this line item.
       */
      quantity?: number | null;
      /**
       * Unitprice
       * @description The unit price for this line item. In case of tiered pricing where theunit price varies for each quantity tier, this is the average unit price.
       */
      unitPrice?: number | null;
      /**
       * Amount
       * @description The amount for this line item. This is unitPrice*quantity.
       */
      amount: number;
      /**
       * Istaxinclusive
       * @description Indicates whether the subtotal for this line item is inclusive of taxes.
       */
      isTaxInclusive: boolean;
      /**
       * Istaxable
       * @description Indicates whether this line item is taxable.
       */
      isTaxable: boolean;
      /**
       * Taxidentifiers
       * @description The tax code fields of the product used for tax calculation.
       */
      taxIdentifiers?: components['schemas']['TaxIdentifier'][] | null;
      /** @description The tax exemption type for a line item. This is a mandatory parameter while applying tax exemption on any line-item. */
      taxExemptType?: components['schemas']['TaxExemptType'] | null;
      /**
       * Taxexemptreason
       * @description The reason due to which a line item is exempted from tax. This is a mandatory parameter while applying tax exemption on any line-item.
       */
      taxExemptReason?: string | null;
      /**
       * Exemptamount
       * @description The part of this line item's subtotal that is exempted from tax.
       */
      exemptAmount: number;
      /**
       * Discountamount
       * @description The discount applied to this line item.
       */
      discountAmount: number;
      /**
       * Subtotal
       * @description The amount after discounts for this line item.This is amount - discountAmount.
       */
      subtotal: number;
      /**
       * Taxableamount
       * @description The part of this line item's subtotal that is taxable.
       */
      taxableAmount: number;
      /**
       * Taxamount
       * @description The tax payable for this line item. This is the sum of all taxes.taxAmount for this line item.
       */
      taxAmount: number;
      /**
       * Total
       * @description The total for this line item after discounts and taxes. This is the same as subtotal if it is tax inclusive; otherwise it is subtotal + taxAmount. total can also be expressed as exemptAmount + taxableAmount + taxAmount.
       */
      total: number;
      /**
       * Ispartialtax
       * @description Indicates if taxes were applied only partially for this line item.
       */
      isPartialTax?: boolean | null;
      /**
       * Taxes
       * @description List of taxes applied for this line item under each jurisdiction.
       */
      taxes: components['schemas']['TaxLineItem-Output'][];
    };
  };
  responses: never;
  parameters: never;
  requestBodies: never;
  headers: never;
  pathItems: never;
}
export type $defs = Record<string, never>;
export interface operations {
  health_check_health_get: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
    };
  };
  integration_test_integration_get: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
    };
  };
  proxy_address_validation_search_v1_address_validation_search_fields_post: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ValidationAddress'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  proxy_address_validation_suggestions_v1_address_validation_suggestions_post: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ValidationAddress'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  proxy_address_validation_bulk_suggestions_v1_address_validation_bulk_suggestions_post: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['Address-Input'][];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  run_address_batch_v1_address_validation_run_address_batch_post: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
    };
  };
  get_attachments_v1_attachments_get: {
    parameters: {
      query?: {
        order_by?: string | null;
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_AttachmentRead_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  upload_attachment_v1_attachments_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'multipart/form-data': components['schemas']['Body_upload_attachment_v1_attachments_post'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['AttachmentRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_attachment_by_id_v1_attachments__attachment_id__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        attachment_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Attachment'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  download_attachment_v1_attachments__attachment_id__download_get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        attachment_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  run_all_batches_v1_internal_batches_run_post: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['BatchMessage'];
        };
      };
    };
  };
  run_organization_batch_v1_internal_batches_run__organization_id__post: {
    parameters: {
      query?: never;
      header?: never;
      path: {
        organization_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['BatchMessage'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  run_batch_stage_for_org_v1_internal_batches_run__organization_id___batch_stage__post: {
    parameters: {
      query?: never;
      header?: never;
      path: {
        organization_id: string;
        batch_stage: components['schemas']['BatchStages'];
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['BatchMessage'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_connections_v1_connections_get: {
    parameters: {
      query?: {
        status?: string;
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_ConnectionRead_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_connection_by_id_v1_connections__connection_id__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        connection_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ConnectionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  update_connection_v1_connections__connection_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        connection_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ConnectionUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ConnectionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_or_update_shopify_connection_v1_connections_shopify__shop_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        shop_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ShopifyCreateUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ConnectionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_or_update_shopify_connection_from_oauth_v1_connections_shopify_oauth__shop_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        shop_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ShopifyCreateUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ConnectionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_or_update_chargebee_connection_v1_connections_chargebee__site_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        site_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ChargebeeCreateUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ConnectionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_or_update_stripe_connection_v1_connections_stripe__publishable_key__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        publishable_key: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['StripeReadUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ConnectionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  sync_connection_v1_connections__connection_id__sync_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        connection_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  update_connection_status_v1_connections__connection_id__status_put: {
    parameters: {
      query: {
        new_connection_status: string;
      };
      header: {
        'x-organization-id': string | null;
      };
      path: {
        connection_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ConnectionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_apideck_connection_v1_connections_apideck__service_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        service_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  reset_connection_v1_connections__connection_id__reset_post: {
    parameters: {
      query: {
        auth_code: string;
        organization_id: string;
        update_mode?: boolean;
      };
      header?: never;
      path: {
        connection_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_customers_v1_customers_get: {
    parameters: {
      query?: {
        search_query?: string | null;
        country?: components['schemas']['CountryCodeEnum'] | null;
        state?: string | null;
        order_by?: string | null;
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_CustomerRead_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_customer_v1_customers_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['CustomerCreate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['CustomerRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_customer_by_id_v1_customers__customer_id__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        customer_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['CustomerRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  update_customer_v1_customers__customer_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        customer_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['CustomerUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['CustomerRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_customer_by_external_id_v1_customers_external__external_id__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        external_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['CustomerRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  send_internal_email_v1_internal_email_send_post: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['EmailRequest'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  send_password_v1_email_send_registration_password_post: {
    parameters: {
      query: {
        organization_id: string;
        state_name: string;
      };
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_exemptions_v1_exemptions_get: {
    parameters: {
      query?: {
        status__in?: string | null;
        start_date?: string | null;
        end_date?: string | null;
        customer_id?: string | null;
        order_by?: string | null;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ExemptionRead'][];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_exemption_v1_exemptions_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ExemptionCreate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ExemptionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_exemption_by_id_v1_exemptions__exemption_id__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        exemption_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ExemptionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_attachments_for_exemption_v1_exemptions__exemption_id__attachments_get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        exemption_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['AttachmentRead'][];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  upload_exemption_certificate_v1_exemptions__exemption_id__attachments_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        exemption_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'multipart/form-data': components['schemas']['Body_upload_exemption_certificate_v1_exemptions__exemption_id__attachments_post'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['AttachmentRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_dashboard_data_route_v1_dashboard__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['DashboardData'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  update_onboarding_status_route_v1_onboarding_put: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['OnboardingData'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_dashboard_details_v1_dashboard_details_aggregations__aggregation_type__get: {
    parameters: {
      query?: {
        /** @description Country for filtering */
        country?: string;
        /** @description State for filtering */
        state?: string;
        /** @description Marketplace for filtering */
        marketplace?: boolean;
      };
      header: {
        'x-organization-id': string | null;
      };
      path: {
        aggregation_type: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['DashboardDetailsData'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_onboarding_steps_status_v1_onboarding_steps_status_get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['OnBoardingStepStatusData'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_filings_v1_filings_get: {
    parameters: {
      query?: {
        state_name?: string | null;
        status__in?: string | null;
        start_date?: string | null;
        end_date?: string | null;
        order_by?: string | null;
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_FilingRead_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_filing_v1_filings_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['FilingCreate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['FilingRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_filing_by_id_v1_filings__filing_id__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        filing_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['FilingDetailsRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  update_filing_v1_filings__filing_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        filing_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['FilingUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['FilingRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  update_filing_internal_v1_internal_filings__filing_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        filing_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['FilingUpdateInternal'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['FilingRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_filings_by_registration_id_v1_filings_registration__registration_id__get: {
    parameters: {
      query?: {
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header: {
        'x-organization-id': string | null;
      };
      path: {
        registration_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_FilingRead_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  export_filings_v1_filings_reports_export_post: {
    parameters: {
      query?: {
        unpaid_only?: boolean;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_filing_details_report_by_id_v1_filings__filing_id__reports_export_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        filing_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  upload_filing_attachment_v1_filings__filing_id__attachments_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        filing_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'multipart/form-data': components['schemas']['Body_upload_filing_attachment_v1_filings__filing_id__attachments_post'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['AttachmentRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  validate_import_data_v1_import_validate_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['RawImportData'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ValidationResult'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  import_upload_urls_v1_import_upload_urls_post: {
    parameters: {
      query: {
        user_id: string;
        manual?: boolean;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['FileUploadData'][];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['FileUploadURLs'][];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  internal_import_v1_import_internal_upload_post: {
    parameters: {
      query: {
        user_id: string;
        source?: components['schemas']['SourceEnum'] | null;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'multipart/form-data': components['schemas']['Body_internal_import_v1_import_internal_upload_post'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  manual_internal_import_v1_import_internal_manual_upload_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'multipart/form-data': components['schemas']['Body_manual_internal_import_v1_import_internal_manual_upload_post'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  jira_webhook_v1_webhooks_jira_post: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
    };
  };
  get_nexus_for_org_v1_nexus__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_physical_nexus_v1_nexus_physical_nexus_get: {
    parameters: {
      query?: {
        country_code?: string | null;
        state_code?: string | null;
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_PhysicalNexusRead_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_physical_nexus_v1_nexus_physical_nexus_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['PhysicalNexusCreate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['PhysicalNexusRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  update_physical_nexus_v1_nexus_physical_nexus__physical_nexus_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        physical_nexus_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['PhysicalNexusUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['PhysicalNexusRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  delete_physical_nexus_v1_nexus_physical_nexus__physical_nexus_id__delete: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        physical_nexus_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  refresh_nexus_by_country_state_v1_nexus__country_code___state_code__refresh_post: {
    parameters: {
      query?: {
        deep_refresh?: boolean;
      };
      header: {
        'x-organization-id': string | null;
      };
      path: {
        country_code: components['schemas']['CountryCodeEnum'];
        state_code: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  refresh_nexus_v1_nexus_refresh_post: {
    parameters: {
      query?: {
        deep_refresh?: boolean;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  refresh_all_nexuses_belonging_to_all_organizations_v1_internal_nexus_refresh_all_organizations_post: {
    parameters: {
      query?: {
        deep_refresh?: boolean;
      };
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_nexus_report_v1_nexus_export_get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_organization_by_id_v1_internal_organizations__organization_id__get: {
    parameters: {
      query?: never;
      header?: never;
      path: {
        organization_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['OrganizationRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_organizations_v1_internal_organizations__get: {
    parameters: {
      query?: {
        status?: string | null;
        order_by?: string | null;
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_OrganizationRead_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_organizations_by_user_v1_organizations__get: {
    parameters: {
      query?: {
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_OrganizationRead_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  update_organization_v1_organizations__organization_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': unknown;
      };
      path: {
        organization_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['OrganizationUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['OrganizationRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  delete_organization_v1_organizations__organization_id__delete: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': unknown;
      };
      path: {
        organization_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  auth_webhook_v1_webhooks_auth__post: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json':
          | components['schemas']['OrgCreated']
          | components['schemas']['OrgUpdatedDeleted']
          | components['schemas']['UserCreated'];
      };
    };
    responses: {
      /** @description Successful Response */
      202: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_current_user_v1_users_me_get: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['UserRead'];
        };
      };
    };
  };
  get_user_signup_query_params_v1_users_signup_query_params_get: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
    };
  };
  get_bank_details_v1_bank_details__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': unknown;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['BankDetailsRead'] | null;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_update_bank_details_v1_bank_details__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': unknown;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['BankDetailsCreateUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['BankDetailsRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_update_business_details_v1_organization_details_business_details__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['OrganizationDetailsBusinessDetailsCreateUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['OrganizationDetailsRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_update_business_address_v1_organization_details_business_address__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['OrganizationDetailsCompanyAddressCreateUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['OrganizationDetailsRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_update_business_information_v1_organization_details_business_information__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['OrganizationDetailsBusinessInformationCreateUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['OrganizationDetailsRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_update_business_contact_v1_organization_details_business_contact__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['OrganizationDetailsBusinessContactCreateUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['OrganizationDetailsRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_update_business_owners_v1_organization_details_business_owners__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['OrganizationDetailsBusinessOwnersCreateUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['OrganizationDetailsRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_organization_details_v1_organization_details__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': unknown;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['OrganizationDetailsRead'] | null;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_products_v1_products__get: {
    parameters: {
      query?: {
        query?: string | null;
        status__in?: string | null;
        product_category__in?: string | null;
        product_subcategory__in?: string | null;
        order_by?: string | null;
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_ProductRead_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_product_by_id_v1_products__product_id__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        product_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ProductRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  update_product_v1_products__product_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        product_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ProductUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ProductRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_product_categories_v1_products_categories__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ProductCategoryRead'][];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_update_product_categories_v1_products_categories__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ProductCatCreateUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ProductCatRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  bulk_approve_v1_products_bulk_approve__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': {
          [key: string]: components['schemas']['ProductUpdate'] | undefined;
        };
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  bulk_classify_v1_products_bulk_classify__post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_product_config_v1_products_config__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ProductConfigRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_update_product_config_v1_products_config__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ProductConfigCreateUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ProductConfigRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_product_classification_status_v1_products_classification_status__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_registrations_v1_registrations_get: {
    parameters: {
      query?: {
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_RegistrationReadWithPassword_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_registration_v1_registrations_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['RegistrationCreate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['RegistrationRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_registration_by_id_v1_registrations__registration_id__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        registration_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['RegistrationRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  update_registration_v1_registrations__registration_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        registration_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['RegistrationUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['RegistrationRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  upload_registration_attachment_v1_registrations__registration_id__attachments_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        registration_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'multipart/form-data': components['schemas']['Body_upload_registration_attachment_v1_registrations__registration_id__attachments_post'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Attachment'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_rules_v1_rules__get: {
    parameters: {
      query?: {
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_RuleRead_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_rule_by_id_v1_rules__rule_id__get: {
    parameters: {
      query?: never;
      header?: never;
      path: {
        rule_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['RuleRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_transactions_by_customer_id_v1_customers__customer_id__transactions_get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        customer_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['TransactionRead'][];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_transaction_by_customer_id_v1_customers__customer_id__transactions_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        customer_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['TransactionCreate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['TransactionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_addresses_v1_transactions_addresses_get: {
    parameters: {
      query: {
        /** @description Filter on whether address is empty */
        address_not_empty?: boolean;
        /** @description Filter on whether city field is existed */
        has_city?: boolean;
        /** @description Filter on whether state field is existed */
        has_state?: boolean;
        /** @description Filter on whether country field is existed */
        has_country?: boolean;
        /** @description Filter on whether postal code field is existed */
        has_postal_code?: boolean;
        status: components['schemas']['AddressStatus'] | null;
        city?: string | null;
        state?: string | null;
        postal_code?: string | null;
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_TransactionAddressRead_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  update_addresses_v1_transactions_addresses_put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['TransactionAddressUpdate'][];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_transaction_by_id_v1_transactions__transaction_id__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        transaction_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['TransactionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  update_transaction_v1_transactions__transaction_id__put: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        transaction_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['TransactionUpdate'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['TransactionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_related_transactions_v1_transactions__transaction_id__related_transactions_get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        transaction_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['TransactionRead'][];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_transaction_by_external_id_v1_transactions_external__external_id__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        external_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['TransactionRead'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_transactions_v1_transactions_get: {
    parameters: {
      query?: {
        state_code?: string | null;
        transaction_type?: string | null;
        search_query?: string | null;
        country?: string | null;
        state?: string | null;
        address_status__in?: string | null;
        status?: components['schemas']['TransactionStatusEnum'] | null;
        filing_id?: string | null;
        order_by?: string | null;
        date__gte?: string | null;
        date__lte?: string | null;
        /** @description Page number */
        page?: number;
        /** @description Page size */
        size?: number;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Page_TransactionRead_'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  import_transactions_v1_transactions_import_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['TransactionImport'][];
      };
    };
    responses: {
      /** @description Successful Response */
      202: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['TransactionImportResponse'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_transactions_by_filing_id_v1_transactions_filings__filing_id__get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path: {
        filing_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  export_transactions_to_csv_v1_transactions_export_csv_get: {
    parameters: {
      query?: {
        limit_transactions?: number;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  export_transactions_details_report_v1_transactions_reports_details_post: {
    parameters: {
      query?: {
        country_code?: string | null;
        start_year?: number | null;
        end_year?: number | null;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  export_transactions_summary_report_v1_transactions_reports_summary_post: {
    parameters: {
      query?: {
        country_code?: string | null;
        start_year?: number | null;
        end_year?: number | null;
      };
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  estimate_tax_v1_tax_estimate_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['TransactionEstimateRequest'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['TransactionEstimateResponse'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  proxy_taxbot_v1_taxgpt_get: {
    parameters: {
      query: {
        /** @description Text to be sent to chat */
        text: string;
      };
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': unknown;
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_organization_billing_info_v1_billing_get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['OrganizationBillingPlan'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_organization_billing_plan_v1_billing_plan_get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['BillingPlanEnum'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  get_invoices_from_chargebee_v1_billing_invoices_get: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Invoice'][];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_checkout_new_url_v1_billing_checkout_new_url_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['CheckoutNewItem'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HostedPage'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  generate_portal_session_v1_billing_generate_portal_session_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['PortalSessionHostedPage'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  listen_chargebee_checkout_webhook_v1_billing_chargebee_checkout_post: {
    parameters: {
      query?: never;
      header?: never;
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': {
            [key: string]: string | undefined;
          };
        };
      };
    };
  };
  update_billing_plan_v1_billing_update_billing_plan_post: {
    parameters: {
      query?: never;
      header: {
        'x-organization-id': string | null;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['UpdateExistingItem'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['Subscription'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  handle_chargebee_webhook_v1_webhooks_chargebee__organization_id__post: {
    parameters: {
      query?: never;
      header?: never;
      path: {
        organization_id: string;
      };
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['Event'];
      };
    };
    responses: {
      /** @description Successful Response */
      204: {
        headers: {
          [name: string]: unknown;
        };
        content?: never;
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  validate_address_chargebee_address_validate_post: {
    parameters: {
      query?: never;
      header?: {
        authorization?: string;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ChargebeeAddressValidationRequest'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeAddressValidationResponse'];
        };
      };
      /** @description Bad Request */
      400: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeValidationError'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
      /** @description Internal Server Error */
      500: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeUnexpectedError'];
        };
      };
    };
  };
  check_address_taxability_chargebee_address_check_taxability_post: {
    parameters: {
      query?: never;
      header?: {
        authorization?: string;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ChargebeeTaxabilityAddressRequest'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeAddressTaxabilityResponse'];
        };
      };
      /** @description Bad Request */
      400: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeValidationError'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
      /** @description Internal Server Error */
      500: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeUnexpectedError'];
        };
      };
    };
  };
  estimate_tax_chargebee_tax_estimate_post: {
    parameters: {
      query?: never;
      header?: {
        authorization?: string;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ChargebeeTaxEstimateRequest'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeTaxEstimationResponse'];
        };
      };
      /** @description Bad Request */
      400: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeValidationError'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
      /** @description Internal Server Error */
      500: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeUnexpectedError'];
        };
      };
    };
  };
  create_invoice_chargebee_invoices_post: {
    parameters: {
      query?: never;
      header?: {
        authorization?: string;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['ChargebeeInvoiceRequest'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeInvoiceResponse'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  commit_invoice_chargebee_invoices__invoice_id__commit_post: {
    parameters: {
      query?: never;
      header?: {
        authorization?: string;
      };
      path: {
        invoice_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      204: {
        headers: {
          [name: string]: unknown;
        };
        content?: never;
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  void_invoice_chargebee_invoices__invoice_id__void_post: {
    parameters: {
      query?: never;
      header?: {
        authorization?: string;
      };
      path: {
        invoice_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      204: {
        headers: {
          [name: string]: unknown;
        };
        content?: never;
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  create_credit_note_chargebee_credit_notes_post: {
    parameters: {
      query?: never;
      header?: {
        authorization?: string;
      };
      path?: never;
      cookie?: never;
    };
    requestBody: {
      content: {
        'application/json': components['schemas']['CreditNoteRequest'];
      };
    };
    responses: {
      /** @description Successful Response */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['CreditNoteResponse'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  commit_credit_note_chargebee_credit_notes__credit_note_id__commit_post: {
    parameters: {
      query?: never;
      header?: {
        authorization?: string;
      };
      path: {
        credit_note_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Credit note committed successfully. */
      204: {
        headers: {
          [name: string]: unknown;
        };
        content?: never;
      };
      /** @description Bad request, cannot update status */
      400: {
        headers: {
          [name: string]: unknown;
        };
        content?: never;
      };
      /** @description Unauthenticated request. */
      401: {
        headers: {
          [name: string]: unknown;
        };
        content?: never;
      };
      /** @description Unauthorized request. */
      403: {
        headers: {
          [name: string]: unknown;
        };
        content?: never;
      };
      /** @description Not found. */
      404: {
        headers: {
          [name: string]: unknown;
        };
        content?: never;
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
      /** @description Too many requests. */
      429: {
        headers: {
          [name: string]: unknown;
        };
        content?: never;
      };
      /** @description Unexpected error while processing request. */
      500: {
        headers: {
          [name: string]: unknown;
        };
        content?: never;
      };
    };
  };
  void_credit_note_chargebee_credit_notes__credit_note_id__void_post: {
    parameters: {
      query?: never;
      header?: {
        authorization?: string;
      };
      path: {
        credit_note_id: string;
      };
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Successful Response */
      204: {
        headers: {
          [name: string]: unknown;
        };
        content?: never;
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
    };
  };
  validate_credentials_chargebee_credentials_validate_post: {
    parameters: {
      query?: never;
      header?: {
        authorization?: string;
      };
      path?: never;
      cookie?: never;
    };
    requestBody?: never;
    responses: {
      /** @description Authentication succeeded. */
      200: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeCredentialValidationResponse'];
        };
      };
      /** @description Authentication failed */
      401: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeCredentialValidationResponse'];
        };
      };
      /** @description Validation Error */
      422: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['HTTPValidationError'];
        };
      };
      /** @description Internal Server Error */
      500: {
        headers: {
          [name: string]: unknown;
        };
        content: {
          'application/json': components['schemas']['ChargebeeUnexpectedError'];
        };
      };
    };
  };
}
export enum AddressStatus {
  UNVERIFIED = 'UNVERIFIED',
  INVALID = 'INVALID',
  PARTIALLY_VERIFIED = 'PARTIALLY_VERIFIED',
  VERIFIED = 'VERIFIED',
  UNVERIFIABLE = 'UNVERIFIABLE',
  BLANK = 'BLANK',
}
export enum AddressType {
  BILL_TO = 'BILL_TO',
  SHIP_TO = 'SHIP_TO',
  SHIP_FROM = 'SHIP_FROM',
}
export enum BankAccountTypeEnum {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}
export enum BatchStages {
  CONNECTION_SYNC = 'CONNECTION_SYNC',
  PRODUCT = 'PRODUCT',
  TAX = 'TAX',
  NEXUS = 'NEXUS',
  FILING = 'FILING',
  REGISTRATION = 'REGISTRATION',
  TRANSACTION_FILING = 'TRANSACTION_FILING',
  ADDRESS_VERIFICATION = 'ADDRESS_VERIFICATION',
  CONDITIONAL_EXECUTION = 'CONDITIONAL_EXECUTION',
  DETERMINE_TAX_EXEMPTION = 'DETERMINE_TAX_EXEMPTION',
  DETERMINE_TAX_ITEMS = 'DETERMINE_TAX_ITEMS',
  CALCULATE_TAX_LIABILITY = 'CALCULATE_TAX_LIABILITY',
}
export enum BillingPlanEnum {
  FREE = 'FREE',
  GROWTH = 'GROWTH',
  PREMIUM = 'PREMIUM',
}
export enum ChargebeeRequestValidationEnum {
  INVALID_DATA = 'INVALID_DATA',
  MISSING_REQUIRED_DATA = 'MISSING_REQUIRED_DATA',
}
export enum ChargebeeStatusEnum {
  VALID = 'VALID',
  INVALID = 'INVALID',
}
export enum ConnectionStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}
export enum CountryCodeEnum {
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BQ = 'BQ',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  CV = 'CV',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  HR = 'HR',
  CU = 'CU',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  SZ = 'SZ',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  MK = 'MK',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SX = 'SX',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  SS = 'SS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  US = 'US',
  UM = 'UM',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  VI = 'VI',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
  XK = 'XK',
}
export enum CreditNoteType {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}
export enum CurrencyEnum {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IMP = 'IMP',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SPL = 'SPL',
  SRD = 'SRD',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TVD = 'TVD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWD = 'ZWD',
}
export enum DocumentStatus {
  PENDING = 'PENDING',
  COMMITTED = 'COMMITTED',
  VOIDED = 'VOIDED',
}
export enum EntityTypeEnum {
  CORPORATION = 'CORPORATION',
  LLC = 'LLC',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  NONPROFIT = 'NONPROFIT',
}
export enum ExemptionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
  DEACTIVATED = 'DEACTIVATED',
  ARCHIVED = 'ARCHIVED',
}
export enum ExemptionType {
  customer = 'customer',
  wholesale = 'wholesale',
  transaction = 'transaction',
}
export enum FilingFrequencyEnum {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  ANNUALLY = 'ANNUALLY',
}
export enum FilingStatusEnum {
  UNFILED = 'UNFILED',
  FILING = 'FILING',
  FILED = 'FILED',
}
export enum ImportOperationEnum {
  IMPORT = 'IMPORT',
  UPDATE = 'UPDATE',
  ARCHIVE = 'ARCHIVE',
}
export enum JurisdictionType {
  COUNTRY = 'COUNTRY',
  FEDERAL = 'FEDERAL',
  STATE = 'STATE',
  COUNTY = 'COUNTY',
  CITY = 'CITY',
  SPECIAL = 'SPECIAL',
  OTHER = 'OTHER',
}
export enum OnboardingStatus {
  NOT_STARTED = 'NOT_STARTED',
  SKIPPED = 'SKIPPED',
  FINISHED = 'FINISHED',
}
export enum OrganizationSourceEnum {
  PARTNER = 'PARTNER',
  SEARCH = 'SEARCH',
  LINKEDIN = 'LINKEDIN',
  LINKEDIN_AD = 'LINKEDIN_AD',
  GOOGLE_AD = 'GOOGLE_AD',
  REFERRAL = 'REFERRAL',
  UNKNOWN = 'UNKNOWN',
}
export enum OrganizationStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}
export enum ProcessingStatusEnum {
  NEW = 'NEW',
  UPDATED = 'UPDATED',
  PROCESSED = 'PROCESSED',
  LOCKED = 'LOCKED',
  EXEMPT_DONE = 'EXEMPT_DONE',
  ADDRESS_DONE = 'ADDRESS_DONE',
  NEXUS_DONE = 'NEXUS_DONE',
  FILING_STARTED = 'FILING_STARTED',
  FILING_DONE = 'FILING_DONE',
  PENDING = 'PENDING',
  ARCHIVED = 'ARCHIVED',
  QUEUED = 'QUEUED',
}
export enum ProductCategoryEnum {
  PHYSICAL = 'PHYSICAL',
  DIGITAL = 'DIGITAL',
  SERVICE = 'SERVICE',
  UNKNOWN = 'UNKNOWN',
  MISCELLANEOUS = 'MISCELLANEOUS',
}
export enum ProductCodeEnum {
  CODE_0000 = 'CODE_0000',
  CODE_1001 = 'CODE_1001',
  CODE_1002 = 'CODE_1002',
  CODE_1003 = 'CODE_1003',
  CODE_1004 = 'CODE_1004',
  CODE_1005 = 'CODE_1005',
  CODE_1006 = 'CODE_1006',
  CODE_1007 = 'CODE_1007',
  CODE_1008 = 'CODE_1008',
  CODE_2001 = 'CODE_2001',
  CODE_2002 = 'CODE_2002',
  CODE_2003 = 'CODE_2003',
  CODE_2004 = 'CODE_2004',
  CODE_2005 = 'CODE_2005',
  CODE_2006 = 'CODE_2006',
  CODE_2007 = 'CODE_2007',
  CODE_2008 = 'CODE_2008',
  CODE_3001 = 'CODE_3001',
  CODE_3002 = 'CODE_3002',
  CODE_3003 = 'CODE_3003',
  CODE_3004 = 'CODE_3004',
  CODE_3005 = 'CODE_3005',
  CODE_3006 = 'CODE_3006',
  CODE_3007 = 'CODE_3007',
  CODE_3008 = 'CODE_3008',
  CODE_3009 = 'CODE_3009',
  CODE_3010 = 'CODE_3010',
  CODE_3011 = 'CODE_3011',
  CODE_3012 = 'CODE_3012',
  CODE_3013 = 'CODE_3013',
  CODE_3014 = 'CODE_3014',
  CODE_3015 = 'CODE_3015',
  CODE_3016 = 'CODE_3016',
  CODE_3017 = 'CODE_3017',
  CODE_3018 = 'CODE_3018',
  CODE_3019 = 'CODE_3019',
  CODE_3020 = 'CODE_3020',
  CODE_3021 = 'CODE_3021',
  CODE_3022 = 'CODE_3022',
  CODE_9001 = 'CODE_9001',
  CODE_9002 = 'CODE_9002',
  CODE_9003 = 'CODE_9003',
  CODE_9004 = 'CODE_9004',
  CODE_9005 = 'CODE_9005',
  CODE_9006 = 'CODE_9006',
}
export enum ProductStatusEnum {
  APPROVED = 'APPROVED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  PENDING = 'PENDING',
}
export enum ProductSubCategoryEnum {
  UNKNOWN = 'UNKNOWN',
  SAAS = 'SAAS',
  DIGITAL_GENERAL = 'DIGITAL_GENERAL',
  B2B_SAAS = 'B2B_SAAS',
  SOFTWARE_ON_PERSONAL_PROPERTY = 'SOFTWARE_ON_PERSONAL_PROPERTY',
  SOFTWARE_DOWNLOADED = 'SOFTWARE_DOWNLOADED',
  CUSTOM_SOFTWARE_ON_PERSONAL_PROPERTY = 'CUSTOM_SOFTWARE_ON_PERSONAL_PROPERTY',
  CUSTOM_SOFTWARE_DOWNLOADED = 'CUSTOM_SOFTWARE_DOWNLOADED',
  CUSTOMIZATION_OF_SOFTWARE = 'CUSTOMIZATION_OF_SOFTWARE',
  B2C_SAAS = 'B2C_SAAS',
  SERVICE_GENERAL = 'SERVICE_GENERAL',
  PROFESSIONAL_SERVICE = 'PROFESSIONAL_SERVICE',
  TANGIBLE_PROPERTY_SERVICE = 'TANGIBLE_PROPERTY_SERVICE',
  REAL_PROPERTY_SERVICE = 'REAL_PROPERTY_SERVICE',
  BUSINESS_SERVICE = 'BUSINESS_SERVICE',
  PERSONAL_SERVICE = 'PERSONAL_SERVICE',
  AMUSEMENT_SERVICE = 'AMUSEMENT_SERVICE',
  MEDICAL_SERVICES = 'MEDICAL_SERVICES',
  PHYSICAL_GENERAL = 'PHYSICAL_GENERAL',
  GENERAL_CLOTHING = 'GENERAL_CLOTHING',
  CATERING = 'CATERING',
  GROCERY_FOOD = 'GROCERY_FOOD',
  LEASES_AND_RENTALS_MOTOR_VEHICLES = 'LEASES_AND_RENTALS_MOTOR_VEHICLES',
  LEASES_AND_RENTALS_TANGIBLE_MEDIA_PROPERTY = 'LEASES_AND_RENTALS_TANGIBLE_MEDIA_PROPERTY',
  MACHINERY = 'MACHINERY',
  RAW_MATERIALS = 'RAW_MATERIALS',
  UTILITIES_FUEL = 'UTILITIES_FUEL',
  MEDICAL_DEVICES = 'MEDICAL_DEVICES',
  MEDICINES = 'MEDICINES',
  NEWSPAPERS = 'NEWSPAPERS',
  PERIODICALS = 'PERIODICALS',
  GENERAL_OCCASIONAL_SALES = 'GENERAL_OCCASIONAL_SALES',
  MOTOR_VEHICLES_OCCASIONAL_SALES = 'MOTOR_VEHICLES_OCCASIONAL_SALES',
  GENERAL_OPTIONAL_MAINTENANCE_CONTRACTS = 'GENERAL_OPTIONAL_MAINTENANCE_CONTRACTS',
  PARTS_PURCHASED_OPTIONAL_MAINTENANCE_CONTRACTS = 'PARTS_PURCHASED_OPTIONAL_MAINTENANCE_CONTRACTS',
  GENERAL_POLLUTION_CONTROL_EQUIPMENT = 'GENERAL_POLLUTION_CONTROL_EQUIPMENT',
  GENERAL_TRADE_INS = 'GENERAL_TRADE_INS',
  FOOD_VENDING_MACHINE = 'FOOD_VENDING_MACHINE',
  MERCHANDISE_VENDING_MACHINE = 'MERCHANDISE_VENDING_MACHINE',
  SUPPLEMENTS = 'SUPPLEMENTS',
  SHIPPING = 'SHIPPING',
  GIFT_CARD = 'GIFT_CARD',
  CREDIT_CARD_SURCHARGES = 'CREDIT_CARD_SURCHARGES',
  CREDIT_CARD_FEES = 'CREDIT_CARD_FEES',
  MISCELLANEOUS_EXEMPT = 'MISCELLANEOUS_EXEMPT',
  DISCOUNT = 'DISCOUNT',
}
export enum RegistrationStatusEnum {
  REGISTERED = 'REGISTERED',
  PROCESSING = 'PROCESSING',
  UNREGISTERED = 'UNREGISTERED',
  DEREGISTERING = 'DEREGISTERING',
  DEREGISTERED = 'DEREGISTERED',
  VALIDATING = 'VALIDATING',
  AWAITING_CLARIFICATION = 'AWAITING_CLARIFICATION',
}
export enum RelatedEntityType {
  EXEMPTION = 'EXEMPTION',
  REGISTRATION = 'REGISTRATION',
  FILING = 'FILING',
  FILING_PAYMENT = 'FILING_PAYMENT',
}
export enum SourceEnum {
  BIGCOMMERCE = 'BIGCOMMERCE',
  CHARGEBEE = 'CHARGEBEE',
  SHOPIFY = 'SHOPIFY',
  STRIPE = 'STRIPE',
  AMAZON = 'AMAZON',
  TIKTOK = 'TIKTOK',
  CUSTOM = 'CUSTOM',
  ZUORA = 'ZUORA',
  APIDECK = 'APIDECK',
  QUICKBOOKS = 'QUICKBOOKS',
  API = 'API',
  APPLE_APP_STORE = 'APPLE_APP_STORE',
  GOOGLE_APP_STORE = 'GOOGLE_APP_STORE',
  WALMART = 'WALMART',
  PAYPAL = 'PAYPAL',
  NETSUITE = 'NETSUITE',
  XERO = 'XERO',
  MAXIO = 'MAXIO',
  RECURLY = 'RECURLY',
  SALESFORCE = 'SALESFORCE',
  ETSY = 'ETSY',
  EBAY = 'EBAY',
  WIX = 'WIX',
  SQUARESPACE = 'SQUARESPACE',
  WOOCOMMERCE = 'WOOCOMMERCE',
  MAGENTO = 'MAGENTO',
  BILLING_PLATFORM = 'BILLING_PLATFORM',
  DEEL = 'DEEL',
  RIPPLING = 'RIPPLING',
  GUSTO = 'GUSTO',
  OTHER = 'OTHER',
}
export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}
export enum TaxExemptType {
  PRODUCT_EXEMPT = 'PRODUCT_EXEMPT',
  CUSTOMER_EXEMPT = 'CUSTOMER_EXEMPT',
  REGION_EXEMPT = 'REGION_EXEMPT',
  REVERSE_CHARGE = 'REVERSE_CHARGE',
  ZERO_RATE_TAX = 'ZERO_RATE_TAX',
  HIGH_VALUE_PHYSICAL_GOODS = 'HIGH_VALUE_PHYSICAL_GOODS',
  EXPORT = 'EXPORT',
  ZERO_VALUE_ITEM = 'ZERO_VALUE_ITEM',
}
export enum TaxExemptionEnum {
  PRODUCT = 'PRODUCT',
  CUSTOMER = 'CUSTOMER',
  REGION = 'REGION',
  REVERSE_CHARGE = 'REVERSE_CHARGE',
  ZERO_RATE_TAX = 'ZERO_RATE_TAX',
  HIGH_VALUE_PHYSICAL_GOODS = 'HIGH_VALUE_PHYSICAL_GOODS',
  EXPORT = 'EXPORT',
  ZERO_VALUE_ITEM = 'ZERO_VALUE_ITEM',
  NO_RULE_FOUND = 'NO_RULE_FOUND',
  IMPORTED = 'IMPORTED',
}
export enum TaxItemReturnReasonEnum {
  NO_RULE_FOUND = 'NO_RULE_FOUND',
  RULE_FOUND_TAXABLE = 'RULE_FOUND_TAXABLE',
  RULE_FOUND_NOT_TAXABLE = 'RULE_FOUND_NOT_TAXABLE',
  PRODUCT_EXEMPT = 'PRODUCT_EXEMPT',
  FROM_IMPORT = 'FROM_IMPORT',
}
export enum TaxItemTypeEnum {
  IMPORTED = 'IMPORTED',
  CALCULATED = 'CALCULATED',
}
export enum TaxJurisdictionType {
  COUNTRY = 'COUNTRY',
  FEDERAL = 'FEDERAL',
  STATE = 'STATE',
  COUNTY = 'COUNTY',
  CITY = 'CITY',
  SPECIAL = 'SPECIAL',
  OTHER = 'OTHER',
}
export enum TaxTreatment {
  FULL_AMOUNT = 'FULL_AMOUNT',
  AMOUNT_OVER_THRESHOLD = 'AMOUNT_OVER_THRESHOLD',
}
export enum TransactionExemptStatusEnum {
  NOT_EXEMPT = 'NOT_EXEMPT',
  PARTIALLY_EXEMPT = 'PARTIALLY_EXEMPT',
  FULLY_EXEMPT = 'FULLY_EXEMPT',
}
export enum TransactionStatusEnum {
  PENDING = 'PENDING',
  COMMITTED = 'COMMITTED',
  CANCELLED = 'CANCELLED',
  FULLY_REFUNDED = 'FULLY_REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
}
export enum TransactionTypeEnum {
  SALE = 'SALE',
  FULL_CREDIT_NOTE = 'FULL_CREDIT_NOTE',
  PARTIAL_CREDIT_NOTE = 'PARTIAL_CREDIT_NOTE',
  ARCHIVE = 'ARCHIVE',
}
