import { ApideckVault, Connection } from '@apideck/vault-js';
import { useAuthInfo } from '@propelauth/react';
import { useMutation } from '@tanstack/react-query';
import { createApiDeckConnection, deleteApiDeckConnection, updateApiDeckConnections } from 'apis/connections';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import getValue from 'lodash/get';
import React, { useState } from 'react';

import { ItemCard } from '../item-card';

type HrisTabProps = {
  serviceId: string;
  heading: string;
  subHeading: string;
  icon: React.ReactNode;
  isConnected?: boolean;
};

export const HrisTab = ({ serviceId, heading, subHeading, icon, isConnected = false }: HrisTabProps) => {
  const { orgId } = useOrg();
  const { track } = useTracking();
  const { user } = useAuthInfo();
  const [connection, setConnection] = useState({
    connectionId: '',
  });

  // TODO: remove after prod release of rippling apps
  const hrisEnabled = getValue(user, 'properties.metadata.hris');

  const { mutateAsync: doUpdateApideckConnections, isPending } = useMutation({
    mutationFn: (status: string) => {
      return updateApiDeckConnections(connection.connectionId, orgId, status);
    },
    onSuccess: () => {
      track('connects integration', { integration: serviceId });
    },
  });

  const handleConnectionChange = async (connection: Connection) => {
    if (connection.state === 'callable') {
      await doUpdateApideckConnections('ACTIVE');
    }
    if (connection.state === 'added') {
      await doUpdateApideckConnections('INACTIVE');
    }
  };

  const { mutate: doCreateApideckConnection, isPending: isCreating } = useMutation({
    mutationFn: async () => {
      const { data } = await createApiDeckConnection(orgId, serviceId, {});
      return data;
    },
    onSuccess: data => {
      const token = data['token'];
      const connectionId = data['connection_id'];
      setConnection(prev => ({ ...prev, connectionId }));

      ApideckVault.open({
        token: token,
        unifiedApi: 'hris',
        serviceId,
        onConnectionChange: handleConnectionChange,
        onClose: () => deleteApiDeckConnection(connectionId, orgId),
      });
    },
  });

  // TODO: remove after prod release of rippling app
  if (!hrisEnabled && serviceId === 'rippling') {
    return null;
  }

  return (
    <ItemCard
      isConnected={isConnected}
      title={heading}
      description={subHeading}
      icon={icon}
      onConnect={doCreateApideckConnection}
      btnProps={{
        isLoading: isCreating || isPending,
      }}
    />
  );
};
