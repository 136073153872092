import { Flex, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CUSTOMERS_STATE_KEY } from 'apis/customers';
import { updateExemption } from 'apis/exemption-apis';
import { EXEMPTIONS_STATE_KEY } from 'apis/exemptions';
import Button from 'component-library/components/ButtonTmp/button';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import { useHandleNotification } from 'hooks/useApiNotification';

type ArchiveExemptionFormProps = {
  isOpen: boolean;
  onClose: () => void;
  exemption: any;
  orgId: string;
};

export const ArchiveExemptionForm = ({ exemption, orgId, isOpen, onClose }: ArchiveExemptionFormProps) => {
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();

  const archiveExemption = useMutation({
    mutationFn: (status: string) => {
      const payload = { ...exemption, status: status };
      return updateExemption(orgId, exemption.id, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CUSTOMERS_STATE_KEY, orgId] });
      queryClient.invalidateQueries({ queryKey: [EXEMPTIONS_STATE_KEY, orgId] });
      handleSuccessNotification('Exemption Archived Successfully');
      onClose();
    },
  });

  const handleConfirm = () => {
    archiveExemption.mutate('ARCHIVED');
  };

  return (
    <ModalPopup
      closeOnOverlayClick={false}
      size={'lg'}
      isOpen={isOpen}
      onClose={onClose}
      header="Are you sure you want to archive exemption?"
      footer={
        <Flex gap={2}>
          <Button variant={'outline'} color={'secondary'} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={'danger'} width={'90px'} isLoading={archiveExemption.isPending} onClick={handleConfirm}>
            Confirm
          </Button>
        </Flex>
      }
    >
      <Text>Once you archived this exemption it will no longer apply to this customer.</Text>
    </ModalPopup>
  );
};
