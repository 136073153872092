import { HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {
  getPhysicalNexus,
  getPhysicalNexusCategories,
  PHYSICAL_NEXUS_CATEGORIES_KEY,
  PHYSICAL_NEXUS_KEY,
} from 'apis/physical-nexus-apis';
import { getTransactions, TRANSACTION_STATE_KEY } from 'apis/transactions';
import AppHeader from 'app/app-header';
import { PhysicalNexusToolbar } from 'app-header-toolbar/physical-nexus-toolbar';
import { TableSkeleton } from 'component-library/components/Skeleton/table-skeleton';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import { useOrg } from 'hooks/useOrg';
import { useTableFilters } from 'hooks/useTableFilters';
import { useState } from 'react';
import { PhysicalNexusInstance } from 'types/shared-types';

import { PhysicalNexusModal } from './components/physical-nexus-modal';
import { PhysicalNexusTable } from './components/physical-nexus-table';

const Presence = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { orgId } = useOrg();
  const { page, size, setFilters } = useTableFilters();
  const [physicalNexus, setPhysicalNexus] = useState<PhysicalNexusInstance>();

  const {
    isPending: isPhysicalNexusDataPending,
    data,
    isError,
  } = useQuery({
    queryKey: [PHYSICAL_NEXUS_KEY, orgId, page, size],
    queryFn: async () => {
      const { data } = await getPhysicalNexus(orgId, { page, size });
      return data;
    },
  });

  const { isPending: isTransactionAvailablePending, data: isTransactionAvailable = false } = useQuery({
    queryKey: [TRANSACTION_STATE_KEY, orgId, page, size],
    queryFn: async () => {
      const { data } = await getTransactions(orgId, { page, size });
      return data;
    },
    select: ({ items }) => items.length > 0,
  });

  const { data: categories = [], isPending: isCategoriesPending } = useQuery({
    queryKey: [PHYSICAL_NEXUS_CATEGORIES_KEY, orgId],
    queryFn: async () => {
      const res = await getPhysicalNexusCategories(orgId);
      return res.data;
    },
  });

  const physicalNexusItems = data?.items || [];
  const hasPhysicalNexusData = physicalNexusItems.length > 0;
  const totalPages = data?.pages ?? 0;

  if (isError && !data) {
    return null;
  }
  const isAllPending = isPhysicalNexusDataPending || isTransactionAvailablePending || isCategoriesPending;

  const handleOnEdit = (physicalNexus: PhysicalNexusInstance) => {
    setPhysicalNexus(physicalNexus);
    onOpen();
  };

  return (
    <>
      <Stack height={'100%'}>
        <HStack gap={1} justifyContent={'space-between'}>
          <AppHeader />
          <PhysicalNexusToolbar />
        </HStack>
        {isAllPending && <TableSkeleton items={size} />}
        {!isAllPending && (
          <PhysicalNexusTable
            data={physicalNexusItems}
            categories={categories}
            isTransactionAvailable={isTransactionAvailable}
            onEdit={handleOnEdit}
          />
        )}
        {hasPhysicalNexusData && (
          <PaginationButtons size={size} currentPage={page} totalPages={totalPages} setFilters={setFilters} />
        )}
      </Stack>
      {isOpen && <PhysicalNexusModal isOpen={isOpen} onClose={onClose} physicalNexus={physicalNexus} />}
    </>
  );
};

export default Presence;
