import { Box, Button, Flex } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateBlankAddresses } from 'apis/addressses';
import { TRANSACTION_STATE_KEY } from 'apis/transaction-api';
import ModalPopup from 'component-library/components/Modal/modal-popup';
import Text from 'component-library/components/Text';
import { AIIconButton } from 'components/icons';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';

type AIBlankAddressAddModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AIBlankAddressAddModal = ({ isOpen, onClose }: AIBlankAddressAddModalProps) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();

  const { handleSuccessNotification } = useHandleNotification();
  const { mutateAsync: updateAllBlankAddresses, isPending: isUpdatingBlankAddress } = useMutation({
    mutationFn: () => {
      return updateBlankAddresses(orgId);
    },
    onSuccess: () => {
      //this toast is a bit special, it has a longer duration
      handleSuccessNotification(
        'Kinstugi Intelligence is updating your addresses; depending on the number of addresses, this can take some time.',
        1000
      );
      queryClient.invalidateQueries({ queryKey: [TRANSACTION_STATE_KEY] });
      onClose();
    },
  });

  const handleBulkAddAddress = async () => {
    await updateAllBlankAddresses();
  };

  return (
    <ModalPopup
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={'lg'}
      header="Bulk Add Addresses"
      footer={
        <Flex gap={4}>
          <Button variant="secondary-outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={'solid'}
            width={'90px'}
            onClick={handleBulkAddAddress}
            isLoading={isUpdatingBlankAddress}
            leftIcon={<AIIconButton />}
            bgGradient={'linear(to-r, #04C3CC, #527BE4, #A373EB)'}
          >
            Submit
          </Button>
        </Flex>
      }
    >
      <Box>
        <Text>
          Kintsugi Intelligence will look at where you sell your products historically and pick a fair and even
          distribution to add addresses to Transactions that do not have them.
        </Text>
        <br />
        <Text>
          Going forward be sure to collect good addresses for your customers and transactions to avoid needing to do
          this.
        </Text>
      </Box>
    </ModalPopup>
  );
};

export default AIBlankAddressAddModal;
