import { Badge, Box, Heading, HStack, IconButton, Stack, Tooltip } from '@chakra-ui/react';
import Text from 'component-library/components/Text';
import { ArrowLeft, MarketplaceIcon } from 'components/icons';
import { transactionStatusVariantMap } from 'constants/transactions';
import { Link, useNavigate } from 'react-router-dom';
import { TransactionInstance } from 'types/shared-types';
import { toDateShort } from 'utils/dates';

type TransactionDetailsHeaderLabelProps = {
  data?: TransactionInstance;
  isPending: boolean;
  isError: boolean;
  onClick?: () => void;
};
export const TransactionDetailsHeaderLabel = ({
  data: transactionDetails,
  isError,
  isPending,
  onClick,
}: TransactionDetailsHeaderLabelProps) => {
  const navigate = useNavigate();

  if (isPending || !transactionDetails) {
    return null;
  }

  if (isError) {
    console.log('Error fetching transaction details');
    return null;
  }

  const status = transactionDetails.status;
  const { external_id, marketplace, date, customer_id, type } = transactionDetails;
  const statusVariant = transactionStatusVariantMap[status];

  const handleOnclick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate('/transactions');
    }
  };

  if (!statusVariant?.variant) {
    return null;
  }

  return (
    <HStack alignItems={'center'} mt={'16px'}>
      <Stack gap={1}>
        <HStack alignContent={'center'}>
          <IconButton aria-label="back" variant={'ghost'} rounded={4} onClick={handleOnclick}>
            <ArrowLeft />
          </IconButton>
          <Heading as={'h1'} fontSize={'20px'}>
            Transaction Details
          </Heading>
          {type !== 'SALE' && <Badge variant={'secondary'}>Credit Note</Badge>}
          <Badge variant={statusVariant.variant}>{statusVariant.title}</Badge>
          {marketplace && (
            <Tooltip
              label={`A Marketplace transactions is a transaction where the tax is paid by the marketplace directly`}
              shouldWrapChildren
              hasArrow
            >
              <MarketplaceIcon cursor={'pointer'} />
            </Tooltip>
          )}
        </HStack>
        <HStack direction={'row'} alignItems={'center'} ml={'50px'}>
          <Text fontSize={'sm'} color={'gray.500'}>
            ID: {external_id}
          </Text>
          <Box boxSize={'4px'} rounded={'xl'} bgColor={'gray.500'} />
          <Link to={`/customers/${customer_id}`}>
            <Text fontSize={'sm'} color={'gray.500'} textDecoration={'underline'} textUnderlineOffset={2}>
              {customer_id}
            </Text>
          </Link>
          <Box boxSize={'4px'} rounded={'xl'} bgColor={'gray.500'} />
          <Text fontSize={'sm'} color={'gray.500'}>
            {toDateShort(new Date(date))}
          </Text>
        </HStack>
      </Stack>
    </HStack>
  );
};
