import { Flex, FlexProps, HStack, Switch, Text, Tooltip } from '@chakra-ui/react';
import { InfoFilled } from 'components/icons';

interface AutoFileRegisterToggleProps extends FlexProps {
  label?: string;
  tooltip?: string | React.ReactNode;
  subtitle?: string;
  fieldName: string;
  formik: any;
  fontSize?: string;
}

export const AutoFileRegisterToggle = ({
  label,
  tooltip,
  fieldName,
  subtitle,
  formik,
  fontSize = 'md',
  ...props
}: AutoFileRegisterToggleProps) => {
  const handleToggle = (event: any) => {
    const checked = event.target.checked;
    formik.setFieldValue(fieldName, checked);
  };

  return (
    <Flex
      border="1px solid #ddd"
      borderRadius={4}
      justifyContent="space-between"
      align="center"
      mt={4}
      p={4}
      fontSize={'sm'}
      {...props}
    >
      <HStack spacing={1} align="center">
        <Flex direction={'column'}>
          {label && (
            <Text fontWeight={500} fontSize={fontSize}>
              {label}
            </Text>
          )}
          {subtitle && (
            <Text fontSize={'sm'} color="#4B5169" mt="2px">
              {subtitle}
            </Text>
          )}
        </Flex>

        {tooltip && (
          <Tooltip label={tooltip} placement="bottom-start" hasArrow={false} shouldWrapChildren>
            <InfoFilled width={17} height={17} />
          </Tooltip>
        )}
      </HStack>
      <Switch size="lg" onChange={handleToggle} isChecked={formik.values[fieldName]} />
    </Flex>
  );
};
