import { PhysicalNexusCategory } from 'types/nexus';
import { ApiResponse, CountryCodeEnum, PhysicalNexusInstance, TableFilter } from 'types/shared-types';

import api from './api';

const getPhysicalNexus = (orgId: string, params?: TableFilter) => {
  return api.get<ApiResponse<PhysicalNexusInstance>>(`/v1/nexus/physical_nexus`, {
    headers: {
      'x-organization-id': orgId,
    },
    params,
  });
};

const getPhysicalNexusCategories = (orgId: string, countryCode?: CountryCodeEnum, stateCode?: string) => {
  return api.get<PhysicalNexusCategory[]>(`/v1/nexus/physical_nexus/categories`, {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      country_code: countryCode,
      state_code: stateCode,
    },
  });
};

const deletePhysicalNexus = (orgId: string, id: string) => {
  return api.delete(`/v1/nexus/physical_nexus/${id}`, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const addPhysicalNexus = (orgId: string, payload: object) => {
  return api.post(`/v1/nexus/physical_nexus`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const updatePhysicalNexus = (orgId: string, id: string, payload: object) => {
  return api.put(`/v1/nexus/physical_nexus/${id}`, payload, {
    headers: {
      'x-organization-id': orgId,
    },
  });
};

const PHYSICAL_NEXUS_KEY = 'physicalNexus';
const PHYSICAL_NEXUS_CATEGORIES_KEY = 'physicalNexusCategories';
export {
  addPhysicalNexus,
  deletePhysicalNexus,
  getPhysicalNexus,
  getPhysicalNexusCategories,
  PHYSICAL_NEXUS_CATEGORIES_KEY,
  PHYSICAL_NEXUS_KEY,
  updatePhysicalNexus,
};
